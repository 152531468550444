import React, { useState } from 'react';
import Measure from 'react-measure';

import SearchResults from '../../../../scenes/Basic/scenes/SearchResults';
import useEntryParameters from '../../../../services/entryParameters/useEntryParameters';
import  useSearch from '../../../../scenes/Basic/services/SearchActions/useSearch';
import  useAggregatedSearch from '../../../../scenes/Basic/services/SearchActions/useAggregatedSearch';
import  useGeoData from '../../../../services/geo/useGeoData';
import { usePostMessages } from '../../../../services/postMessages';


import SearchForm from './components/SearchForm';
import Filters from './components/Filters';
import {
  Container,
  FormAndFiltersContainer,
  ResultsContainer,
  LoaderBar,
  LoaderWrapper,
} from './index.styled';

const BasicSearchForm = () => {
  const { closestCities } = useGeoData();
  const entryParameters = useEntryParameters();
  const [height, setHeight] = useState(1);
  const { postIframeHeight } = usePostMessages();

  //increase by deeplink overhead if widget is embedded into deeplink preview
  const increaseBy = entryParameters.iframeId === 'deeplink-generator' ? 400 : 0;

  const search = useSearch();
  const aggregatedSearch = useAggregatedSearch();

  const handleResize = contentRect => {
    if (height !== contentRect.client.height) {
      setHeight(contentRect.client.height);
      postIframeHeight(contentRect.client.height + increaseBy);
    }
  };

  if (!closestCities.data) {
    return null;
  }

  const showSearchForm = !entryParameters.resultsOnly;

  return (
    <Measure client onResize={handleResize}>

      {({ measureRef }) => (
        <Container ref={measureRef}>
          {showSearchForm && (
            <FormAndFiltersContainer>
              <Filters />
              <SearchForm />
            </FormAndFiltersContainer>
          )}
          {(search.results.loading ||
            aggregatedSearch.results.loading
            /* || search.results.loadingMore*/) && (
            <LoaderWrapper>
              <LoaderBar data-test="LoaderBar" />
            </LoaderWrapper>
          )}
          <ResultsContainer>
            <SearchResults />
          </ResultsContainer>
        </Container>
      )}
    </Measure>
  );
};

export default BasicSearchForm;
