import React from 'react';

import { range } from '../../utils/array';

import {
  Wrapper,
  ContentStyled,
  ItineraryStyled,
  TripsWrapperStyled,
  TripStyled,
  DateStyled,
  RouteStyled,
  BadgesWrapperStyled,
  BadgesStyled,
  ActionsStyled,
  PriceWrapperStyled,
  PriceStyled,
  ButtonsStyled,
} from './index.styled';

const ResultCardPlaceholder = ({ tripCount, highlighted }) => (
  <Wrapper highlighted={highlighted}>
    <ContentStyled>
      <ItineraryStyled>
        <TripsWrapperStyled>
          {range(tripCount).map(i => (
            <TripStyled key={i}>
              <DateStyled />
              <RouteStyled />
            </TripStyled>
          ))}
        </TripsWrapperStyled>
        <BadgesWrapperStyled>
          <BadgesStyled />
        </BadgesWrapperStyled>
      </ItineraryStyled>
      <ActionsStyled>
        <PriceWrapperStyled>
          <PriceStyled />
        </PriceWrapperStyled>
        <ButtonsStyled />
      </ActionsStyled>
    </ContentStyled>
  </Wrapper>
);

export default ResultCardPlaceholder;
