import React, { useState } from 'react';

import { TabsContainer, TabContainer, TabButton, ActionsWrapper } from './index.styled';

const Tab = props => {
  return (
    <TabContainer lineSize={props.lineSize} active={props.active}>
      <TabButton {...props}>{props.label}</TabButton>
    </TabContainer>
  );
};

const Tabs = ({ items, transparent, lineSize, actions, changeActiveTab }) => {
  const [activeTab, setActiveTab] = useState(items[0].value);

  const handleSelectTab = item => {
    setActiveTab(item.value);
    if (changeActiveTab) {
      changeActiveTab(item.value);
    }
  };

  const activeComponent = items.find(i => i.value === activeTab).component;

  return (
    <div>
      <TabsContainer hasActions={!!actions}>
        <div>
          {items.map(item => {
            return (
              <div key={item.value}>
                <Tab
                  onClick={() => {
                    handleSelectTab(item);
                  }}
                  label={item.label}
                  active={item.value === activeTab}
                  value={item.value}
                  transparent={transparent}
                  lineSize={lineSize}
                />
              </div>
            );
          })}
        </div>
        {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
      </TabsContainer>
      <div>{typeof activeComponent === 'function' ? <activeComponent /> : activeComponent}</div>
    </div>
  );
};

export default Tabs;
