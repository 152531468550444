import React from 'react';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';

import AirlineLogo from './AirlineLogo';

const TripSegmentDetailsCarrierLogo = ({ carrier, size, type }) => {
  const { icon } = carrier;
  if (icon) {
    return <AirlineLogo airlines={[carrier]} size={size} />;
  }
  return <CarrierLogo carriers={[{ code: carrier.code, name: carrier.name, type }]} size={size} />;
};

export default TripSegmentDetailsCarrierLogo;
