import Slider from '@kiwicom/orbit-components/lib/Slider';
import styled from 'styled-components';

import  useTranslations  from '../../../services/translations/useTranslations';
import {} from "ramda";

const SliderWrapper = styled.div`
  min-width: 350px;
  padding: 0;
`;

const NightsOfStay = ({ nightsOfStay, handleNightsChange }) => {
  const { translate } = useTranslations();

  const displayedNightsOfStay =
    nightsOfStay[0] === nightsOfStay[1] ? nightsOfStay[1] : `${nightsOfStay[0]}-${nightsOfStay[1]}`;

  return (
    <SliderWrapper>
      <Slider
        defaultValue={nightsOfStay}
        minValue={1}
        maxValue={31}
        step={1}
        label={translate(('form_search.date_picker.time_of_stay'))}
        valueDescription={`${displayedNightsOfStay} ${translate(('form_search.of_nights'))}`}
        onChange={handleNightsChange}
      />
    </SliderWrapper>
  );
};

export default NightsOfStay;
