import React, { useState } from 'react';
import Measure from 'react-measure';
import styled from 'styled-components';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import List, { ListItem } from '@kiwicom/orbit-components/lib/List';

import  usePostMessages  from '../../services/postMessages/usePostMessages';
import useEntryParameters from '../../services/entryParameters/useEntryParameters';

const Wrapper = styled.div`
  max-width: 500px;
  padding: 24px;
`;

const ErrorBoundary = ({ children }) => {
  const entryParams = useEntryParameters();
  const { postIframeHeight } = usePostMessages();
  const [height, setHeight] = useState(1);

  const handleResize = element => {
    if (height !== element.height) {
      setHeight(element.height);
      postIframeHeight(element.height);
    }
  };

  if (entryParams.error) {
    return (
      <Measure
        client
        onResize={contentRect => {
          handleResize(contentRect.client);
        }}
      >
        {({ measureRef }) => (
          <Wrapper ref={measureRef}>
            <Illustration name="Error" size="medium" />
            <Heading spaceAfter="medium">An error occurred:</Heading>
            <List>
              {entryParams.error.map(e => (
                <ListItem key={e}>{e}</ListItem>
              ))}
            </List>
          </Wrapper>
        )}
      </Measure>
    );
  }
  return children;
};

export default ErrorBoundary;
