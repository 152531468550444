/* eslint-disable no-bitwise */

/**
 * Custom RNG
 * Reference for more detailed info:
 * https://en.wikipedia.org/wiki/Multiply-with-carry_pseudorandom_number_generator
 */
const mask = 0xffffffff;
export const randomFactory = (seed = Math.floor(Math.random() * 1000)) => {
  let mw = (123456789 + seed) & mask;
  let mz = (987654321 - seed) & mask;

  return function rand() {
    mz = (36969 * (mz & (2 ** 16 - 1)) + (mz >>> 16)) & mask;
    mw = (18000 * (mw & (2 ** 16 - 1)) + (mw >>> 16)) & mask;

    return (((mz << 16) + (mw & 65535)) >>> 0) / 4294967296;
  };
};
