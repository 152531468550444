import React from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';

import Item from './Item';

const ResultsSection = ({ title, places, direction, onAdd, onRemove, selected }) => {

  return (
    <div>
      <Text uppercase size="small" weight="bold" spaceAfter="smallest">
        {title}
      </Text>

      {places.map(place => (

        <Item key={place.slug} place={place} direction={direction} onAdd={onAdd} onRemove={onRemove} selected={selected}/>
      ))}
    </div>
  );
};

export default React.memo(ResultsSection);
