import React, { useReducer, useCallback, useEffect } from 'react';

import useEntryParameters from '../../../services/entryParameters/useEntryParameters';
import { useDeeplinkSyncProvider } from '../../../services/deeplinkSync';
import { DAY_OPTIONS } from '../../../consts/days';
import { SORT_PARAM_TO_AGGREGATED } from '../../../consts/sortBy';
import { useDependencyEffect } from '../../../utils/hooks';

export const SearchState = React.createContext({});

const filterDays = (days, dayIndex) => {
  const res = [];

  for (let i = 0; i < days.length; i++) {
    if (i === dayIndex) {
      if (days[i]) {
        res.push(null);
      } else {
        res.push(DAY_OPTIONS[i]);
      }
    } else {
      res.push(days[i]);
    }
  }

  return res;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SORT_BY':
      return {
        ...state,
        sortBy: action.sortBy,
      };
    case 'TOGGLE_TRANSPORT':
      if (state.transportTypes.includes(action.transportType)) {
        return {
          ...state,
          transportTypes:
            state.transportTypes.length === 1
              ? []
              : state.transportTypes.filter(t => t !== action.transportType),
        };
      }
      return {
        ...state,
        transportTypes: state.transportTypes.concat(action.transportType),
      };
    case 'TOGGLE_TRANSPORT_ONLY':
      return {
        ...state,
        transportTypes: [action.transportType],
      };
    case 'SET_TRANSPORT':
      return {
        ...state,
        transportTypes: action.transportTypes,
      };
    case 'CLEAR_TRANSPORT':
      return {
        ...state,
        transportTypes: ['FLIGHT', 'BUS', 'TRAIN'],
      };
    case 'CHANGE_STOP_NUMBER':
      return {
        ...state,
        stopNumber: action.stopNumber,
      };
    case 'TOGGLE_OVERNIGHT_STOPOVERS':
      return {
        ...state,
        allowOvernightStopovers: !state.allowOvernightStopovers,
      };
    case 'CHANGE_CABIN_CLASS':
      return {
        ...state,
        cabinClass: action.cabinClass,
        applyMixedClasses: action.applyMixedClasses,
      };
    case 'CHANGE_PRICE':
      return {
        ...state,
        price: action.priceRange,
      };
    case 'CHANGE_AGGREGATED_SORT_BY':
      return {
        ...state,
        aggregatedSortBy: action.sortBy,
      };
    case 'ADD_LOCATION_HASHTAG':
      return {
        ...state,
        locationHashtags: state.locationHashtags.concat(action.locationHashtagId),
      };
    case 'REMOVE_LOCATION_HASHTAG':
      return {
        ...state,
        locationHashtags: state.locationHashtags.filter(e => e !== action.locationHashtagId),
      };
    case 'SET_LOCATION_HASHTAGS':
      return {
        ...state,
        locationHashtags: action.locationHashtagIds,
      };
    case 'TOGGLE_DAY':
      return {
        ...state,
        [`${action.direction}Days`]: filterDays(state[`${action.direction}Days`], action.dayIndex),
      };
    case 'SET_DAYS':
      return {
        ...state,
        [`${action.direction}Days`]: action.days,
      };
    case 'SET_INITIAL_PARAMS':
      return {
        ...state,
        ...action.initialParameters,
      };
    case 'CLEAR_DAYS':
      return {
        ...state,
        outboundDays: DAY_OPTIONS,
        inboundDays: DAY_OPTIONS,
      };
    case 'CHANGE_TIMES':
      return {
        ...state,
        times: {
          ...state.times,
          [action.direction]: {
            ...state.times[action.direction],
            [action.directionType]: action.value,
          },
        },
      };
    case 'CLEAR_TIMES':
      return {
        ...state,
        times: { ...initialState.times },
      };
    case 'SET_TIMES':
      return {
        ...state,
        times: action.times,
      };
    case 'CHANGE_ALLOW_CHANGE_INBOUND':
      return {
        ...state,
        [action.key]: action.value,
      };
    case 'RESET_ALLOW_CHANGE_INBOUND':
      return {
        ...state,
        returnFromDifferentAirport: true,
        returnToDifferentAirport: true,
      };
    case 'SET_ALLOW_CHANGE_INBOUND':
      return {
        ...state,
        returnFromDifferentAirport: action.values.returnFromDifferentAirport,
        returnToDifferentAirport: action.values.returnToDifferentAirport,
      };
    default:
      return state;
  }
};

const initialState = {
  cabinClass: 'ECONOMY',
  applyMixedClasses: true,
  sortBy: 'best',
  transportTypes: ['FLIGHT', 'BUS', 'TRAIN'],
  stopNumber: -1,
  allowOvernightStopovers: true,
  price: [0, 0],
  aggregatedSortBy: 'POPULARITY',
  locationHashtags: [],
  outboundDays: DAY_OPTIONS,
  inboundDays: DAY_OPTIONS,
  limit: 30,
  returnFromDifferentAirport: true,
  returnToDifferentAirport: true,
  times: {
    outbound: {
      departure: [0, 24],
      arrival: [0, 24],
    },
    inbound: {
      departure: [0, 24],
      arrival: [0, 24],
    },
  },
};

const SearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const entryParams = useEntryParameters();
  const [, deeplinkDispatch] = useDeeplinkSyncProvider();

  useEffect(() => {
    const initialParameters = {
      ...(entryParams.limit ? { limit: entryParams.limit } : {}),
      ...(entryParams.tileLimit ? { tileLimit: entryParams.tileLimit } : {}),
      ...(entryParams.sortBy
        ? {
            sortBy: entryParams.sortBy,
            aggregatedSortBy: SORT_PARAM_TO_AGGREGATED[entryParams.sortBy],
          }
        : {}),
      ...(entryParams.stopNumber >= -1 && entryParams.stopNumber <= 2
        ? { stopNumber: entryParams.stopNumber }
        : {}),
      ...(entryParams.transportTypes ? { transportTypes: entryParams.transportTypes } : {}),
      ...(entryParams.excludeCarriers ? { excludeCarriers: entryParams.excludeCarriers } : {}),
      ...(entryParams.carriers ? { carriers: entryParams.carriers } : {}),
      ...(entryParams.cabinClass ? { cabinClass: entryParams.cabinClass } : {}),
      ...(entryParams.inboundDays && { inboundDays: entryParams.inboundDays }),
      ...(entryParams.outboundDays && { outboundDays: entryParams.outboundDays }),
      ...(entryParams.times ? { times: entryParams.times } : {}),
      ...(entryParams.price ? { price: entryParams.price } : []),
      returnFromDifferentAirport: entryParams.returnFromDifferentAirport,
      returnToDifferentAirport: entryParams.returnToDifferentAirport,
    };
    dispatch({
      type: 'SET_INITIAL_PARAMS',
      initialParameters,
    });
  }, [entryParams]);

  const transportTypesHash = JSON.stringify(state.transportTypes);
  useDependencyEffect(() => {
    deeplinkDispatch({ type: 'SET_PARAM', key: 'transportTypes', value: state.transportTypes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplinkDispatch, transportTypesHash]);

  const timesHash = JSON.stringify(state.times);
  useDependencyEffect(() => {
    deeplinkDispatch({ type: 'SET_PARAM', key: 'times', value: state.times });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplinkDispatch, timesHash]);

  const priceHash = JSON.stringify(state.price);
  useDependencyEffect(() => {
    deeplinkDispatch({ type: 'SET_PARAM', key: 'price', value: state.price });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplinkDispatch, priceHash]);

  useDependencyEffect(() => {
    deeplinkDispatch({ type: 'SET_PARAM', key: 'stopNumber', value: state.stopNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplinkDispatch, state.stopNumber]);

  const daysHash = JSON.stringify(state.outboundDays) + JSON.stringify(state.inboundDays);
  useDependencyEffect(() => {
    deeplinkDispatch({
      type: 'SET_PARAM',
      key: 'days',
      value: {
        departure: state.outboundDays,
        return: state.inboundDays,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplinkDispatch, daysHash]);

  useEffect(() => {
    deeplinkDispatch({
      type: 'ASSIGN',
      value: {
        returnFromDifferentAirport: state.returnFromDifferentAirport,
        returnToDifferentAirport: state.returnToDifferentAirport,
      },
    });
  }, [deeplinkDispatch, state.returnFromDifferentAirport, state.returnToDifferentAirport]);

  const changeSortBy = useCallback(sortBy => {
    dispatch({ type: 'SORT_BY', sortBy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTransport = useCallback(transportType => {
    dispatch({ type: 'TOGGLE_TRANSPORT', transportType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleTransportOnly = useCallback(transportType => {
    dispatch({ type: 'TOGGLE_TRANSPORT_ONLY', transportType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTransportTypes = useCallback(transportTypes => {
    dispatch({ type: 'SET_TRANSPORT', transportTypes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearTransportFilters = useCallback(() => {
    dispatch({ type: 'CLEAR_TRANSPORT' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStopNumber = useCallback(stopNumber => {
    dispatch({ type: 'CHANGE_STOP_NUMBER', stopNumber });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOvernightStopovers = useCallback(() => {
    dispatch({ type: 'TOGGLE_OVERNIGHT_STOPOVERS' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeCabinClass = useCallback((cabinClass, applyMixedClasses) => {
    dispatch({ type: 'CHANGE_CABIN_CLASS', cabinClass, applyMixedClasses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePrice = useCallback(priceRange => {
    dispatch({ type: 'CHANGE_PRICE', priceRange });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAggregatedSortBy = useCallback(sortBy => {
    dispatch({ type: 'CHANGE_AGGREGATED_SORT_BY', sortBy });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addLocationHashtag = useCallback(locationHashtagId => {
    dispatch({ type: 'ADD_LOCATION_HASHTAG', locationHashtagId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeLocationHashtag = useCallback(locationHashtagId => {
    dispatch({ type: 'REMOVE_LOCATION_HASHTAG', locationHashtagId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLocationHashtags = useCallback(locationHashtagIds => {
    dispatch({ type: 'SET_LOCATION_HASHTAGS', locationHashtagIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearDays = useCallback(() => {
    dispatch({ type: 'CLEAR_DAYS' });
  }, []);

  const toggleDay = useCallback((dayIndex, direction) => {
    dispatch({ type: 'TOGGLE_DAY', dayIndex, direction });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDays = useCallback((direction, days) => {
    dispatch({ type: 'SET_DAYS', direction, days });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTimes = useCallback(({ value, direction, type }) => {
    dispatch({ type: 'CHANGE_TIMES', value, direction, directionType: type });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearTimes = useCallback(() => {
    dispatch({ type: 'CLEAR_TIMES' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setTimes = useCallback(times => {
    dispatch({ type: 'SET_TIMES', times });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAllowChangeInbound = useCallback(({ key, value }) => {
    dispatch({ type: 'CHANGE_ALLOW_CHANGE_INBOUND', key, value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetAllowChangeInbound = useCallback(() => {
    dispatch({ type: 'RESET_ALLOW_CHANGE_INBOUND' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAllowChangeInbound = useCallback(values => {
    dispatch({ type: 'SET_ALLOW_CHANGE_INBOUND', values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultValues = type => {
    return initialState[type];
  };

  const value = {
    ...state,
    changeSortBy,
    toggleTransport,
    toggleTransportOnly,
    setTransportTypes,
    clearTransportFilters,
    changeStopNumber,
    toggleOvernightStopovers,
    changeCabinClass,
    changePrice,
    changeAggregatedSortBy,
    addLocationHashtag,
    removeLocationHashtag,
    setLocationHashtags,
    toggleDay,
    setDays,
    clearDays,
    changeTimes,
    clearTimes,
    setTimes,
    changeAllowChangeInbound,
    resetAllowChangeInbound,
    setAllowChangeInbound,
    filterDays,
    getDefaultValues,
  };

  return <SearchState.Provider value={value}>{children}</SearchState.Provider>;
};

export default SearchProvider;
