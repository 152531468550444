import React, { useReducer, useCallback, useEffect } from 'react';

import { useDeeplinkSyncProvider } from '../../../services/deeplinkSync';
import useEntryParameters from '../../../services/entryParameters/useEntryParameters';
import { getArrayBags } from '../../../utils/bags';

export const PassengersAndBagsState = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INITIAL_PAX_AND_BAGS':
      return {
        ...state,
        adults: action.adults,
        children: action.children,
        infants: action.infants,
        cabinBags: Math.min(action.handBags, action.adults + action.children),
        checkedBags: Math.min(action.holdBags, (action.adults + action.children) * 2),
      };
    case 'INCREMENT':
      return {
        ...state,
        [action.key]: state[action.key] + 1,
      };
    case 'DECREMENT':
      return {
        ...state,
        [action.key]: state[action.key] - 1,
      };
    case 'DECREMENT_ADULTS':
      return {
        ...state,
        adults: state.adults - 1,
        // max number of infants = number of adults
        infants: Math.min(state.infants, state.adults - 1),
        // max allowed cabin bags = adults + children
        cabinBags: Math.min(state.cabinBags, state.adults + state.children - 1),
        // max allowed checked bags = (adults + children) * 2
        checkedBags: Math.min(state.checkedBags, (state.adults + state.children - 1) * 2),
      };
    case 'DECREMENT_CHILDREN':
      return {
        ...state,
        children: state.children - 1,
        cabinBags: Math.min(state.cabinBags, state.adults + state.children - 1),
        checkedBags: Math.min(state.checkedBags, (state.adults + state.children - 1) * 2),
      };
    case 'RESTORE_FROM_SNAPSHOT':
      return {
        ...action.snapshot,
      };
    case 'RESET_BAGS_FILTER':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_TO_INITIAL_STATE':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  adults: 1,
  children: 0,
  infants: 0,
  cabinBags: 0,
  checkedBags: 0,
};

const MAX_PAX = 9;
const MAX_CABIN_BAGS = 9;
const MAX_CHECKED_BAGS = 18;

const PassengersAndBagsProvider = ({
  onChange,
  noEntryParams,
  noDeeplinkSync = false,
  children,
  noConfig = true,
  configPaxAndBags,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const entryParams = useEntryParameters();
  const [deeplinkStore, deeplinkDispatch] = useDeeplinkSyncProvider();

  useEffect(() => {
    const store = noDeeplinkSync ? deeplinkStore : entryParams;

    let [adults, children, infants] = [1, 0, 0];
    let [handBags, holdBags] = [0, 0];

    if (!noEntryParams) {
      [adults, children, infants] = store.passengers.split('-').map(Number);
      [handBags, holdBags] = getArrayBags(store.bags);
    }

    if (!noConfig) {
      [adults, children, infants] = configPaxAndBags.passengers.split('-').map(Number);
      [handBags, holdBags] = getArrayBags(configPaxAndBags.bags);
    }

    dispatch({ type: 'SET_INITIAL_PAX_AND_BAGS', adults, children, infants, handBags, holdBags });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalPax = state.adults + state.children + state.infants;
  const totalBags = state.cabinBags + state.checkedBags;

  const canIncrement = paxAndBags => {
    const totalPax = paxAndBags.adults + paxAndBags.children + paxAndBags.infants;
    const canIncrementPax = totalPax < MAX_PAX;

    return {
      adults: canIncrementPax,
      children: canIncrementPax,
      infants: paxAndBags.infants < paxAndBags.adults && canIncrementPax,
      cabinBags:
        paxAndBags.cabinBags < paxAndBags.adults + paxAndBags.children &&
        paxAndBags.cabinBags < MAX_CABIN_BAGS,
      checkedBags:
        paxAndBags.checkedBags < (paxAndBags.adults + paxAndBags.children) * 2 &&
        paxAndBags.cabinBags < MAX_CHECKED_BAGS,
    };
  };

  const canDecrement = paxAndBags => ({
    adults: paxAndBags.adults > 1,
    children: paxAndBags.children > 0,
    infants: paxAndBags.infants > 0,
    cabinBags: paxAndBags.cabinBags > 0,
    checkedBags: paxAndBags.checkedBags > 0,
  });

  const handleIncrement = useCallback(key => {
    dispatch({ type: 'INCREMENT', key });
  }, []);

  const handleDecrement = useCallback(key => {
    if (key === 'adults') {
      dispatch({ type: 'DECREMENT_ADULTS' });
    } else if (key === 'children') {
      dispatch({ type: 'DECREMENT_CHILDREN' });
    } else {
      dispatch({ type: 'DECREMENT', key });
    }
  }, []);

  const restoreStateFromSnapshot = useCallback(snapshot => {
    dispatch({ type: 'RESTORE_FROM_SNAPSHOT', snapshot });
  }, []);

  const resetBagsFilter = useCallback(() => {
    dispatch({ type: 'RESET_BAGS_FILTER', payload: { cabinBags: 0, checkedBags: 0 } });
  }, []);

  const resetToInitialState = useCallback(() => {
    dispatch({ type: 'RESET_TO_INITIAL_STATE', payload: initialState });
  }, []);

  const passengers = `${state.adults}-${state.children}-${state.infants}`;
  const bags = `${state.cabinBags}.${state.checkedBags}`;
  useEffect(() => {
    onChange?.({ passengers, bags });

    if (!noDeeplinkSync) {
      deeplinkDispatch({ type: 'ASSIGN', value: { passengers, bags } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengers, bags]);

  const value = {
    ...state,
    totalPax,
    totalBags,
    canIncrement,
    canDecrement,
    handleIncrement,
    handleDecrement,
    restoreStateFromSnapshot,
    resetBagsFilter,
    resetToInitialState,
  };

  return (
    <PassengersAndBagsState.Provider value={value}>{children}</PassengersAndBagsState.Provider>
  );
};

export default PassengersAndBagsProvider;
