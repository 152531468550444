import React, { useState, useContext, useMemo, useEffect } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import Button from '@kiwicom/orbit-components/lib/Button';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';
import { left, right } from '@kiwicom/orbit-components/lib/utils/rtl';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

import {CurrenciesState}  from '../../services/currencies/CurrenciesProvider';
import { chunk } from '../../utils/array';
import useViewport from '../../scenes/Basic/services/Viewport/useViewport';
import { usePostMessages } from '../../services/postMessages';
import PopoverActions from '../../common/PopoverActions';
let bb = CurrenciesState;
const COLUMNS = 4;

const Column = styled.div`
  width: 100%;

  ${media.largeMobile(css`
    width: ${100 / COLUMNS}%;
  `)};
`;

const CurrencyWrapper = styled.div`
  margin-${right}: 25px;

  & button {
    justify-content: flex-start;

    & > div {
      justify-content: flex-start;
      text-align: left;
    }
  }

  &:not(.active) button {
    background: transparent;

    &:hover {
      background: ${({ theme }) => theme.orbit.backgroundButtonSecondaryHover};
    }
  }
`;

const ChoiceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 465px;
`;

const CurrencySymbol = styled.span`
  margin-${left}: 10px;
  font-weight: 400;
`;

// orbit has a nasty bug where it hides the popover on 576-650px
const OrbitFixGlobalStyles = createGlobalStyle`
  @media all and (min-width: 576px) and (max-width: 655px) {
    div[data-test="currencies-filter-popover"][class^="ContentWrapper__StyledPopoverParent"] {
      display: block;
      opacity: 1;
      z-index: 1000;
    }
  }
`;

const CurrenciesFilter = ({ popoverActions }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { loading, currencies, activeCurrencyCode, changeCurrency } = useContext(CurrenciesState);
  const { isMobile } = useViewport();
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const [contentHeight, setContentHeight] = useState(false);

  const chunkedCurrencies = useMemo(() => {
    if (!currencies) {
      return [];
    }

    return chunk(currencies, Math.ceil(currencies.length / COLUMNS));
  }, [currencies]);

  const [selectedCurr, setSelectedCurr] = useState({ code: activeCurrencyCode });

  useEffect(() => {
    setSelectedCurr({ code: activeCurrencyCode });
  }, [activeCurrencyCode]);

  if (loading) {
    return (
      <ButtonLink
        size="small"
        type="secondary"
        iconRight={<ChevronDownIcon />}
        loading
        title="Loading currencies"
      />
    );
  }

  const handleOpenPopover = () => {
    if (isMobile) {
      setContentHeight(window.innerHeight);
      postIframeHeight(700);
      lockResizing();
    }
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setSelectedCurr({ code: activeCurrencyCode });
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeight, true);
    }
    setPopoverOpen(false);
  };

  const handleCurrencyChange = () => {
    changeCurrency(selectedCurr.code);
    handleClosePopover();
  };

  return (
    <>
      <OrbitFixGlobalStyles />
      <Popover
        opened={popoverOpen}
        onOpen={handleOpenPopover}
        onClose={handleClosePopover}
        fixed
        overlapped
        dataTest="currencies-filter-popover"
        actions={popoverActions}
        content={
          <div>
            <PopoverActions onClose={handleClosePopover} onSubmit={handleCurrencyChange} />
            <ChoiceWrapper>
              {chunkedCurrencies.map((currs, i) => (
                <Column key={i}>
                  {currs.map(curr => (
                    <CurrencyWrapper
                      key={curr.code}
                      className={
                        (selectedCurr.code || activeCurrencyCode) === curr.code ? 'active' : ''
                      }
                      onClick={() => setSelectedCurr(curr)}
                    >
                      {!isMobile && (
                        <Tooltip content={curr.name}>
                          <Button
                            size="small"
                            type={
                              (selectedCurr.code || activeCurrencyCode) === curr.code
                                ? 'primary'
                                : 'secondary'
                            }
                            width="90px"
                          >
                            {curr.code.toUpperCase()}
                            <CurrencySymbol>{curr.symbol}</CurrencySymbol>
                          </Button>
                        </Tooltip>
                      )}
                      {isMobile && (
                        <Button
                          size="small"
                          type={
                            (selectedCurr.code || activeCurrencyCode) === curr.code
                              ? 'primary'
                              : 'secondary'
                          }
                          width="90px"
                        >
                          {curr.code.toUpperCase()}
                          <CurrencySymbol>{curr.symbol}</CurrencySymbol>
                        </Button>
                      )}
                    </CurrencyWrapper>
                  ))}
                </Column>
              ))}
            </ChoiceWrapper>
          </div>
        }
      >
        <ButtonLink size="small" type="secondary" iconRight={<ChevronDownIcon />}>
          {activeCurrencyCode.toUpperCase()}
        </ButtonLink>
      </Popover>
    </>
  );
};

export default React.memo(CurrenciesFilter);
