/* global */
import React from 'react';
import Button from '@kiwicom/orbit-components/lib/Button';
import styled, { css, keyframes } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

import  useTranslations  from '../../services/translations/useTranslations';
import {__} from "ramda";

const pulseMobile = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  25%, 100% {
    transform: scale(1.04, 1.4); /* scale approximately the same amount of pixels, don’t maintaint aspect ratio */
    opacity: 0;
  }
`;

const pulseDesktop = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  25%, 100% {
    transform: scale(1.2, 1.4); /* scale approximately the same amount of pixels, don’t maintaint aspect ratio */
    opacity: 0;
  }
`;

const ButtonAnimation = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  background: ${({ theme }) => theme.orbit.backgroundButtonPrimary};
  animation: ${pulseMobile} 5s cubic-bezier(0.135, 0.085, 0, 1) infinite;

  ${media.desktop(css`
    animation: ${pulseDesktop} 5s cubic-bezier(0.135, 0.085, 0, 1) infinite;
  `)}
`;

const SearchButton = ({ onClick, ...props }) => {
  const { translate } = useTranslations();

  const handleClick = (...args) => {
    onClick(...args);
    if (window.forceSentryError === true) {
      throw new Error('Forced Sentry error');
    }
  };

  return (
    <Button type="primary" {...props} dataTest="SearchButton" onClick={handleClick}>
      {translate(('search.form.button.search'))}
      <ButtonAnimation />
    </Button>
  );
};

export default SearchButton;
