import { useContext } from 'react';
import TagManager from 'react-gtm-module';

import usePaxAndBags from '../../services/filters/PassengersAndBags/usePaxAndBags';
import useEntryParameters from '../../services/entryParameters/useEntryParameters';
import  useSearchFormState  from '../../scenes/Basic/services/SearchForm/useSearchForm';
import {SearchState} from '../../services/filters/Search/SearchProvider';
import { GTM_EVENTS } from '../../consts/tracking';

import { mapDataLayer } from './utils';

export const useGTM = () => {
  const { cabinClass, applyMixedClasses } = useContext(SearchState);
  const { mode, source, destination, outboundDate, inboundDate } = useSearchFormState();
  const { adults, children, infants, cabinBags, checkedBags } = usePaxAndBags();
  const entryParams = useEntryParameters();
  const searchData = {
    affilId: entryParams.affilid,
    adults,
    children,
    infants,
    cabinBags,
    checkedBags,
    mode,
    source: source?.length ? source : entryParams.source,
    destination: destination?.length ? destination : entryParams.destination,
    outboundDate,
    inboundDate,
    cabinClass,
    applyMixedClasses,
  };

  const pushToDataLayer = (event, payload) => {
    return;
  };
  return { pushToDataLayer };
};
