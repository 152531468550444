/* global __ */
import React, { useCallback, useState } from 'react';
import isSameDay from 'date-fns/isSameDay';
import Text from '@kiwicom/orbit-components/lib/Text';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import ShowMore from '@kiwicom/orbit-components/lib/icons/ShowMore';
import ShowLess from '@kiwicom/orbit-components/lib/icons/ShowLess';
import CircleSmall from '@kiwicom/orbit-components/lib/icons/CircleSmall';
import AirplaneDown from '@kiwicom/orbit-components/lib/icons/AirplaneDown';
import Bus from '@kiwicom/orbit-components/lib/icons/Bus';
import Train from '@kiwicom/orbit-components/lib/icons/Train';

import  useTranslations  from '../../../services/translations/useTranslations';
import Timestamp from '../../../common/Timestamp';
import ItineraryTimeBadge from '../../../common/ItineraryTimeBadge';

import { TripDetail, TripPlaceText } from '../index.styled';

import TripGrid from './TripGrid';
import TripSegmentDetails from './TripSegmentDetails';
// import TripSegmentDetail from './TripSegmentDetail';
// import TripSegmentCabinClassInfo from './TripSegmentCabinClassInfo';
// import Duration from '../Timestamp/Duration';
// import * as PartUtils from 'common/records/Part/partUtils';
import {
  TripTime,
  TripTimeText,
  TripPlace,
  TripFlightCarrier,
  TripFlightInfo,
  ShowMoreIcon,
  TripSegmentAlertDate,
  TripSegmentAlertInfo,
  SegmentTextWrapper,
} from './TripSegment.styled';
import CabinClassInfo from './CabinClassInfo';

const partTypeIcons = {
  FLIGHT: <AirplaneDown size="small" />,
  TRAIN: <Train size="small" />,
  BUS: <Bus size="small" />,
};

const TripSegment = ({ segment }) => {
  const [isDetailShown, setDetailShown] = useState(false);
  const toggleDetailShown = useCallback(() => {
    setDetailShown(!isDetailShown);
  }, [setDetailShown, isDetailShown]);
  const { translate } = useTranslations();

  const SEGMENT_DETAIL_ID = `segment-detail-${segment.id}`;

  return (
    <TripDetail key={segment.id}>
      <TripGrid>
        {/* {departureNextDay && (
          <React.Fragment key="tripAlertDeparture">
            <TripSegmentAlertDate isFirst>
              <Text type="critical" size="small" align="center">
                <Timestamp formatKeys="dateFormat" value={part.departure.when.local} />
              </Text>
            </TripSegmentAlertDate>
            <TripSegmentAlertInfo isFirst>
              <Text element="span" type="critical" size="small" align="center">
                <Translate
                  t={
                    part.type === 'flight'
                      ? ('multi_modal.search.detail.alert.departing_next_day.flight_only')
                      : ('multi_modal.search.detail.alert.departing_next_day.bus_train')
                  }
                />
              </Text>
            </TripSegmentAlertInfo>
          </React.Fragment>
        )} */}

        <TripTime>
          <TripTimeText>
            <Timestamp formatKeys="timeFormat" value={new Date(segment.source.localTime)} isLocal />
          </TripTimeText>
        </TripTime>
        <TripPlace isFirst>
          <Stack align="center" spacing="XSmall">
            <CircleSmall size="small" color="secondary" />
            <SegmentTextWrapper>
              <TripPlaceText>{segment.source.station.city.name}</TripPlaceText>
              <Text size="small" type="secondary">
                {segment.source.station.name || segment.source.station.code}
              </Text>
            </SegmentTextWrapper>
          </Stack>
        </TripPlace>

        <CabinClassInfo segment={segment} />

        <TripFlightCarrier onClick={toggleDetailShown} clickable>
          <CarrierLogo
            size="medium"
            carriers={[{ code: segment.carrier.code, name: segment.carrier.name }]}
          />
        </TripFlightCarrier>
        <TripFlightInfo onClick={toggleDetailShown} clickable>
          <Stack align="center" spacing="XSmall">
            {partTypeIcons[segment.type]}
            <Stack spacing="XXSmall" direction="row" inline>
              <Badge type="white">{segment.carrier.name}</Badge>
              <ItineraryTimeBadge duration={segment.duration} />
            </Stack>
            <ShowMoreIcon
              aria-expanded={isDetailShown}
              aria-controls={SEGMENT_DETAIL_ID}
              aria-label={isDetailShown ? 'Hide details' : 'Show details'}
            >
              {isDetailShown ? <ShowLess /> : <ShowMore />}
            </ShowMoreIcon>
          </Stack>
          {isDetailShown && <TripSegmentDetails segment={segment} id={SEGMENT_DETAIL_ID} />}
        </TripFlightInfo>

        {!isSameDay(
          new Date(segment.source.localTime),
          new Date(segment.destination.localTime),
        ) && (
          <React.Fragment key="tripAlertArrival">
            <TripSegmentAlertDate>
              <Text type="critical" size="small" align="center">
                <Timestamp
                  formatKeys="dateFormat"
                  value={new Date(segment.destination.localTime)}
                  isLocal
                />
              </Text>
            </TripSegmentAlertDate>
            <TripSegmentAlertInfo>
              <Text element="span" type="critical" size="small">
                {translate(
                  segment.type === 'FLIGHT'
                    ? ('multi_modal.search.detail.alert.arriving_different_day.flight_only')
                    : ('multi_modal.search.detail.alert.arriving_next_day.bus_train'),
                )}
              </Text>
            </TripSegmentAlertInfo>
          </React.Fragment>
        )}

        <TripTime>
          <TripTimeText>
            <Timestamp
              formatKeys="timeFormat"
              value={new Date(segment.destination.localTime)}
              isLocal
            />
          </TripTimeText>
        </TripTime>
        <TripPlace>
          <Stack align="center" spacing="XSmall">
            <CircleSmall size="small" color="secondary" />
            <Stack direction="column" spacing="XXSmall">
              <TripPlaceText>{segment.destination.station.city.name}</TripPlaceText>
              <Text size="small" type="secondary">
                {segment.destination.station.name || segment.destination.station.code}
              </Text>
            </Stack>
          </Stack>
        </TripPlace>
      </TripGrid>
    </TripDetail>
  );
};

export default TripSegment;
