import Price from '../../common/Price';
import Translate from '../../common/Translate';
import {__} from "ramda";

Math.log10 = Math.log10 || (x => Math.log(x) / Math.LN10);

export const MAX_POWER = 70; // that's just random numbers which looked nice
export const MIN_POWER = 20;
export const MIN_PRICE = 0; // in euros
export const MAX_PRICE = 3000;
export const POWER_MAGIC_NUMBER = 20; // don't ask

export function powerToEuros(power, side) {
  if (side === 'max' && power === MAX_POWER) {
    return 0; // disabled filter
  }
  if (side === 'min' && power === MIN_POWER) {
    return 0; // disabled filter
  }
  return 10 ** (power / POWER_MAGIC_NUMBER);
}

export function eurosToPower(euros, side) {
  if (side === 'max' && euros === 0) {
    return MAX_POWER; // disabled filter
  }
  if (side === 'min' && euros === 0) {
    return MIN_POWER; // disabled filter
  }
  return Math.round(Math.log10(euros) * POWER_MAGIC_NUMBER);
}

export function parsePricesForHistogram(prices, rate) {
  function parseRangeValue(range) {
    const min = parseFloat(range.split('-')[0].split('+')[0]) * rate;
    return eurosToPower(min, 'min') - MIN_POWER;
  }

  return prices.reduce((acc, range) => {
    acc[parseRangeValue(range.label)] = range.frequency;

    return acc;
  }, Array(MAX_POWER - MIN_POWER + 1));
}

export const getValueDescription = price => {
  const minPrice = Math.round(price[0]);
  const maxPrice = Math.round(price[1]);

  if ((minPrice || maxPrice) && (minPrice !== MIN_PRICE || maxPrice !== MAX_PRICE)) {
    return (
      <>
        {<Price convert>{minPrice || 0}</Price>}
        &nbsp;-&nbsp;
        {maxPrice ? (
          <Price convert>{maxPrice}</Price>
        ) : (
          <Translate tkey={('search.filter.price.any_price')} />
        )}
      </>
    );
  }
  return <Translate tkey={('search.filter.price.any_price')} />;
};
