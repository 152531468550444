import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Slider from '@kiwicom/orbit-components/lib/Slider';
import { format, setHours, setMinutes, subMinutes } from 'date-fns';

import Tabs from '../../common/Tabs';
import  useTranslations  from '../../services/translations/useTranslations';
import Translate from '../../common/Translate';

const SliderWrapper = styled.div``;

const Container = styled.div`
  margin-bottom: 16px;

  ${({ standalone }) =>
    standalone
      ? css`
          display: flex;

          & > ${SliderWrapper} {
            flex: 1;

            &:first-of-type {
              margin-right: 32px;
            }
          }
        `
      : css`
          & > ${SliderWrapper} {
            margin-bottom: 16px;
          }
        `}
`;

const SLIDER_LABELS = {
  departure: ('search.filter.times.departure'),
  arrival: ('search.filter.times.arrival'),
};

const MIN = 0;
const MAX = 24;

const getValueDescription = ({ value, locale }) => {
  if (value[0] === MIN && value[1] === MAX) {
    return <Translate tkey={('search.filter.times.all_day')} />;
  }

  const subMaxMinutes = value[1] === MAX ? 1 : 0;
  const min = format(setMinutes(setHours(new Date(), value[0]), 0), 'p', { locale });
  const max = format(
    subMinutes(setMinutes(setHours(new Date(), value[1]), 0), subMaxMinutes),
    'p',
    {
      locale,
    },
  );

  return <Translate tkey={('search.filter.times.from_to_time')} values={{ min, max }} />;
};

const TimeSlider = ({ type, direction, value, onChange }) => {
  const val = value[type];
  const [times, setTimes] = useState(val);
  const { translate, locale } = useTranslations();

  const timesKey = JSON.stringify(val);

  useEffect(() => {
    setTimes(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesKey]);

  const handleChange = value => {
    setTimes(value);
  };

  const handleRelease = newTime => {
    setTimes(newTime);
    onChange({
      value: newTime,
      type,
      direction,
    });
  };

  return (
    <Slider
      label={translate(SLIDER_LABELS[type])}
      valueDescription={getValueDescription({ value: times, locale })}
      onChange={handleChange}
      defaultValue={times}
      onChangeAfter={handleRelease}
      minValue={MIN}
      maxValue={MAX}
    />
  );
};

export const TimeTab = React.memo(({ standalone, direction, value, onChange }) => {
  return (
    <Container standalone={standalone}>
      <SliderWrapper>
        <TimeSlider type="departure" direction={direction} value={value} onChange={onChange} />
      </SliderWrapper>

      <SliderWrapper>
        <TimeSlider type="arrival" direction={direction} value={value} onChange={onChange} />
      </SliderWrapper>
    </Container>
  );
});

const TimeOfTravel = ({ standalone, oneWay, value, onChange }) => {
  const tabItems = useMemo(() => {
    return [
      {
        label: 'Departure',
        value: 'departure-time',
        component: (
          <TimeTab
            direction="outbound"
            value={value.outbound}
            standalone={standalone}
            onChange={onChange}
          />
        ),
      },
      {
        label: 'Return',
        value: 'return-time',
        component: (
          <TimeTab
            direction="inbound"
            value={value.inbound}
            standalone={standalone}
            onChange={onChange}
          />
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standalone, value]);

  if (oneWay) {
    return tabItems[0].component;
  }

  return (
    <Tabs items={tabItems} standalone={standalone} translateLabels transparent lineSize="small" />
  );
};

export default React.memo(TimeOfTravel);
