import { gql } from 'graphql.macro';

import * as fragments from '../../../../../graphql/fragments/index';

export default gql`
  query AggregateResultsOneWayItinerariesQuery_BasicWidget(
    $search: SearchOnewayInput
    $filter: ItinerariesFilterInput
    $options: ItinerariesOptionsInput
  ) {
    onewayOnePerCityItineraries(search: $search, filter: $filter, options: $options) {
      __typename
      ... on AppError {
        error: message
      }
      ... on OnePerCityItineraries {
        server {
          serverToken
        }
        metadata {
          ...AggregatedItinerariesMetadata
        }
        itineraries {
          __typename
          ... on OnewayOnePerCityItinerary {
            ... on OnePerCityItinerary {
              price {
                amount
                priceBeforeDiscount
                formattedValue
              }
              locationHashtags {
                id
                slug
                label
              }
              source {
                ...AggregatedSearchStop
              }
              destination {
                ...AggregatedSearchStop
              }
            }
          }
        }
      }
    }
  }

  ${fragments.AggregatedItinerariesMetadata}
  ${fragments.AggregatedSearchStop}
`;
