export const range = (n1, n2) => {
  const res = [];

  if (!n2) {
    for (let i = 0; i < n1; i++) {
      res.push(i);
    }
  } else {
    for (let i = n1; i < n2; i++) {
      res.push(i);
    }
  }

  return res;
};

export const uniqueBy = (array, getKey) => {
  const map = {};

  for (const el of array) {
    const key = getKey(el);
    if (key in map) {
      continue;
    }
    map[key] = el;
  }

  return Object.values(map);
};

export const removeAdjacentDuplicates = arr => {
  const res = [];

  for (const el of arr) {
    if (res[res.length - 1] === el) {
      continue;
    }
    res.push(el);
  }

  return res;
};

export const chunkArray = (array, size) => {
  const arr = [...array];
  const res = [];

  while (arr.length) {
    res.push(arr.splice(0, size));
  }

  return res;
};

export const difference = (arr1, arr2) => arr1.filter(value => !arr2.includes(value));

export const chunk = (arr, chunkSize) => {
  const result = [];

  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }

  return result;
};
