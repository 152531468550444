/* global __ */
import React, { useState } from 'react';
import styled from 'styled-components';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import Text from '@kiwicom/orbit-components/lib/Text';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import SortIcon from '@kiwicom/orbit-components/lib/icons/Sort';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';
import  useTranslations  from '../../../../../../services/translations/useTranslations';


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
`;

const TableRow = styled.tr`
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  cursor: pointer;

  time {
    white-space: nowrap;
  }
`;

const TableCell = styled.td`
  padding: 12px 4px 2px 12px;
  vertical-align: bottom;

  &:first-of-type {
    padding: 12px 4px 2px 24px;
  }
`;

export const VerticalCenter = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-${right}: 8px;
  }
`;

const SortByDesktop = ({ sortBy, changeSortBy, options }) => {
  const [opened, setOpened] = useState(false);
  const { translate } = useTranslations();

  const handleSortSelect = sort => {
    changeSortBy(sort);
    setOpened(false);
  };

  return (
    <Popover
      opened={opened}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      overlapped
      fixed
      content={
        <div>
          <Text type="secondary">
            <VerticalCenter>
              <SortIcon size="small" /> {translate(('search.result.sorting.name'))}
            </VerticalCenter>
          </Text>
          <Table>
            <tbody>
              <TableRow
                data-test="SortBy-popularity"
                onClick={() => handleSortSelect('POPULARITY')}
              >
                <TableCell>
                  <Text weight={sortBy === 'POPULARITY' ? 'bold' : 'normal'}>
                    {translate(options.POPULARITY)}
                  </Text>
                </TableCell>
              </TableRow>
              <TableRow data-test="SortBy-price" onClick={() => handleSortSelect('PRICE')}>
                <TableCell>
                  <Text weight={sortBy === 'PRICE' ? 'bold' : 'normal'}>
                    {translate(options.PRICE)}
                  </Text>
                </TableCell>
              </TableRow>
            </tbody>
          </Table>
        </div>
      }
    >
      <ButtonLink dataTest="SortingButton" size="small" type="secondary" iconLeft={<SortIcon />}>
        {translate(options[sortBy])}
      </ButtonLink>
    </Popover>
  );
};

export default SortByDesktop;
