/* global __ */
import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import SortIcon from '@kiwicom/orbit-components/lib/icons/Sort';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';

import useTranslations  from '../../../../../../services/translations/useTranslations';
import Price from '../../../../../../common/Price';
import TripTime from '../../../../../../common/TripTime';
import { SORT_BY_TRANSLATIONS } from '../../../../../../consts/sortBy';

import { VerticalCenter } from './common';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
`;

const TableRow = styled.tr`
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  cursor: pointer;

  time {
    white-space: nowrap;
  }
`;

const TableCell = styled.td`
  padding: 12px 4px 2px 12px;
  vertical-align: bottom;

  &:first-of-type {
    padding: 12px 4px 2px 24px;
  }
`;

const RowItem = React.memo(({ sortBy, activeSortBy, data, onClick }) => {
  const { translate } = useTranslations();
  const handleClick = () => {
    onClick(sortBy);
  };

  const isSelected = sortBy === activeSortBy;

  return (
    <TableRow onClick={handleClick} data-test={`SortBy-${sortBy}`}>
      <TableCell>
        <Text weight={isSelected ? 'bold' : 'normal'}>
          {translate(SORT_BY_TRANSLATIONS[sortBy])}
        </Text>
      </TableCell>
      <TableCell>
        <Price highlight={isSelected}>{data.price.amount}</Price>
      </TableCell>
      <TableCell>
        <Text>
          <VerticalCenter>
            <ClockIcon size="small" />
            <TripTime duration={data.duration} highlight={isSelected} />
          </VerticalCenter>
        </Text>
      </TableCell>
    </TableRow>
  );
});

const SortByDesktop = ({ topResults, sortBy, changeSortBy }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { translate } = useTranslations();

  const handleItemClick = sortParam => {
    changeSortBy(sortParam);
    setPopoverOpen(false);
  };

  return (
    <Popover
      opened={popoverOpen}
      onOpen={() => setPopoverOpen(true)}
      onClose={() => setPopoverOpen(false)}
      overlapped
      fixed
      content={
        <div>
          <Text type="secondary">
            <VerticalCenter>
              <SortIcon size="small" /> {translate(('result.sorting.name'))}
            </VerticalCenter>
          </Text>
          <Table style={{ width: '100%' }}>
            <tbody>
              <RowItem
                sortBy="best"
                activeSortBy={sortBy}
                data={topResults.best}
                onClick={handleItemClick}
              />
              <RowItem
                sortBy="price"
                activeSortBy={sortBy}
                data={topResults.cheapest}
                onClick={handleItemClick}
              />
              <RowItem
                sortBy="duration"
                activeSortBy={sortBy}
                data={topResults.fastest}
                onClick={handleItemClick}
              />
            </tbody>
          </Table>
        </div>
      }
    >
      <ButtonLink size="small" type="secondary" iconLeft={<SortIcon />} dataTest="SortingButton">
        {translate(SORT_BY_TRANSLATIONS[sortBy])}
      </ButtonLink>
    </Popover>
  );
};

export default SortByDesktop;
