import React, { useReducer } from 'react';

export const PostMessagesState = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_RESIZE_LOCK':
      return {
        ...state,
        resizingLocked: action.resizingLocked,
      };
    default:
      return state;
  }
};

const initialState = {
  resizingLocked: false,
};

const PostMessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const lockResizing = () => {
    dispatch({ type: 'SET_RESIZE_LOCK', resizingLocked: true });
  };

  const unlockResizing = () => {
    dispatch({ type: 'SET_RESIZE_LOCK', resizingLocked: false });
  };

  const value = {
    ...state,
    lockResizing,
    unlockResizing,
  };

  return <PostMessagesState.Provider value={value}>{children}</PostMessagesState.Provider>;
};

export default PostMessagesProvider;
