import React from 'react';
import { format, formatDistanceToNow } from 'date-fns';

import  useTranslations  from '../../services/translations/useTranslations';

const toUtc = date => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

const getHumanReadable = (relative, timestamp, formatKeys, localeData, locale) => {
  if (relative) {
    return formatDistanceToNow(timestamp);
  }

  if (formatKeys) {
    return format(new Date(timestamp), localeData[formatKeys], { locale });
  }
  return null;
};

const Timestamp = ({ value, formatKeys, relative, className = '', isLocal }) => {
  const { localeData, locale } = useTranslations();

  if (!value) {
    return null;
  }

  const timestamp = isLocal ? value : toUtc(value);
  const machineReadable = format(timestamp, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

  const humanReadable = getHumanReadable(
    Boolean(relative),
    timestamp,
    formatKeys,
    localeData,
    locale,
  );

  return humanReadable ? (
    <time
      className={className}
      dateTime={machineReadable}
      dangerouslySetInnerHTML={{ __html: humanReadable }}
    />
  ) : (
    <time className={className}>{machineReadable}</time>
  );
};

export default Timestamp;
