/* global __ */
import React, { useState } from 'react';
import styled from 'styled-components';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import  useTranslations  from '../../services/translations/useTranslations';
import useViewport from '../../scenes/Basic/services/Viewport/useViewport';
import { usePostMessages } from '../../services/postMessages';
import { useGTM } from '../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../consts/tracking';
import PopoverActions from '../../common/PopoverActions';


const EmptyIcon = styled.div`
  width: 16px;
  height: 16px;
`;

const DirectionWrapper = styled.div`
  padding: 16px 16px 0 16px;
`;

const options = [
  {
    label: ('search.form.journey_mode.return'),
    value: 'return',
  },
  {
    label: ('search.form.journey_mode.one_way'),
    value: 'one-way',
  },
];

const DirectionFilter = ({ value = 'return', onChange, popoverActions }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { translate } = useTranslations();
  const { isMobile } = useViewport();
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const [contentHeight, setContentHeight] = useState(false);
  const { pushToDataLayer } = useGTM();
  const [direction, setDirection] = useState(value);

  const handleOpenPopover = () => {
    if (isMobile) {
      setContentHeight(window.innerHeight);
      postIframeHeight(600);
      lockResizing();
    }
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setDirection(value);
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeight, true);
    }
    setPopoverOpen(false);
  };

  const handleSubmit = () => {
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeight, true);
    }
    onChange(direction);
    setPopoverOpen(false);
  };

  return (
    <Popover
      opened={popoverOpen}
      onOpen={handleOpenPopover}
      onClose={handleClosePopover}
      overlapped
      noPadding
      fixed
      actions={popoverActions}
      content={
        <DirectionWrapper>
          <PopoverActions onClose={handleClosePopover} onSubmit={handleSubmit} spaceAfter="none" />

          {options.map(o => (
            <ListChoice
              dataTest={`ModePopupOption-${o.value}`}
              key={o.value}
              icon={direction === o.value ? <CheckIcon /> : <EmptyIcon />}
              title={translate(o.label)}
              onClick={() => {
                pushToDataLayer(GTM_EVENTS.SEARCH_FILTER, { mode: o.value });
                setDirection(o.value);
              }}
            />
          ))}
        </DirectionWrapper>
      }
    >
      <ButtonLink
        dataTest="SearchFormModesPicker"
        size="small"
        type="secondary"
        iconRight={<ChevronDownIcon />}
      >
        {translate(options.find(o => o.value === value).label)}
      </ButtonLink>
    </Popover>
  );
};

export default DirectionFilter;
