/* global __ */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';

import  useSearchParams  from '../../../../../../services/filters/Search/useSearchParams';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import { getHashtagTranslation } from '../../../../../../utils/hashtags';
import Translate from '../../../../../../common/Translate';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

const HashtagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;

  & > div {
    margin: 2px !important;
  }
`;

const HashtagButtonWrapper = styled.div`
  ${({ isSelected }) =>
    isSelected &&
    css`
      button {
        color: rgb(255, 255, 255) !important;
        background: rgb(95, 115, 140);

        &:hover {
          color: rgb(255, 255, 255) !important;
          background: rgb(82, 100, 122);
        }

        &:focus {
          box-shadow: none;
          background: rgb(95, 115, 140) !important;
        }
      }
    `}
`;

const HashtagButton = ({ hashtag, selectedHashtags, handleChange }) => {
  const label = getHashtagTranslation(hashtag.label);

  if (label === undefined) {
    return null;
  }

  const isSelected = selectedHashtags.includes(hashtag.id);

  return (
    <HashtagButtonWrapper key={hashtag.id} isSelected={isSelected}>
      <Button type="secondary" size="small" onClick={() => handleChange(isSelected, hashtag.id)}>
        <Translate tkey={label} />
      </Button>
    </HashtagButtonWrapper>
  );
};

const LocationHashtagsFilter = ({ asButton, hashtags, popoverActions }) => {
  const [open, setOpen] = useState(false);
  const { translate } = useTranslations();
  const searchParams = useSearchParams();

  const { setLocationHashtags, locationHashtags } = searchParams;

  const [selectedHashes, setSelectedHashes] = useState(locationHashtags);

  const handleClose = () => {
    setSelectedHashes(locationHashtags);
    setOpen(false);
  };

  const handleChange = (isSelected, hashtagId) => {
    if (isSelected) {
      setSelectedHashes(selectedHashes.filter(h => h !== hashtagId));
    } else {
      setSelectedHashes([...selectedHashes, hashtagId]);
    }
  };

  const handleSubmit = () => {
    setLocationHashtags(selectedHashes);
    setOpen(false);
  };

  return (
    <FilterWrapper
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      label={translate(('result.filter.locations'))}
      asButton={asButton}
      disabled={!hashtags.length}
      actions={popoverActions}
      width="260px"
    >
      <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />
      <HashtagsWrapper>
        {hashtags.map(h => (
          <HashtagButton
            key={h.id}
            hashtag={h}
            selectedHashtags={selectedHashes}
            handleChange={handleChange}
          />
        ))}
      </HashtagsWrapper>
    </FilterWrapper>
  );
};

export default LocationHashtagsFilter;
