import React from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';

import Timestamp from '../../common/Timestamp/';

import { SemiBold, TripPlace, TripPlaceDetails } from './ItineraryPlace.styled';

const ResultCardItineraryPlace = ({ destination, narrowView }) => (
  <Text dataTest="FlightSectionTimeAndPlace">
    <SemiBold>
      <Timestamp formatKeys="timeFormat" value={new Date(destination.localTime)} isLocal />
      <TripPlace narrowView={narrowView}>
        {destination.station.city.name || destination.station.name}
      </TripPlace>
    </SemiBold>
    <TripPlaceDetails narrowView={narrowView} data-test="FlightSectionPlace">
      {destination.station.name || destination.station.code}
    </TripPlaceDetails>
  </Text>
);

export default ResultCardItineraryPlace;
