/* global __ */
import React, { useState, useEffect } from 'react';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import  useSearchParams  from '../../../../../../services/filters/Search/useSearchParams';
import CheckboxFilterItem from '../../../../../../common/CheckboxFilterItem';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

const TransportFilter = ({ asButton, noButtons = false, popoverActions }) => {
  const [open, setOpen] = useState(false);
  const {
    transportTypes,
    setTransportTypes,
    toggleTransport,
    toggleTransportOnly,
    clearTransportFilters,
    getDefaultValues,
  } = useSearchParams();
  const { translate } = useTranslations();
  const { pushToDataLayer } = useGTM();
  const [transport, setTransport] = useState(transportTypes);

  useEffect(() => {
    setTransport(transportTypes);
  }, [transportTypes]);

  const handleClose = () => {
    setTransport(transportTypes);
    setOpen(false);
  };

  const handleToggle = value => {
    setTransport(
      transport.includes(value) ? transport.filter(t => t !== value) : [...transport, value],
    );
  };

  const handleToggleTransportOnly = value => {
    setTransport([value]);
  };

  const handleSubmit = () => {
    setTransportTypes(transport);
    setOpen(false);
  };

  const handleClear = () => {
    setTransport(getDefaultValues('transportTypes'));
  };

  return (
    <FilterWrapper
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      asButton={asButton}
      label={translate(('search.filter.transportation_type.title'))}
      actions={
        transport.length !== 3 ? (
          <TextLink
            size="small"
            type="secondary"
            onClick={() => (noButtons ? clearTransportFilters() : handleClear())}
            dataTest="ClearFilter-transport"
          >
            {translate(('search.filter.multi_used.button.clear'))}
          </TextLink>
        ) : (
          popoverActions
        )
      }
      width="260px"
    >
      {!noButtons && <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />}
      <CheckboxFilterItem
        label={translate(('search.filter.transportation_type.options.flight'))}
        value="FLIGHT"
        disabled={transport.length === 1 && transport[0] === 'FLIGHT'}
        checked={transport.includes('FLIGHT')}
        onToggle={value => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? toggleTransport(value) : handleToggle(value);
        }}
        onOnlyClick={value => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? toggleTransportOnly(value) : handleToggleTransportOnly(value);
        }}
      />
      <CheckboxFilterItem
        label={translate(('search.filter.transportation_type.options.bus'))}
        value="BUS"
        disabled={transport.length === 1 && transport[0] === 'BUS'}
        checked={transport.includes('BUS')}
        onToggle={value => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? toggleTransport(value) : handleToggle(value);
        }}
        onOnlyClick={value => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? toggleTransportOnly(value) : handleToggleTransportOnly(value);
        }}
      />
      <CheckboxFilterItem
        label={translate(('search.filter.transportation_type.options.train'))}
        value="TRAIN"
        disabled={transport.length === 1 && transport[0] === 'TRAIN'}
        checked={transport.includes('TRAIN')}
        onToggle={value => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? toggleTransport(value) : handleToggle(value);
        }}
        onOnlyClick={value => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? toggleTransportOnly(value) : handleToggleTransportOnly(value);
        }}
      />
    </FilterWrapper>
  );
};

export default TransportFilter;
