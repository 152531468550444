import React from 'react';
import styled from 'styled-components';

const TripGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    box-sizing: border-box;
  }

  > *:nth-child(2n - 1) {
    justify-content: center;
    width: 5.75em;
  }

  > *:nth-child(2n) {
    width: calc(100% - 5.75em);
  }
`;

const TripGrid = ({ children, element }) => (
  <TripGridWrapper as={element}>{children}</TripGridWrapper>
);

export default TripGrid;
