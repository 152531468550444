/* global __ */
import React, { useState } from 'react';
import Button from '@kiwicom/orbit-components/lib/Button';
import Mobile from '@kiwicom/orbit-components/lib/Mobile';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import useTranslations  from '../../services/translations/useTranslations';
import ResultModal from '../../common/ResultModal';
import { useGTM } from '../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../consts/tracking';
import useCurrency  from '../../services/currencies/useCurrency';
import useCurrencyData from '../../services/currencies/useCurrencyData';

import Itinerary from './Itinerary';
import BookingButton from './BookingButton';
import CardBadges from './CardBadges';
import {
  ResultCardMain,
  ResultCardWrapper,
  ResultCardInner,
  ResultCardActions,
  ResultCardActionsPriceMeta,
  ResultCardActionsPrice,
  PriceText,
  DetailsButtonLargeMobile,
  DetailsButtonMobile,
  ButtonWrapper,
  PriceBeforeDiscountValue,
  PriceDiscountedValue,
} from './index.styled';

const WRAPPER_IDENTIFIER = 'ResultCardWrapper';

const InactiveButtonWrapper = props => <div {...props} />;

const ResultCard = ({ itinerary, narrowView, position, total }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { translate } = useTranslations();
  const { pushToDataLayer } = useGTM();
  const { formatPrice } = useCurrency();
  const { activeCurrencyCode } = useCurrencyData();

  const handleOpenDetails = () => {
    pushToDataLayer(GTM_EVENTS.VIEW_DETAILS, { itinerary, position, total });
    setDetailsOpen(true);
  };

  return (
    <>
      {detailsOpen && <ResultModal itinerary={itinerary} onClose={() => setDetailsOpen(false)} />}
      <ResultCardWrapper
        data-test={WRAPPER_IDENTIFIER}
        narrowView={narrowView}
        role="button"
        tabIndex={0}
      >

        <ResultCardInner narrowView={narrowView} onClick={handleOpenDetails}>
          <ResultCardMain narrowView={narrowView}>
            <Itinerary itinerary={itinerary} narrowView={narrowView} />
            <CardBadges narrowView={narrowView} itinerary={itinerary}>
              <DetailsButtonLargeMobile
                narrowView={narrowView}
                data-test="Journey-toggle"
                aria-hidden
              >
                <ButtonLink
                  type="secondary"
                  asComponent={InactiveButtonWrapper}
                  iconLeft={<ChevronDownIcon />}
                  size="small"
                >
                  {translate(('search.result.itinerary_detail.button.get_detail'))}
                </ButtonLink>
              </DetailsButtonLargeMobile>
            </CardBadges>
          </ResultCardMain>
          <ResultCardActions narrowView={narrowView}>
            <ResultCardActionsPriceMeta data-test="ResultCardPrice" narrowView={narrowView}>
              <ResultCardActionsPrice>
                {itinerary.price.amount !== itinerary.price.priceBeforeDiscount ? (
                  <>
                  <PriceBeforeDiscountValue>{formatPrice(activeCurrencyCode, parseFloat(itinerary.price.priceBeforeDiscount).toFixed(0))}</PriceBeforeDiscountValue>
                  <FlightDirectIcon medium="large" color='secondary' />
                  <PriceDiscountedValue>{itinerary.price.formattedValue}</PriceDiscountedValue>
                  </>
                ) : (
                  <PriceText narrowView={narrowView}>{itinerary.price.formattedValue}</PriceText>
                )}
              </ResultCardActionsPrice>
            </ResultCardActionsPriceMeta>
            <ButtonWrapper>
              <Mobile>
                <DetailsButtonMobile
                  narrowView={narrowView}
                  data-test="BookingButton-Mobile"
                  aria-hidden
                >
                  <Button
                    type="secondary"
                    asComponent={InactiveButtonWrapper}
                    iconLeft={<ChevronDownIcon color="secondary" />}
                    size="small"
                  >
                    {translate(('search.result.itinerary_detail.button.get_detail'))}
                  </Button>
                </DetailsButtonMobile>
              </Mobile>

              <BookingButton
                itinerary={itinerary}
                fullWidth
                hidePrice
                size="small"
                isJourneyResult
                shouldStopPropagation
                type="primary"
                dataTest="BookingButton"
                resultType="result card"
              />
            </ButtonWrapper>
          </ResultCardActions>
        </ResultCardInner>
      </ResultCardWrapper>
    </>
  );
};

export default ResultCard;
