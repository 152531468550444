/* global __ */
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';

import { getMidnight } from './datetime';

export const getTypes = itinerary => {
  return itinerary.segments.map(s => s.type);
};

export const getStopsKey = stops => {
  if (!stops) {
    return ('result.direct');
  }

  if (stops === 1) {
    return ('result.stop');
  }

  return ('result.stops');
};

const getDeparture = trip => trip.segments[0].source;

const getArrival = trip => trip.segments[trip.segments.length - 1].destination;

export const getLayovers = itinerary => {
  const { trips } = itinerary;

  return trips.slice(1).reduce((acc, trip, i) => {
    const prevArrival = getArrival(trips[i]);
    const currDeparture = getDeparture(trip);

    const nightsInDestination = differenceInCalendarDays(
      getMidnight(currDeparture.localTime),
      getMidnight(prevArrival.localTime),
    );

    const layover = {
      duration: differenceInMilliseconds(
        new Date(currDeparture.localTime),
        new Date(prevArrival.localTime),
      ),
      nightsInDestination,
      station: prevArrival.station,
      switchToStation:
        prevArrival.station.code !== currDeparture.station.code ? currDeparture.station : null,
    };
    return layover ? [...acc, layover] : acc;
  }, []);
};
