import React, { useContext } from 'react';

import  useSearchFormState  from '../../../../scenes/Basic/services/SearchForm/useSearchForm';
import  useAggregatedSearch from '../../../../scenes/Basic/services/SearchActions/useAggregatedSearch';
import  useSearch  from '../../../../scenes/Basic/services/SearchActions/useSearch';
import  useCurrency  from '../../../../services/currencies/useCurrency';
import { SearchState } from '../../../../services/filters/Search/SearchProvider';
import usePaxAndBags from '../../../../services/filters/PassengersAndBags/usePaxAndBags';
import { AMBIGUOUS_PLACE_TYPES } from '../../../../consts/place';
//import { retry } from '../../../../utils/functions';
//import { useDependencyEffect } from 'hooks';
import { useDependencyEffect } from '../../../../utils/hooks';
import Basic from "./scenes/Basic/index";

import Aggregated from "./scenes/Aggregated/index";
/*
import basic from "src/scenes/Basic";

const Basic = React.lazy(() =>
  retry(() => import( './scenes/Basic/')),
);*/

const SearchResults = () => {
  const { mode, destination } = useSearchFormState();
  const {
    sortBy,
    transportTypes,
    cabinClass,
    applyMixedClasses,
    price,
    outboundDays,
    inboundDays,
    allowOvernightStopovers,
    stopNumber,
    times,
    returnFromDifferentAirport,
    returnToDifferentAirport,
    locationHashtags,
  } = useContext(SearchState);
  const { activeCurrencyCode } = useCurrency();
  const { cabinBags, checkedBags, adults, children, infants } = usePaxAndBags();
  const aggregatedSearchContext = useAggregatedSearch();
  const searchContext = useSearch();
  useDependencyEffect(() => {
    if (destination.length > 0 && destination.every(p => !AMBIGUOUS_PLACE_TYPES[p.type])) {
      searchContext.loadResults();
    } else {
      aggregatedSearchContext.loadResults();
    }
  }, [
    mode,
    sortBy,
    transportTypes,
    cabinClass,
    applyMixedClasses,
    price,
    outboundDays,
    inboundDays,
    allowOvernightStopovers,
    stopNumber,
    times,
    returnFromDifferentAirport,
    returnToDifferentAirport,
    cabinBags,
    checkedBags,
    activeCurrencyCode,
    locationHashtags,
    adults,
    children,
    infants,
  ]);
  if (destination.length > 0 && destination.every(p => !AMBIGUOUS_PLACE_TYPES[p.type])) {
    return <Basic />;
  }

  return <Aggregated />;
};

export default React.memo(SearchResults);
