/* global __ */
import React from 'react';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Calendar from '@kiwicom/orbit-components/lib/icons/Calendar';
import Location from '@kiwicom/orbit-components/lib/icons/Location';

import  useTranslations  from '../../../services/translations/useTranslations';
import Translate from '../../../common/Translate';
import Timestamp from '../../../common/Timestamp';

// import TripGrid from './TripGrid';
// import TripSegment from './TripSegment';
// import TripStop from './TripStop';
// import TripNights from './TripNights';
// import * as TripUtils from 'common/records/Trip/utils';
import { TripDateText, TripPlaceText } from '../index.styled';

import { TripWrapper, TripDate, TripArrival } from './SegmentDetails.styled';
// import SelectTimes from "common/components/ResultDetail/ResultDetailSelectTimes"
// import SelectTimesWithCarousel from "common/components/ResultDetail/ResultDetailSelectTimesWithCarousel"
// import type { AlternativeJourney } from "common/components/ResultCard/utils"
import TripGrid from './TripGrid';
import TripSegment from './TripSegment';
import TripStop from './TripStop';

const SegmentDetails = ({ itinerary, segments, tripIndex }) => {
  const { translate } = useTranslations();

  return (
    <TripWrapper data-test="TripPopupWrapper" isFirst={tripIndex === 0}>
      <Heading type="title3" as="h2">
        <Translate
          tkey={('search.result.itinerary_detail.to_where.header')}
          values={{ place: segments[segments.length - 1].destination.station.city.name }}
        />
      </Heading>
      <section>
        <TripGrid element="header">
          <div />
          <TripDate>
            <Calendar size="small" />
            <TripDateText>
              <Timestamp
                formatKeys="dateFormat"
                value={new Date(segments[0].source.localTime)}
                isLocal
              />
            </TripDateText>
          </TripDate>
        </TripGrid>
        {/* {alternativeJourneys &&
          alternativeJourneys.length > 1 &&
          setSelectedSectorIds &&
          (groupsVersion === 1 ? (
            <React.Fragment key="SelectTimes">
              <SelectTimes
                selectedJourney={journey}
                alternativeJourneys={alternativeJourneys}
                tripIndex={tripIndex}
                setSelectedSectorIds={setSelectedSectorIds}
                defaultExpanded={Boolean(expandAlternativeJourneys)}
              />
              <TripGrid>
                <div />
                <TripSelectTimesCell />
              </TripGrid>
            </React.Fragment>
          ) : (
            <SelectTimesWithCarousel
              selectedJourney={journey}
              alternativeJourneys={alternativeJourneys}
              tripIndex={tripIndex}
              setSelectedSectorIds={setSelectedSectorIds}
            />
          ))} */}
        {/* {differentDepartureKey && (
          <TripGrid>
            <TripAlert />
            <TripAlert>
              <Alert color="warning" size="small" />
              <Text size="small" element="span" type="warning">
                <Translate t={differentDepartureKey} />
              </Text>
            </TripAlert>
          </TripGrid>
        )} */}
        {segments.map((segment, index) => {
          const stop = index > 0; // && TripUtils.getPrevStop(part, accommodationParams, trip);
          return (
            <React.Fragment key={index}>
              {stop && <TripStop stop={segment} itinerary={itinerary} />}
              <TripSegment
                segment={segment}
                // departureIataCode={
                //   iataGroundTansfer ? TripUtils.getForcedIataCode(trip, index) : null
                // }
                // arrivalIataCode={
                //   iataGroundTansfer ? TripUtils.getForcedIataCode(trip, index + 1) : null
                // }
                // departureNextDay={
                //   index > 0 &&
                //   !isSameDay(part.departure.when.local, trip.parts[index - 1].arrival.when.local)
                // }
              />
            </React.Fragment>
          );
        })}
        {/* {differentArrivalKey && (
          <TripGrid>
            <TripAlert />
            <TripAlert>
              <Alert color="warning" size="small" />
              <Text size="small" element="span" type="warning">
                <Translate t={differentArrivalKey} />
              </Text>
            </TripAlert>
          </TripGrid>
        )} */}
        <TripGrid element="footer">
          <TripArrival />
          <TripArrival>
            <Stack align="center" spacing="XSmall">
              <Location size="small" customColor="#7f91a8" />
              <Stack direction="column" spacing="XXSmall">
                <Text type="secondary" size="small" element="span">
                  {translate(('search.result.itinerary_detail.arrive_at_destination'))}
                </Text>
                <TripPlaceText>
                  {segments[segments.length - 1].destination.station.city.name}
                </TripPlaceText>
              </Stack>
            </Stack>
          </TripArrival>
          {/* <TripNights trip={trip} journey={journey} accommodationParams={accommodationParams} /> */}
        </TripGrid>
      </section>
    </TripWrapper>
  );
};

export default SegmentDetails;
