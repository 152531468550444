/* global __ */
import { PLACE_TYPES, PLACE_VALUE_MODES, PLACE_MODE_KEYS } from '../consts/place';

const typenameToPlaceTypes = {
  City: PLACE_TYPES.CITY,
  Subdivision: PLACE_TYPES.COUNTRY, // subdivision is most similar to country TODO on native use of locations use normal subdivisions
  AutonomousTerritory: PLACE_TYPES.AUTONOMOUS_TERRITORY,
  Country: PLACE_TYPES.COUNTRY,
  Region: PLACE_TYPES.REGION,
  Continent: PLACE_TYPES.CONTINENT,
  POI: PLACE_TYPES.SPECIAL,
  TouristRegion: PLACE_TYPES.TOURIST_REGION,
};

const getStationType = place => {
  const { type } = place;
  switch (type) {
    case 'AIRPORT':
    case 'BUS_STATION':
    case 'TRAIN_STATION':
      return PLACE_TYPES[type];
    default:
      return null;
  }
};

const getPlaceType = place => {
  switch (place.__typename) {
    case 'Station':
      return getStationType(place);
    default:
      return typenameToPlaceTypes[place.__typename] || null;
  }
};

const getSuffix = place => {
  switch (getPlaceType(place)) {
    case PLACE_TYPES.AUTONOMOUS_TERRITORY:
    case PLACE_TYPES.CITY:
      return place.country?.name ? ` (${place.country?.name || ''})` : '';
    case PLACE_TYPES.AIRPORT:
      return place.legacyId ? ` (${place.legacyId})` : '';
    default:
      return '';
  }
};

const getValue = place => {
  const { name } = place;
  if (!name) {
    return '';
  }

  const suffix = getSuffix(place);
  switch (getPlaceType(place)) {
    case PLACE_TYPES.AUTONOMOUS_TERRITORY:
    case PLACE_TYPES.CITY:
    case PLACE_TYPES.AIRPORT:
      return (
        name +
        suffix +
        (suffix && /[a-zA-Z]+/.test(name) // latin strings have problem with closing parentheses in rtl languages, so we have to add ltr marker
          ? '\u200e'
          : '')
      );
    default:
      return name;
  }
};

const getParents = place => {
  switch (place.__typename) {
    case 'Continent':
      return [place];
    case 'Region':
      return [place, place.continent];
    case 'Country':
      return [place, place.region, place.region?.continent];
    case 'AutonomousTerritory':
      return [place, place.country, place.country?.region, place.country?.region?.continent];
    case 'Subdivision':
      return [place, place.country, place.country?.region, place.country?.region?.continent];
    case 'City':
      return [
        place,
        place.subdivision,
        place.autonomousTerritory,
        place.country,
        place.country?.region,
        place.country?.region?.continent,
      ];
    case 'Station':
      return [
        place,
        place.city,
        place.city?.subdivision,
        place.city?.autonomousTerritory,
        place.city?.country,
        place.city?.country?.region,
        place.city?.country?.region?.continent,
      ];
    default:
      return [];
  }
};

const getParentIds = place =>
  getParents(place)
    .map(parent => parent?.legacyId)
    .filter(Boolean);

const getCity = city =>
  city && city.legacyId && city.name ? { id: city.legacyId, name: city.name } : null;

const getCountry = country => (country ? { id: country.legacyId, name: country.name } : null);

const getSubdivision = subdivision =>
  subdivision ? { id: subdivision.legacyId, name: subdivision.name } : null;

const isCityOrAirportOrSpecial = location => {
  return (
    location.type === PLACE_TYPES.TRAIN_STATION ||
    location.type === PLACE_TYPES.AIRPORT ||
    location.type === PLACE_TYPES.BUS_STATION ||
    location.type === PLACE_TYPES.CITY ||
    location.type === PLACE_TYPES.SPECIAL
  );
};

export const isLocationLarge = locations => {
  let isLarge = true;
  for (const location of locations) {
    isLarge = isLarge && !isCityOrAirportOrSpecial(location);
  }

  return isLarge;
};

export const mapPlaceEdgeToPlace = edge => {
  if (!edge) {
    return null;
  }
  const { node: place, rank, distance, sphericalDistance, carDirections, isAmbiguous } = edge;
  if (!place) {
    return null;
  }
  const type = getPlaceType(place);
  const { name } = place;
  const id = place.legacyId;
  const { slug } = place;
  if (type === undefined || type === null || !name || !id || !slug) {
    return null;
  }
  return {
    name,
    type,
    id,
    slug,
    value: getValue(place),
    country: getCountry(place.country || place.city?.country),
    subdivision: getSubdivision(place.subdivision),
    city: getCity(place.city),
    rank: typeof rank === 'number' ? rank : 1024,
    code: place.code || place.legacyId || '',
    distance: sphericalDistance || carDirections || distance,
    parentIds: getParentIds(place),
    isAmbiguous,
    neighbours: [],
    tags: [],
    lat: null,
    lng: null,
    continentId: null,
    locationsNearby: null,
  };
};

export const getShortName = place => {
  switch (place.type) {
    case PLACE_TYPES.CITY:
      return place.value.replace(/\s*\(.+\)/, '');
    case PLACE_TYPES.AIRPORT:
      return `${place.city ? place.city.name : ''} ${place.value
        .replace(/.+\s/, '')
        .replace(/\(/, '')
        .replace(/\)/, '')}`;
    case PLACE_TYPES.BUS_STATION:
    case PLACE_TYPES.TRAIN_STATION:
      return place.name;
    case PLACE_TYPES.RADIUS: // should never happen, refactor
      return place.closeCity ? getShortName(place.closeCity) : '';
    default:
      return place.value;
  }
};

// const getCityListText = (radius: Radius, length?: number, searchedText?: string): string => {
//   const placesInRadius = RadiusUtils.getPlacesInRadius(this.cities, radius).map(getShortName);

//   const [including, rest] = partition(
//     name => (searchedText ? name.toLowerCase().includes(searchedText.toLowerCase()) : false),
//     placesInRadius,
//   );
//   const text = including.concat(rest).join(', ') || this.getShortText(radius);
//   return length && text.length > length + 2 ? `${text.substr(0, length)}...` : text;
// };

export const getDisplayName = (place, context) => {
  if (place.places) {
    return '';
  }
  if (place.mode) {
    switch (place.mode) {
      case PLACE_VALUE_MODES.RADIUS:
        if (place.value.name) {
          return place.value.name;
        }
        if (place.value.radius && context) {
          // return getCityListText(
          //   place.value,
          //   context && context.radiusListLength,
          //   context && context.searchedText,
          // );
          return 'radius??';
        }
        return '';
      case PLACE_VALUE_MODES.ANYWHERE:
        // translate?
        return PLACE_MODE_KEYS[PLACE_VALUE_MODES.ANYWHERE];
      case PLACE_VALUE_MODES.AS_PICK_UP:
        // translate?
        return PLACE_MODE_KEYS[PLACE_VALUE_MODES.AS_PICK_UP];
      case PLACE_VALUE_MODES.PLACE:
        if (place.value.id) {
          return getDisplayName(place.value, context);
        }
        return '';
      default:
        return place.value;
    }
  }
  if (place.id) {
    switch (place.type) {
      case PLACE_TYPES.AIRPORT:
        return !(context && context.pickerRowLevel)
          ? `${place.code} ${(place.city && place.city.name) || ''}, ${
              place.country?.name || ''
            } - ${place.name || ''}`
          : `${place.code} ${place.name || ''}`;
      case PLACE_TYPES.BUS_STATION:
      case PLACE_TYPES.TRAIN_STATION:
        return place.name || '';
      case PLACE_TYPES.CITY:
        return `${getShortName(place)}, ${place.country?.name || ''}`;
      case PLACE_TYPES.COUNTRY: {
        // subdivisions are stored as countries, but with country name filled
        const countryName = place.country?.name;
        return `${getShortName(place)}${countryName ? `, ${countryName}` : ''}`;
      }
      default:
        return getShortName(place);
    }
  }
  return '';
};

const defaultPlace = {
  name: '',
  type: 0,
  id: '',
  slug: '',
  value: '',
  country: null,
  subdivision: null,
  city: null,
  rank: 1024,
  code: '',
  distance: null,
  parentIds: [],
  isAmbiguous: null,
  neighbours: [],
  tags: [],
  lat: 0,
  lng: 0,
  continentId: null,
  locationsNearby: null,
};

const createPlace = input => ({ ...defaultPlace, ...input });

const getVirtualPlaces = translate => [
  {
    value: createPlace({
      id: 'anywhere',
      value: translate(('form_search.anywhere')),
      slug: 'anywhere',
      code: 'anywhere',
      type: PLACE_TYPES.VIRTUAL,
      parentIds: ['anywhere'],
    }),
    showOnActiveField: ['destination'],
  },
];

export const injectVirtualPlaces = (results, search, activeField, translate) => {
  return getVirtualPlaces(translate)
    .filter(
      virtual =>
        virtual.showOnActiveField.includes(activeField) &&
        virtual.value.value.toLowerCase().startsWith(search ? search.toLowerCase() : ''),
    )
    .map(t => t.value)
    .concat(results);
};

// returns ty:{type}_id:{id}_ci:{city}_cn:{country}_rg:{region}_co:{continent}
export const getGTMPlace = place => {
  try {
    switch (place.type) {
      case PLACE_TYPES.AIRPORT:
        return `ty:airport_id:${place.id}_ci:${place.city.id}_cn:${place.country.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      case PLACE_TYPES.COUNTRY:
        return `ty:country_id:${place.id}_ci:_cn:${place.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      case PLACE_TYPES.CITY:
        return `ty:city_id:${place.id}_ci:${place.id}_cn:${place.country.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      case PLACE_TYPES.TRAIN_STATION:
        return `ty:trainStation_id:${place.id}_ci:${place.city.id}_cn:${place.country.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      case PLACE_TYPES.AUTONOMOUS_TERRITORY:
        return `ty:autonomousTerritory_id:${place.id}_ci:_cn:${place.country.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      case PLACE_TYPES.REGION:
        return `ty:region_id:${place.id}_ci:_cn:_rg:_co:`;
      case PLACE_TYPES.CONTINENT:
        return `ty:continent_id:${place.id}_ci:_cn:_rg:_co:${place.id}`;
      case PLACE_TYPES.BUS_STATION:
        return `ty:busStation_id:${place.id}_ci:${place.city.id}_cn:${place.country.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      case PLACE_TYPES.SPECIAL:
        return `ty:special_id:${place.id}_ci:_cn:_rg:_co:`;
      case PLACE_TYPES.TOURIST_REGION:
        return `ty:touristRegion_id:${place.id}_ci:_cn:_rg:_co:`;
      case PLACE_TYPES.VIRTUAL:
        return `ty:virtual_id:${place.id}_ci:_cn:_rg:_co:`;
      case PLACE_TYPES.RADIUS:
        return `ty:radius_id:${place.id}_ci:${place.city.id}_cn:${place.country.id}_rg:${
          place.parentIds[place.parentIds.length - 2]
        }_co:${place.parentIds[place.parentIds.length - 1]}`;
      default:
        return `other:${place.id}`;
    }
  } catch (error) {
    return undefined;
  }
};

export const getLogladyPlace = (place, direction) => {
  switch (place.type) {
    case PLACE_TYPES.TRAIN_STATION:
    case PLACE_TYPES.BUS_STATION:
    case PLACE_TYPES.AIRPORT:
      return {
        [`${direction}Station`]: place.id,
        [`${direction}City`]: place.city.name,
        [`${direction}Country`]: place.country.name,
        [`${direction}Region`]: place.parentIds[place.parentIds.length - 2],
        [`${direction}Continent`]: place.parentIds[place.parentIds.length - 1],
        [`${direction}SpecialPlace`]: null,
      };
    case PLACE_TYPES.CITY:
      return {
        [`${direction}Station`]: null,
        [`${direction}City`]: place.name,
        [`${direction}Country`]: place.country.name,
        [`${direction}Region`]: place.parentIds[place.parentIds.length - 2],
        [`${direction}Continent`]: place.parentIds[place.parentIds.length - 1],
        [`${direction}SpecialPlace`]: null,
      };
    case PLACE_TYPES.COUNTRY:
      return {
        [`${direction}Station`]: null,
        [`${direction}City`]: null,
        [`${direction}Country`]: place.name,
        [`${direction}Region`]: place.parentIds[place.parentIds.length - 2],
        [`${direction}Continent`]: place.parentIds[place.parentIds.length - 1],
        [`${direction}SpecialPlace`]: null,
      };
    case PLACE_TYPES.AUTONOMOUS_TERRITORY:
      return {
        [`${direction}Station`]: null,
        [`${direction}City`]: null,
        [`${direction}Country`]: place.value,
        [`${direction}Region`]: place.parentIds[place.parentIds.length - 2],
        [`${direction}Continent`]: place.parentIds[place.parentIds.length - 1],
        [`${direction}SpecialPlace`]: null,
      };
    case PLACE_TYPES.SPECIAL:
      return {
        [`${direction}Station`]: null,
        [`${direction}City`]: null,
        [`${direction}Country`]: null,
        [`${direction}Region`]: null,
        [`${direction}Continent`]: null,
        [`${direction}SpecialPlace`]: place.name,
      };
    default:
      return {
        [`${direction}Station`]: null,
        [`${direction}City`]: null,
        [`${direction}Country`]: null,
        [`${direction}Region`]: null,
        [`${direction}Continent`]: null,
        [`${direction}SpecialPlace`]: null,
      };
  }
};
