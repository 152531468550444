import React from 'react';
import styled, { css } from 'styled-components';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { left } from '@kiwicom/orbit-components/lib/utils/rtl';
import format from 'date-fns/format';
import getDate from 'date-fns/getDate';
import isSameMonth from 'date-fns/isSameMonth';

import  useTranslations  from '../../../services/translations/useTranslations';
import * as utils from '../../../utils/datetime';
import { chunk } from '../../../utils/array';

import * as dayHelpers from '../services/utils/dayHelpers';

import CalendarDay from './CalendarDay';

const Container = styled.div`
  min-width: 100%;

  &:nth-child(2n) {
    margin-${left}: 10px;
  }

  ${media.desktop(css`
    min-width: auto;
  `)}
`;

const Month = styled.div`
  display: table;
  border-collapse: collapse;
  width: 100%;
`;

const Week = styled.div`
  display: table-row;
`;

const DayNameList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 25px 0 10px;
`;

const DayName = styled.span`
  width: 46px;
  font-size: 14px;
  color: ${({ theme }) => theme.orbit.paletteInkLight};
  text-align: center;
`;

const MonthNameContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    background: ${({ theme }) => theme.orbit.paletteCloudNormal};
  }
`;

const CalendarMonth = ({
  month,
  selectedDates,
  disabledDates,
  allowedDates,
  selectDate,
  selectMonth,
  onHover,
  hoveredDates,
  past,
  future,
  isFocused,
  firstWeekDay,
}) => {
  const { locale } = useTranslations();

  const toRender = utils.calendarDaysToRender(firstWeekDay, month);
  const weekDaysToRender = utils.calendarDayNames(toRender);
  const calendarDaysToRender = chunk(toRender, 7);

  return (
    <Container>
      <MonthNameContainer>
        <ButtonLink
          dataTest="CalendarMonth"
          size="small"
          type="secondary"
          title="month name"
          onClick={() => selectMonth(month)}
        >
          {format(month, 'LLLL yyyy', { locale })}
        </ButtonLink>
      </MonthNameContainer>

      <DayNameList>
        {weekDaysToRender.map(day => (
          <DayName key={day}>{format(day, 'EEEEEE', { locale })}</DayName>
        ))}
      </DayNameList>

      <Month>
        {calendarDaysToRender.map(week => (
          <Week key={week}>
            {week.map(day => (
              <CalendarDay
                key={day}
                isHidden={!isSameMonth(month, day)}
                number={getDate(day)}
                value={day}
                isSelecting={!!hoveredDates.length}
                // price={Math.round(100 - Math.random() * 100)}
                selectDate={selectDate}
                onHover={onHover}
                isFirst={dayHelpers.isFirst(day, selectedDates, hoveredDates)}
                isLast={dayHelpers.isLast(day, selectedDates)}
                isHovered={dayHelpers.isHovered(day, hoveredDates)}
                isSelected={dayHelpers.isSelected(day, selectedDates)}
                isDisabled={
                  dayHelpers.isDisabled(day, disabledDates) ||
                  (allowedDates.length && !dayHelpers.isDisabled(day, allowedDates))
                }
                isPast={dayHelpers.isPast(day, new Date()) && !past}
                isFuture={dayHelpers.isFuture(day, new Date()) && !future}
                isFocused={isFocused}
              />
            ))}
          </Week>
        ))}
      </Month>
    </Container>
  );
};

export default CalendarMonth;
