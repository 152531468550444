// const formatThousands = (value, separator) =>
//   R.pipe(R.reverse, R.splitEvery(3), R.join(separator), R.reverse)(value);

import { chunk } from './array';

const formatThousands = (value, separator) => {
  const reversed = `${value}`.split('').reverse();
  const chunked = chunk(reversed).join(separator);

  return chunked.split('').reverse();
};

const decimalPlaces = num => {
  const [, right] = num.split('.');
  return right ? right.length : 0;
};

export function number(langInfo, value) {
  const { decimalSeparator } = langInfo;
  const thousandsSeparator =
    langInfo.thousandsSeparator === 'nbsp' ? ' ' : langInfo.thousandsSeparator;
  const separateFourDigits = langInfo.separateFourDigits === '1';
  const numOfDecimalPlaces = decimalPlaces(value.toString());
  const valueStr = Math.abs(value).toFixed(numOfDecimalPlaces);
  const [full, decimal] = valueStr.split('.');
  let formattedFull = value < 0 ? '-' : '';

  if (full.length === 4 && !separateFourDigits) {
    formattedFull += full;
  } else {
    formattedFull += formatThousands(full, thousandsSeparator);
  }

  return decimal ? formattedFull + decimalSeparator + decimal : formattedFull;
}
