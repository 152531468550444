import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import Collapse from '@kiwicom/orbit-components/lib/Collapse';
import Button from '@kiwicom/orbit-components/lib/Button';
import ChevronUpIcon from '@kiwicom/orbit-components/lib/icons/ChevronUp';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

import useViewport from '../../../../../../scenes/Basic/services/Viewport/useViewport';
import { usePostMessages } from '../../../../../../services/postMessages';

const ButtonWrapper = styled.div`
  margin-left: 4px;

  ${media.desktop(css`
    margin-left: 0;
  `)}
`;

const FilterWrapper = ({
  open,
  onOpen,
  onClose,
  label,
  width,
  asButton,
  initialExpanded,
  children,
  actions,
  disabled,
  noPadding,
}) => {
  const contentHeightRef = useRef(false); // using ref because we need that value on unmount
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const { isMobile } = useViewport();

  useEffect(() => {
    return () => {
      if (isMobile) {
        unlockResizing();
        if (contentHeightRef.current) {
          postIframeHeight(contentHeightRef.current, true);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePopoverOpen = () => {
    if (isMobile) {
      contentHeightRef.current = window.innerHeight;
      postIframeHeight(600);
      lockResizing();
    }
    onOpen();
  };
  const handlePopoverClose = () => {
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeightRef.current, true);
    }
    onClose();
  };
  return (
    <ButtonWrapper>
      {asButton ? (
        <Popover
          opened={open}
          onOpen={handlePopoverOpen}
          onClose={handlePopoverClose}
          content={children}
          noPadding={noPadding}
          actions={actions}
          width={width}
        >
          <Button
            type={open ? 'info' : 'secondary'}
            size="small"
            disabled={disabled}
            iconRight={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            {label}
          </Button>
        </Popover>
      ) : (
        <Collapse
          dataTest={`HashtagHeader-${label.toLowerCase()}`}
          expanded={open}
          onClick={() => {
            if (open) {
              onClose();
            } else {
              onOpen();
            }
          }}
          label={label}
          initialExpanded={initialExpanded}
          actions={actions}
        >
          {children}
        </Collapse>
      )}
    </ButtonWrapper>
  );
};

export default FilterWrapper;
