import React from 'react';
import styled from 'styled-components';

// import * as TripUtils from "common/records/Trip/utils"
import { dots, TripFlightCell } from '../index.styled';

import TripGrid from './TripGrid';
import ItineraryTripLayover from './ItineraryTripLayover';

const TripFlightLayover = styled(TripFlightCell)`
  &:before {
    ${dots};
  }

  svg {
    background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  }
`;

const TripStop = ({ stop, itinerary }) => (
  <>
    <TripGrid>
      <div />
      <TripFlightLayover>
        <ItineraryTripLayover stop={stop} itinerary={itinerary} />
      </TripFlightLayover>
    </TripGrid>
    {/* {showShortTransferInfo && TripUtils.isNearStationChangeStop(stop) && <ShortTransferInfo />} */}
  </>
);

export default TripStop;
