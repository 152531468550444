import styled, { css } from 'styled-components';
import { left } from '@kiwicom/orbit-components/lib/utils/rtl';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ spaceBefore }) => spaceBefore && 'margin-top: 15px'};

  ${media.desktop(css`
    flex-direction: row;
    margin-top: 16px;
  `)}
`;
export const FiltersWrapper = styled.div`
  display: flex;
  padding: 16px 0;

  ${media.desktop(css`
    display: block;
    margin: 50px 0 0;
    padding: 0;
    width: 268px;
  `)}
`;

export const ScrollWrapper = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
`;

export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-${left}: 0;

  ${media.desktop(css`
    padding-${left}: 24px;
  `)}
`;

export const SortWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 16px;
`;

export const Results = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TextLinkWrapper = styled.div`
  margin-bottom: 10px;
`;
