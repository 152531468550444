import React from 'react';

import {
  StyledPictureCardSkeleton,
  PictureCardSkeletonContent,
  StyledPictureCardsPlaceholders,
  PictureCardPlaceholderWrapper,
  RowWrapper,
  TileWrapper,
} from './index.styled';

const PictureCardSkeleton = ({ layout }) => (
  <StyledPictureCardsPlaceholders data-test="PictureCards">
    {layout.map((row, index) => {
      return (
        <RowWrapper key={index}>
          {row.map((tile, i) => (
            <TileWrapper key={i} width={tile.width}>
              <PictureCardPlaceholderWrapper key={i}>
                <StyledPictureCardSkeleton>
                  <PictureCardSkeletonContent style={{ width: '80px', height: '10%' }} />
                  <PictureCardSkeletonContent style={{ width: '50%', height: '20%' }} />
                </StyledPictureCardSkeleton>
              </PictureCardPlaceholderWrapper>
            </TileWrapper>
          ))}
        </RowWrapper>
      );
    })}
  </StyledPictureCardsPlaceholders>
);

export default PictureCardSkeleton;
