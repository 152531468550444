import React, { useState, useRef } from 'react';

import { useGTM } from '../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../consts/tracking';

export const PlacesState = React.createContext({});

const PlacesProvider = ({ max, onChange, values, direction, children }) => {
  const [input, setInput] = useState('');
  const [active, setActive] = useState(false);
  const placePickerRef = useRef(null);
  const { pushToDataLayer } = useGTM();

  const addPlace = place => {
    if (max !== undefined && values.length + 1 >= max) {
      onChange(values.slice(1).concat(place));
      pushToDataLayer(GTM_EVENTS.SEARCH_BUTTON, { [direction]: values.slice(1).concat(place) });
    } else {
      onChange(values.concat(place));
      pushToDataLayer(GTM_EVENTS.SEARCH_BUTTON, { [direction]: values.concat(place) });
    }
  };

  const addPlaces = places => {
    if (max !== undefined && places.length + 1 >= max) {
      return;
    } else {
      onChange(places);
    }
  }

  const removePlace = place => {
    const places = values.filter(p => p.code !== place.code);
    pushToDataLayer(GTM_EVENTS.SEARCH_BUTTON, { [direction]: places });
    onChange(places);
  };

  const isPlaceSelected = place => {
    return values.some(p => p.id === place.id);
  };

  const value = {
    input,
    setInput,
    active,
    setActive,
    placePickerRef,
    addPlace,
    removePlace,
    isPlaceSelected,
    addPlaces,
  };

  return <PlacesState.Provider value={value}>{children}</PlacesState.Provider>;
};

export default PlacesProvider;
