/* global __ */

export const TRANSLATE_KEYS = {
  activities: ('search.route_cards.location_hashtags.activities'),
  adventure: ('search.route_cards.location_hashtags.adventure'),
  beach: ('search.route_cards.location_hashtags.beach'),
  diving_and_snorkeling: ('search.route_cards.location_hashtags.diving_and_snorkeling'),
  events: ('search.route_cards.location_hashtags.events'),
  family_fun: ('search.route_cards.location_hashtags.family_fun'),
  food_and_drink: ('search.route_cards.location_hashtags.food_and_drink'),
  music: ('search.route_cards.location_hashtags.music'),
  nature: ('search.route_cards.location_hashtags.nature'),
  nightlife: ('search.route_cards.location_hashtags.nightlife'),
  romance: ('search.route_cards.location_hashtags.romance'),
  short_break: ('search.route_cards.location_hashtags.short_break'),
  sightseeing_and_culture: ('search.route_cards.location_hashtags.sightseeing_and_culture'),
  sports: ('search.route_cards.location_hashtags.sports'),
  surfing: ('search.route_cards.location_hashtags.surfing'),
  unusual: ('search.route_cards.location_hashtags.unusual'),
  winter_sports: ('search.route_cards.location_hashtags.winter_sports'),
  skiing: ('search.route_cards.location_hashtags.skiing'),
};

export const getHashtagTranslation = label => {
  if (!label) {
    return '';
  }

  return TRANSLATE_KEYS[label.replace('&', '_and_')?.replace(' ', '_')];
};
