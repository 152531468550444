import React from 'react';

import {
  TripSegmentDetailsItemWrapper,
  TripSegmentDetailsItemText,
  TripSegmentDetailsItemValue,
} from './TripSegmentDetailsItem.styled';

const TripSegmentDetailsItem = ({ icon, text, value, dataTest, train }) => (
  <TripSegmentDetailsItemWrapper data-test={dataTest} train={train}>
    {icon}
    <TripSegmentDetailsItemText>{text}</TripSegmentDetailsItemText>
    {value && <TripSegmentDetailsItemValue>{value}</TripSegmentDetailsItemValue>}
  </TripSegmentDetailsItemWrapper>
);

TripSegmentDetailsItem.defaultProps = {
  dataTest: '',
};

export default TripSegmentDetailsItem;
