import { retry } from '../utils/functions';

const imports = {
  au: () => retry(() => import(/* webpackChunkName: 'locale-au' */ 'date-fns/locale/en-AU')),
  ca: () => retry(() => import(/* webpackChunkName: 'locale-ca' */ 'date-fns/locale/en-CA')),
  gb: () => retry(() => import(/* webpackChunkName: 'locale-gb' */ 'date-fns/locale/en-GB')),
  us: () => retry(() => import(/* webpackChunkName: 'locale-us' */ 'date-fns/locale/en-US')),
  ka: () => retry(() => import(/* webpackChunkName: 'locale-us' */ 'date-fns/locale/ka')),
  ct: () => retry(() => import(/* webpackChunkName: 'locale-ct' */ 'date-fns/locale/ca')),
  ru: () => retry(() => import(/* webpackChunkName: 'locale-ru' */ 'date-fns/locale/ru')),
  bg: () => retry(() => import(/* webpackChunkName: 'locale-bg' */ 'date-fns/locale/bg')),
  cn: () => retry(() => import(/* webpackChunkName: 'locale-cn' */ 'date-fns/locale/zh-CN')),
  tw: () => retry(() => import(/* webpackChunkName: 'locale-tw' */ 'date-fns/locale/zh-TW')),
  de: () => retry(() => import(/* webpackChunkName: 'locale-de' */ 'date-fns/locale/de')),
  ja: () => retry(() => import(/* webpackChunkName: 'locale-ja' */ 'date-fns/locale/ja')),
  es: () => retry(() => import(/* webpackChunkName: 'locale-es' */ 'date-fns/locale/es')),
  nl: () => retry(() => import(/* webpackChunkName: 'locale-nl' */ 'date-fns/locale/nl')),
  no: () => retry(() => import(/* webpackChunkName: 'locale-no' */ 'date-fns/locale/nb')),
  id: () => retry(() => import(/* webpackChunkName: 'locale-id' */ 'date-fns/locale/id')),
  it: () => retry(() => import(/* webpackChunkName: 'locale-it' */ 'date-fns/locale/it')),
  pl: () => retry(() => import(/* webpackChunkName: 'locale-pl' */ 'date-fns/locale/pl')),
  pt: () => retry(() => import(/* webpackChunkName: 'locale-pt' */ 'date-fns/locale/pt')),
  sv: () => retry(() => import(/* webpackChunkName: 'locale-sv' */ 'date-fns/locale/sv')),
  fr: () => retry(() => import(/* webpackChunkName: 'locale-fr' */ 'date-fns/locale/fr')),
  tr: () => retry(() => import(/* webpackChunkName: 'locale-tr' */ 'date-fns/locale/tr')),
  ko: () => retry(() => import(/* webpackChunkName: 'locale-ko' */ 'date-fns/locale/ko')),
  el: () => retry(() => import(/* webpackChunkName: 'locale-el' */ 'date-fns/locale/el')),
  sk: () => retry(() => import(/* webpackChunkName: 'locale-sk' */ 'date-fns/locale/sk')),
  da: () => retry(() => import(/* webpackChunkName: 'locale-da' */ 'date-fns/locale/da')),
  is: () => retry(() => import(/* webpackChunkName: 'locale-is' */ 'date-fns/locale/is')),
  fi: () => retry(() => import(/* webpackChunkName: 'locale-fi' */ 'date-fns/locale/fi')),
  th: () => retry(() => import(/* webpackChunkName: 'locale-th' */ 'date-fns/locale/th')),
  hr: () => retry(() => import(/* webpackChunkName: 'locale-hr' */ 'date-fns/locale/hr')),
  ar: () => retry(() => import(/* webpackChunkName: 'locale-ar' */ 'date-fns/locale/ar-SA')),
  cz: () => retry(() => import(/* webpackChunkName: 'locale-cz' */ 'date-fns/locale/cs')),
  ro: () => retry(() => import(/* webpackChunkName: 'locale-ro' */ 'date-fns/locale/ro')),
  hu: () => retry(() => import(/* webpackChunkName: 'locale-hu' */ 'date-fns/locale/hu')),
  lt: () => retry(() => import(/* webpackChunkName: 'locale-lt' */ 'date-fns/locale/lt')),
  il: () => retry(() => import(/* webpackChunkName: 'locale-il' */ 'date-fns/locale/he')),
  vn: () => retry(() => import(/* webpackChunkName: 'locale-vn' */ 'date-fns/locale/vi')),
  uk: () => retry(() => import(/* webpackChunkName: 'locale-uk' */ 'date-fns/locale/uk')),
  sr: () => retry(() => import(/* webpackChunkName: 'locale-sr' */ 'date-fns/locale/sr-Latn')),
};

export const locales = {
  ka: imports.ka,
  bg: imports.bg,
  ct: imports.ct,
  cz: imports.cz,
  da: imports.da,
  de: imports.de,
  at: imports.de,
  ch: imports.de,
  el: imports.el,
  en: imports.gb,
  au: imports.au,
  ca: imports.ca,
  hk: imports.us,
  in: imports.us,
  ie: imports.gb,
  my: imports.us,
  nz: imports.au,
  sg: imports.us,
  za: imports.us,
  ph: imports.us,
  us: imports.us,
  es: imports.es,
  ag: imports.es,
  cl: imports.es,
  co: imports.es,
  ec: imports.es,
  mx: imports.es,
  pe: imports.es,
  fr: imports.fr,
  be: imports.fr,
  'ca-fr': imports.fr,
  hr: imports.hr,
  id: imports.id,
  is: imports.is,
  it: imports.it,
  ja: imports.ja,
  lt: imports.lt,
  il: imports.il,
  hu: imports.hu,
  nl: imports.nl,
  no: imports.no,
  pl: imports.pl,
  pt: imports.pt,
  br: imports.pt,
  ro: imports.ro,
  ru: imports.ru,
  by: imports.ru,
  kz: imports.ru,
  sk: imports.sk,
  fi: imports.fi,
  sv: imports.sv,
  th: imports.th,
  tr: imports.tr,
  vn: imports.vn,
  uk: imports.uk,
  ar: imports.ar,
  bh: imports.ar,
  jo: imports.ar,
  kw: imports.ar,
  om: imports.ar,
  qa: imports.ar,
  ae: imports.ar,
  cn: imports.cn,
  ko: imports.ko,
  tw: imports.tw,
  sr: imports.sr,
};

export const supportedLocales = Object.keys(locales);
