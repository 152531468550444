/* global __ */
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal, { ModalSection, ModalHeader, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import Portal from '@kiwicom/orbit-components/lib/Portal';
import useLockScrolling from '@kiwicom/orbit-components/lib/hooks/useLockScrolling';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import { usePostMessages } from '../../services/postMessages';
import  useTranslations  from '../../services/translations/useTranslations';
import BookingButton from '../../common/ResultCard/BookingButton';
import { PriceBeforeDiscountValue, PriceDiscountedValue } from '../../common/ResultCard/index.styled';
import  useCurrency  from '../../services/currencies/useCurrency';
import useCurrencyData from '../../services/currencies/useCurrencyData';

import SegmentDetails from './components/SegmentDetails';
import { FooterWrapper, Footer, FooterBookingWrapper, FooterPriceWrapper } from './index.styled';

const ItineraryPrice = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const ModalzIndex = styled.div`
  position: absolute;
  z-index: 799;
`;

const ResultDetail = ({ itinerary, onClose }) => {
  const modalRef = useRef();
  useLockScrolling(modalRef);
  const { postIframeHeight, postScrollToTop, lockResizing, unlockResizing } = usePostMessages();
  const [contentHeight, setContentHeight] = useState(false);

  const { translate } = useTranslations();
  const { formatPrice } = useCurrency();
  const { activeCurrencyCode } = useCurrencyData();

  useEffect(() => {
    const modalHeight = modalRef.current.modalContent.current.clientHeight;
    setContentHeight(modalRef.current.modalBody.current.clientHeight);
    postIframeHeight(modalHeight + 100); // plus 100 because offset from top
    postScrollToTop();
    lockResizing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef]);
  const handleClose = () => {
    unlockResizing();
    postIframeHeight(contentHeight, true); // return height to original size
    onClose();
  };
  return (
    <Portal>
      <ModalzIndex>
        <Modal onClose={handleClose} fixedFooter size="small" ref={modalRef}>
          <ModalHeader title={translate(('search.result.itinerary_detail.title'))} />
          <ModalSection suppressed>
            {/* {showNoBags && (
              <NoCheckedBag isSticky={noCheckedBagIsSticky}>
                <NoCheckedBagIcon>
                  <BaggageCheckedNone size="small" />
                </NoCheckedBagIcon>
                <Translate t="result.itinerary.no_checked_bag" />
                {isFAQEnabled && (
                  <MoreInfo onClick={openNoBagsArticle}>
                    <Translate t="common.more_info" />
                    <FlightDirect size="small" reverseOnRtl />
                  </MoreInfo>
                )}
              </NoCheckedBag>
            )} */}
            {/* {itinerary.sector.segments.edges.map((segment, index) => ( */}
            {itinerary.trips.map((trip, idx) => {
              return (
                <SegmentDetails
                  // eslint-disable-next-line react/no-array-index-key
                  key={trip.id}
                  itinerary={itinerary}
                  segments={trip.segments}
                  tripIndex={idx}
                  // alternativeJourneys={alternativeJourneys[index]}
                  // setSelectedSectorIds={setSelectedSectorIds}
                  // differentDepartureKey={
                  //   index > 0 && getDifferentTripDepartureKey(trip, journey.trips[index - 1])
                  // }
                  // differentArrivalKey={
                  //   journey.type === 'return' &&
                  //   index === 1 &&
                  //   getDifferentTripArrivalKey(trip, journey.trips[0])
                  // }
                  // accommodationParams={accommodationParams}
                  // groupsVersion={groupsVersion}
                  // expandAlternativeJourneys={
                  //   ALTERNATIVES_OPEN_BY_DEFAULT || expandAlternativeJourneysIndex === index
                  // }
                  // shouldScrollIntoView={expandAlternativeJourneysIndex === index}
                  // iataGroundTansfer={iataGroundTansfer}
                />
              );
            })}
            {/* ))} */}
          </ModalSection>
          {/* {hasMoreProviders && (
            <ModalSection>
              <div ref={bookingOptionsRef}>
                <Heading element="h2" spaceAfter="largest">
                  <Translate t="search.result.booking_options.title" />
                </Heading>
                <BookingOptions
                  journey={journey}
                  groupedProviders={groupedProviders}
                  providersRef={providersRef}
                  getTrackingData={getTrackingData}
                  showLoyaltyPoints={showLoyaltyPoints}
                />
              </div>
            </ModalSection>
          )} */}
          <ModalFooter>
            <FooterWrapper data-test="ModalFooter">
              <Footer>
                <FooterBookingWrapper>
                  <FooterPriceWrapper>
                  {itinerary.price.amount !== itinerary.price.priceBeforeDiscount ? (
                    <>
                      <PriceBeforeDiscountValue>{formatPrice(activeCurrencyCode, parseFloat(itinerary.price.priceBeforeDiscount).toFixed(0))}</PriceBeforeDiscountValue>
                      <FlightDirectIcon medium="large" color='secondary' />
                      <PriceDiscountedValue>{itinerary.price.formattedValue}</PriceDiscountedValue>
                    </>
                  ) : (
                    <ItineraryPrice>{itinerary.price.formattedValue}</ItineraryPrice>
                  )}
                  </FooterPriceWrapper>
                  <BookingButton
                    itinerary={itinerary}
                    size="normal"
                    isJourneyResult
                    dataTest="DetailBookingButton"
                    resultType="detail"
                  />
                </FooterBookingWrapper>
              </Footer>
            </FooterWrapper>
          </ModalFooter>
        </Modal>
      </ModalzIndex>
    </Portal>
  );
};

export default ResultDetail;
