import { useContext } from 'react';

import { PassengersAndBagsState } from './PassengersAndBagsProvider';

const usePaxAndBags = () => {
  const state = useContext(PassengersAndBagsState);

  return state;
};

export default usePaxAndBags;
