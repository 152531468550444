import React, { useState } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isSameDay from 'date-fns/isSameDay';
import isBefore from 'date-fns/isBefore';

import * as utils from '../../../utils/datetime';
import  useTranslations  from '../../../services/translations/useTranslations';
import Tabs from '../../../common/Tabs';

import CalendarMonth from './CalendarMonth';
import NavButton from './NavButton';
import NightsOfStay from './NightsOfStay';
import {} from "ramda";

const CalendarWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
`;

const CalendarMonthWrapper = styled.div`
  display: flex;
`;

const TAB_CALENDAR = 'return';
const TAB_TIME_OF_STAY = 'time-of-stay';

const Calendar = ({
  visibleMonths,
  selectedDates,
  disabledDates,
  past,
  future,
  firstMonth,
  lastMonth,
  rangeSelect,
  selectDates,
  selectMonth,
  firstWeekDay,
  isTimeOfStay,
  nightsOfStay,
  handleNightsChange,
  direction,
}) => {
  const { translate } = useTranslations();

  const [end, setEnd] = useState(null);
  const [hoveredDates, setHoveredDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(
    utils.getCurrentMonthIndex(firstMonth, lastMonth, selectedDates, future, visibleMonths),
  );
  const [isFocused, setIsFocused] = useState(false);
  const [start, setStart] = useState(null);
  const { isDesktop } = useMediaQuery();

  const handleNext = () => {
    setCurrentMonth(currentMonth + 1);
  };
  const handlePrev = () => {
    setCurrentMonth(currentMonth - 1);
  };

  const handleSetRange = date => {
    // when someting is already selected (start)
    // and we clicked on some date (date)
    // then we will set array of dates between
    // date and start - it depends which one is first
    // selected array of dates is sorted ascending
    if (date && start) {
      if (isBefore(date, start)) {
        const range = eachDayOfInterval({ start: date, end: start });
        const rangeWithoutDisabledDates = range.filter(date => !disabledDates.includes(date));
        selectDates(rangeWithoutDisabledDates);
      } else {
        const range = eachDayOfInterval({ start, end: date });
        const rangeWithoutDisabledDates = range.filter(date => !disabledDates.includes(date));
        selectDates(rangeWithoutDisabledDates);
      }
    }
  };

  const handleSelect = date => {
    // when something is already selected
    if (isFocused && rangeSelect) {
      setEnd(date);
      setIsFocused(false);
      setHoveredDates([]);

      handleSetRange(date);
      // when unselecting date
    } else if (isSameDay(date, end) && isSameDay(end, start)) {
      setStart(null);
      setEnd(null);
      setIsFocused(false);

      selectDates([]);
      // initial select case
    } else {
      setStart(date);
      setIsFocused(true);

      selectDates([date]);
    }
  };

  const handleHover = date => {
    if (start && date && isFocused && rangeSelect) {
      if (isBefore(date, start)) {
        setHoveredDates(eachDayOfInterval({ start: date, end: start }));
      } else {
        setHoveredDates(eachDayOfInterval({ start, end: date }));
      }
    }
  };

  const handleChangeActiveTab = activeTabValue => {
    if (activeTabValue === TAB_TIME_OF_STAY) {
      isTimeOfStay.setOn();
    } else {
      isTimeOfStay.setOff();
    }
  };

  const numOfMonths = isDesktop ? visibleMonths : 1;
  const months = utils.getMonths(firstMonth, lastMonth);
  const monthsToRender = utils.calendarMonthsToRender(numOfMonths, currentMonth, months);

  const tabItems = [
    {
      label: translate(('search.form.return_nocolon')),
      value: TAB_CALENDAR,
      component: (
        <>
          {months.length > numOfMonths && (
            <>
              <NavButton
                direction="back"
                onClick={handlePrev}
                disabled={currentMonth === 0 || months.length <= numOfMonths}
              />
              <NavButton
                direction="forth"
                onClick={handleNext}
                disabled={
                  currentMonth === months.length - numOfMonths || months.length <= numOfMonths
                }
              />
            </>
          )}
          <CalendarMonthWrapper>
            {monthsToRender.map(month => (
              <CalendarMonth
                key={month}
                month={month}
                selectedDates={selectedDates}
                disabledDates={disabledDates}
                allowedDates={[]}
                selectDate={handleSelect}
                selectMonth={selectMonth}
                onHover={handleHover}
                hoveredDates={hoveredDates}
                past={past}
                isFocused={isFocused}
                future={future}
                weekDayFormat="EEEEEE"
                firstWeekDay={firstWeekDay}
              />
            ))}
          </CalendarMonthWrapper>
        </>
      ),
    },
    {
      label: translate(('form_search.date_picker.time_of_stay')),
      value: TAB_TIME_OF_STAY,
      component: (
        <NightsOfStay nightsOfStay={nightsOfStay} handleNightsChange={handleNightsChange} />
      ),
    },
  ];

  return (
    <CalendarWrapper>
      {direction === 'inbound' && (
        <Tabs
          items={tabItems}
          transparent
          lineSize="small"
          changeActiveTab={handleChangeActiveTab}
        />
      )}
      {direction !== 'inbound' && tabItems[0].component}
    </CalendarWrapper>
  );
};

Calendar.defaultProps = {
  disabledDates: [],
  allowedDates: [],
  visibleMonths: 1,
  colors: {},
  future: true,
  past: true,
  rangeSelect: true,
  firstWeekDay: 1,
};

export default Calendar;
