import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

import { ResultCardSection } from './index.styled';

export const ResultCardBadgesRow = styled(ResultCardSection)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: ${({ theme, noButton }) =>
    noButton ? theme.orbit.spaceXXSmall : theme.orbit.spaceXSmall};
  ${({ theme, noButton }) => noButton && `padding-top: ${theme.orbit.spaceXSmall}`};
  border: ${({ theme }) => `${theme.orbit.borderStyleCard} ${theme.orbit.borderColorCard}`};
  border-width: ${({ theme }) => `${theme.orbit.borderWidthCard} 0`};

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      border-bottom: 0;
      padding-bottom: ${({ theme }) => theme.orbit.spaceMedium};
    `)};

  /* Workaround: override Orbit Stack’s styles */
  & > div > div,
  & > div > div:last-child {
    margin-bottom: ${({ theme }) => theme.orbit.spaceXXSmall} !important;
    margin-right: 4px;
  }
`;

export const BadgeInnerWrapper = styled.div`
  display: flex;

  p {
    margin-right: 4px;
  }
`;
