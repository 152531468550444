import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

const Placeholder = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.orbit.spaceMedium};
  background: ${({ theme }) => theme.orbit.paletteCloudLight};
  background: linear-gradient(90deg, rgba(245, 247, 249, 1) 0%, rgba(232, 237, 241, 1) 100%);
`;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.orbit.spaceMedium};
  box-shadow: ${({ theme }) => theme.orbit.boxShadowAction};
`;

export const ContentStyled = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.orbit.backgroundCard};

  ${media.largeMobile(css`
    display: flex;
    align-items: stretch;
  `)};
`;

export const ItineraryStyled = styled.div`
  width: 100%;

  ${media.largeMobile(css`
    border-${right}: 1px solid rgba(232, 237, 241, 1);
  `)};
`;

export const TripsWrapperStyled = styled.div`
  padding: ${({ theme }) => theme.orbit.spaceSmall};

  ${media.largeMobile(css`
    padding: ${({ theme }) => theme.orbit.spaceMedium};
  `)};
`;

export const TripStyled = styled.div`
  & + & {
    margin-top: ${({ theme }) => theme.orbit.spaceMedium};

    ${media.largeMobile(css`
      margin-top: ${({ theme }) => theme.orbit.spaceXXLarge};
    `)};
  }
`;

export const DateStyled = styled(Placeholder)`
  width: 162px;
  max-width: 100%;
  height: 20px;
`;

export const RouteStyled = styled(Placeholder)`
  height: 164px;
  margin-bottom: 0;

  ${media.largeMobile(css`
    height: 80px;
  `)};
`;

export const BadgesWrapperStyled = styled.div`
  padding: ${({ theme }) => `${theme.orbit.spaceMedium} ${theme.orbit.spaceSmall}`};
  border-top: 1px solid rgba(232, 237, 241, 1);
  border-bottom: 1px solid rgba(232, 237, 241, 1);

  ${media.largeMobile(css`
    border: 0;
    padding: ${({ theme }) => theme.orbit.spaceMedium};
    padding-top: 0;
  `)};
`;

export const BadgesStyled = styled(Placeholder)`
  width: 162px;
  max-width: 100%;
  height: 35px;
  margin-bottom: 0;
`;

export const ActionsStyled = styled.div`
  padding: ${({ theme }) => theme.orbit.spaceSmall};

  ${media.largeMobile(css`
    width: 35%;
    padding: ${({ theme }) => theme.orbit.spaceMedium};
    display: flex;
    flex-direction: column;
  `)}
`;

export const PriceWrapperStyled = styled.div`
  ${media.largeMobile(css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `)}
`;

export const PriceStyled = styled(Placeholder)`
  height: 32px;
  margin-bottom: ${({ theme }) => theme.orbit.spaceSmall};

  ${media.largeMobile(css`
    width: 122px;
    height: 80px;
    margin-bottom: 0;
  `)}
`;

export const ButtonsStyled = styled(Placeholder)`
  height: 71px;
  margin-bottom: 0;

  ${media.largeMobile(css`
    height: 35px;
  `)}
`;
