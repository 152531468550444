import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';

import PLACES_QUERY from '../../graphql/queries/places.graphql';
import { mapPlaceEdgeToPlace } from '../../utils/place';
import { getLocale } from '../../utils/locale';
import useEntryParameters from '../../services/entryParameters/useEntryParameters';

export const GeoState = React.createContext({});

const GeoProvider = ({ children }) => {
  const [geoData, setValue] = useState({ loading: true, data: null, error: null });
  const { originRadius, lang } = useEntryParameters();
  const [closestCities, setClosestCities] = useState({ loading: true, data: null, error: null });
  const [retry, setRetry] = useState(1);
  const [loadClosestCity] = useLazyQuery(PLACES_QUERY, {
    onCompleted: data => {
      const closestPlaces = data?.places?.edges.map(mapPlaceEdgeToPlace);

      if ((closestPlaces || []).length === 0 && retry <= 10) {
        setRetry(r => r + 1);
        return;
      }

      Sentry.setExtra('Closest Place', {
        length: closestPlaces?.length,
        closestPlace: closestPlaces?.slice(0, 1),
      });

      setClosestCities({
        loading: false,
        data: closestPlaces,
        error: null,
      });
    },
  });

  useEffect(() => {
    if (!geoData.data) {
      return;
    }
    const locale = lang || getLocale(geoData.data?.isoCountryCode);

    loadClosestCity({
      variables: {
        search: {
          radius: {
            position: {
              lat: geoData.data.latitude,
              lng: geoData.data.longitude,
            },
            radius: (originRadius || 50) * retry,
          },
        },
        filter: {
          onlyTypes: ['CITY'],
        },
        options: {
          locale,
          sortBy: 'RANK',
        },
        first: 10,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoData.data, retry]);

  useEffect(() => {
    fetch('https://geoip-api.skypicker.com')
      .then(res => res.json())
      .then(data => {
        Sentry.setExtra('GeoIPResponse', data);

        setValue({ loading: false, data, error: null });
      })
      .catch(error => {
        setValue({ loading: false, data: null, error });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    geoData,
    closestCities,
    closestCity: {
      ...closestCities,
      data: closestCities.data?.[0],
    },
  };

  return <GeoState.Provider value={value}>{children}</GeoState.Provider>;
};

export default GeoProvider;
