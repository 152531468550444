import { gql } from 'graphql.macro';

export default gql`
  query NewPlacesQuery_WidgetCommon(
    $search: PlacesSearchInput
    $filter: PlacesFilterInput
    $options: PlacesOptionsInput
    $first: Int
  ) {
    places(search: $search, filter: $filter, options: $options, first: $first) {
      __typename
      ... on PlaceConnection {
        edges {
          rank
          distance {
            __typename
            distance
          }
          isAmbiguous
          node {
            __typename
            legacyId
            name
            slug
            ... on City {
              autonomousTerritory {
                legacyId
                id
              }
              subdivision {
                legacyId
                name
                id
              }
              country {
                legacyId
                name
                region {
                  legacyId
                  continent {
                    legacyId
                    id
                  }
                  id
                }
                id
              }
            }
            ... on Station {
              type
              code
              city {
                legacyId
                name
                autonomousTerritory {
                  legacyId
                  id
                }
                subdivision {
                  legacyId
                  id
                }
                country {
                  legacyId
                  name
                  region {
                    legacyId
                    continent {
                      legacyId
                      id
                    }
                    id
                  }
                  id
                }
                id
              }
            }
            ... on Region {
              continent {
                legacyId
                id
              }
            }
            ... on Country {
              region {
                legacyId
                continent {
                  legacyId
                  id
                }
                id
              }
            }
            ... on AutonomousTerritory {
              country {
                legacyId
                name
                region {
                  legacyId
                  continent {
                    legacyId
                    id
                  }
                  id
                }
                id
              }
            }
            ... on Subdivision {
              country {
                legacyId
                region {
                  legacyId
                  continent {
                    legacyId
                    id
                  }
                  id
                }
                id
              }
            }
            id
          }
        }
      }
    }
  }
`;
