/* global __ */
import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import SortIcon from '@kiwicom/orbit-components/lib/icons/Sort';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

import { usePostMessages } from '../../../../../../services/postMessages';
import  useTranslations  from '../../../../../../services/translations/useTranslations';

export const VerticalCenter = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-${right}: 8px;
  }
`;

const SortByMobile = ({ sortBy, changeSortBy, options }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sortDraft, setSortDraft] = useState(sortBy);
  const { translate } = useTranslations();
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const [contentHeight, setContentHeight] = useState(false);

  const handleModalClose = () => {
    unlockResizing();
    postIframeHeight(contentHeight, true);
    setModalOpen(false);
    setSortDraft(sortBy);
  };

  const handleSelectDraft = sortParam => {
    setSortDraft(sortParam);
  };

  const handleSubmit = () => {
    postIframeHeight(contentHeight, true);
    unlockResizing();
    changeSortBy(sortDraft);
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setContentHeight(window.innerHeight);
    postIframeHeight(600);
    lockResizing();
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen && (
        <Modal size="small" onClose={handleModalClose}>
          <ModalHeader title={translate(('search.result.sorting.name'))} />
          <ModalSection>
            <div onClick={() => handleSelectDraft('POPULARITY')}>
              <Stack direction="row" justify="between" spaceAfter="medium">
                <Text weight={sortDraft === 'POPULARITY' ? 'bold' : 'normal'}>
                  {translate(options.POPULARITY)}
                </Text>
                <div>
                  <Radio checked={sortDraft === 'POPULARITY'} />
                </div>
              </Stack>
            </div>

            <div onClick={() => handleSelectDraft('PRICE')}>
              <Stack direction="row" justify="between" spaceAfter="medium">
                <Text weight={sortDraft === 'PRICE' ? 'bold' : 'normal'}>
                  {translate(options.PRICE)}
                </Text>
                <div>
                  <Radio checked={sortDraft === 'PRICE'} />
                </div>
              </Stack>
            </div>
          </ModalSection>
          <ModalFooter>
            <Stack direction="row">
              <Button type="secondary" fullWidth onClick={handleModalClose}>
                {translate(('search.form.button.cancel'))}
              </Button>
              <Button fullWidth onClick={handleSubmit}>
                {translate(('search.form.distance.button.done'))}
              </Button>
            </Stack>
          </ModalFooter>
        </Modal>
      )}

      <ButtonLink size="small" type="secondary" iconLeft={<SortIcon />} onClick={handleModalOpen}>
        {translate(options[sortBy])}
      </ButtonLink>
    </>
  );
};

export default SortByMobile;
