import React from 'react';
import { useLazyQuery } from '@apollo/client';

import useSearchFormState  from '../../../../scenes/Basic/services/SearchForm//useSearchForm';

import ONEWAY_PER_CITY_QUERY from './graphql/oneWayPerCity.graphql';
import RETURN_PER_CITY_QUERY from './graphql/returnPerCity.graphql';

export const SearchAggregatedQueryState = React.createContext({});

const SearchQueryProvider = props => {
  const { mode } = useSearchFormState();

  const onewaySearchQuery = useLazyQuery(ONEWAY_PER_CITY_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const returnSearchQuery = useLazyQuery(RETURN_PER_CITY_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  return (
    <SearchAggregatedQueryState.Provider
      value={mode === 'return' ? returnSearchQuery : onewaySearchQuery}
    >
      {props.children}
    </SearchAggregatedQueryState.Provider>
  );
};

export default SearchQueryProvider;
