import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const Modes = styled.div`
  display: flex;
`;

export const ActiveLine = styled.div`
  background-color: ${({ theme }) => theme.orbit.paletteProductNormal};
  width: 0;
  height: 3px;
  margin-top: ${({ theme }) => theme.orbit.spaceXXSmall};
  border-radius: 1.5px;
  transition: width 0.3s;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.orbit.spaceMedium}`};
  transition: background-color 0.3s;
  margin-bottom: -1px;
  cursor: pointer;

  ${({ active, theme, asButton }) =>
    active &&
    `
    background-color: ${asButton ? theme.orbit.paletteWhite : 'transparent'};
    border-radius: ${theme.orbit.borderRadiusNormal} ${theme.orbit.borderRadiusNormal} 0 0;
    box-shadow: ${theme.orbit.boxShadowRaised};
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: ${theme.orbit.borderColorModal};

  `};

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.orbit.paletteWhite : theme.orbit.backgroundButtonSecondaryHover}};
  }

  ${media.desktop(css`
    display: block;
    padding: ${({ theme }) => `0 ${theme.orbit.spaceMedium} 0 0`};
    box-shadow: none;
    border: none;

    ${({ active }) =>
      active &&
      `
      ${ActiveLine} {
        width: 100%;
      }
  `};

    &:hover {
      background: ${({ asButton }) => (asButton ? 'white' : 'transparent')};
      ${({ active }) =>
        !active &&
        `
        ${ActiveLine} {
          width: 80%;
        }
    `};
    }
  `)};
`;

export const MainPanel = styled.div`
  min-width: 250px;
  display: flex;
  flex-direction: column;
`;

export const Navigation = styled.div`
  display: flex;
  height: 44px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.orbit.paletteCloudNormal};
  border-bottom: ${({ theme }) => `1px solid ${theme.orbit.borderColorModal}`};

  ${media.tablet(css`
    border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal}
      ${({ theme }) => theme.orbit.borderRadiusNormal} 0 0;
  `)};

  ${media.desktop(css`
    background-color: transparent;
    padding: ${({ asButton }) => (asButton ? '16px 0 0 16px' : '0px')};
    border: 0;
  `)};
`;

export const FirstLetterOfDay = styled.div`
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  padding: ${({ asButton }) => (asButton ? '16px 0 0 16px' : '0px')};
  background: transparent;
`;

export const ToggleCircleButton = styled.button`
  outline: none;
  ${({ type }) =>
    type === 'info'
      ? css`
          background: ${({ theme }) => theme.orbit.colorAlertIconInfo};
          color: white !important;
          &:hover {
            background: ${({ theme }) => theme.orbit.colorTextAlertInfo};
          }
        `
      : css`
          background: rgb(208, 233, 251);
          color: ${({ theme }) => theme.orbit.colorTextAlertInfo}!important;
        `}}
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: ${({ asButton }) => (asButton ? 'white' : 'transparent')};
  padding: ${({ asButton }) => (asButton ? '16px' : '0')};
  & > button {
    margin: 2px;
  }
`;

export const PopoverActionsWrapper = styled.div`
  padding: 16px 16px 0 16px;
`;
