import React from 'react';

import  useTranslations  from '../../services/translations/useTranslations';

const Translate = ({ tkey, html, values = {} }) => {
  const { translate } = useTranslations();

  if (html) {
    return <span dangerouslySetInnerHTML={{ __html: translate(tkey, values) }} />;
  }

  return translate(tkey, values);
};

export default Translate;
