import React from 'react';

import  useTranslations  from '../../services/translations/useTranslations';

const TranslateNode = ({ tkey, values = {}, transform = x => x }) => {
  const { translate } = useTranslations();

  return transform(translate(tkey))
    .split('__')
    .filter(Boolean)
    .map((word, i) =>
      values[word] != null ? (
        <span key={i}>{values[word]}</span>
      ) : (
        <span key={i} dangerouslySetInnerHTML={{ __html: word }} />
      ),
    );
};

export default TranslateNode;
