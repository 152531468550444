import React from 'react';
import CarrierLogo from '@kiwicom/orbit-components/lib/CarrierLogo';

import { uniqueBy } from '../../utils/array';

import { BadgeWrapper } from './ItineraryCarriers.styled';

const ResultCardItineraryCarriers = ({ itinerary }) => {
  const hasGuarantee = false;

  if (hasGuarantee) {
    return (
      // Temporary workaround until Kiwi.com carrier is available in Orbit’s CarrierLogo
      // https://skypicker.slack.com/archives/CAMS40F7B/p1563543787124200?thread_ts=1563543679.123900&cid=CAMS40F7B
      <BadgeWrapper>
        <img
          src="/images/logos/kiwicom/carrier.svg"
          height="24"
          width="24"
          alt="Kiwi.com"
          title="Kiwi.com"
        />
      </BadgeWrapper>
    );
  }

  const carriers = uniqueBy(
    itinerary.segments.map(segment => segment.carrier),
    c => c.code,
  );

  return carriers.map(({ code, name }) => (
    <BadgeWrapper key={code}>
      <CarrierLogo size="medium" carriers={[{ code, name }]} />
    </BadgeWrapper>
  ));
};

export default ResultCardItineraryCarriers;
