import React from 'react';
import styled, { css } from 'styled-components';
import useClickOutside from '@kiwicom/orbit-components/lib/hooks/useClickOutside';

const LocationPickerPopupStyled = styled.div`
  z-index: 10;
  width: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.orbit.paletteWhite};
  position: absolute;
  box-sizing: border-box;
  transition: all 250ms ease-in-out;

  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 20px 60px 0 rgba(23, 27, 30, 0.4);
      z-index: 11;
      width: ${({ theme }) => `calc(100% + ${theme.orbit.spaceLarge})`};
      padding: ${({ theme }) => theme.orbit.spaceSmall};
      margin: -${({ theme }) => theme.orbit.spaceSmall};
    `};

  ${({ top }) =>
    top &&
    css`
      max-height: calc(100vh - ${top < 800 ? top + 10 : 250}px);
    `}
`;

const LocationPickerPopup = ({ children, handleOutside, active }) => {
  const ref = React.useRef();

  useClickOutside(ref, handleOutside);

  const boxDimensions = ref.current?.getBoundingClientRect();

  return (
    <LocationPickerPopupStyled ref={ref} active={active} top={boxDimensions?.y}>
      {children}
    </LocationPickerPopupStyled>
  );
};

export default LocationPickerPopup;
