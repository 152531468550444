import React from 'react';
import styled from 'styled-components';

const StyledCarrierLogo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: ${({ theme }) => theme.orbit.backgroundCarrierLogo};
`;

const StyledImage = styled.img`
  width: ${({ imageSize }) => imageSize}px;
  height: ${({ imageSize }) => imageSize}px;
  background-color: ${({ theme }) => theme.orbit.backgroundCarrierLogo};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};

  &:last-child {
    align-self: flex-end;
  }
`;

const fixIconUrl = (icon, size) => {
  const busbudFixedIcon = icon.includes('busbud')
    ? icon.replace('bg=0FFF', 'bg=FFFFFF&pad=5&nrs=100')
    : icon;

  const sizeString = size.toString();

  return busbudFixedIcon
    .replace('{width}', sizeString)
    .replace('{height}', sizeString)
    .replace('{size}', `${sizeString}x${sizeString}`);
};

const AirlineLogo = ({ airlines, size }) => {
  const sizeInPx = size === 'small' ? 16 : 32;
  const retinaSizeInPx = size === 'small' ? 32 : 64;

  return airlines.map(airline => {
    const { icon } = airline;
    if (icon) {
      const src = fixIconUrl(icon, sizeInPx);
      const srcRetina = fixIconUrl(icon, retinaSizeInPx);
      return (
        <StyledCarrierLogo size={sizeInPx}>
          <StyledImage
            imageSize={sizeInPx}
            title={airline.name}
            alt={airline.name}
            key={airline.code}
            src={src}
            srcSet={`${srcRetina} x2`}
          />
        </StyledCarrierLogo>
      );
    }
    return null;
  });
};

export default AirlineLogo;
