/* eslint-disable */
import React from 'react';
import PowerPlug from '@kiwicom/orbit-components/lib/icons/PowerPlug';
import Wifi from '@kiwicom/orbit-components/lib/icons/Wifi';
import Seat from '@kiwicom/orbit-components/lib/icons/Seat';
import Entertainment from '@kiwicom/orbit-components/lib/icons/Entertainment';

import { TripDetailTitle } from '../index.styled';

import TripSegmentDetailItem from './TripSegmentDetailsItem';
import { TripDetailTitleMargin } from './TripSegmentDetailsFlight.styled';

import  useTranslations  from '../../../services/translations/useTranslations';

const AUDIO_VIDEO_TYPES = {
  Yes: ('search.result.flight_details.yes'),
  No: ('search.result.flight_details.no'),
  BYOD: ('search.result.flight_details.audio_video.byod'),
  Paid: ('search.result.flight_details.audio_video.paid'),
};

const getPowerInSeat = powerInSeat => {
  switch (powerInSeat) {
    case 'Yes':
      return translate(('search.result.flight_details.yes'));
    case 'No':
      return translate(('search.result.flight_details.no'));
    default:
      return powerInSeat;
  }
};

const TripSegmentDetailsFlight = ({ segment }) => {
  const { seatInfo } = segment;
  const { translate } = useTranslations();

  return seatInfo ? (
    <>
      <TripDetailTitle>
        <TripDetailTitleMargin>
          {translate(('search.result.flight_details.seating_info.title'))}
        </TripDetailTitleMargin>
      </TripDetailTitle>
      <ul>
        {seatInfo.pitch && seatInfo.pitch.value && seatInfo.pitch.unit && (
          <TripSegmentDetailItem
            icon={<Seat size="small" />}
            text={translate(('search.result.flight_details.seat_pitch.label'))}
            value={`${seatInfo.pitch.value} ${seatInfo.pitch.unit.toLowerCase()}`}
          />
        )}
        {seatInfo.width && seatInfo.width.value && seatInfo.width.unit && (
          <TripSegmentDetailItem
            icon={<Seat size="small" />}
            text={translate(('search.result.flight_details.seat_width.label'))}
            value={`${seatInfo.width.value} ${seatInfo.width.unit.toLowerCase()}`}
          />
        )}
        {seatInfo.recline && seatInfo.recline.value && seatInfo.recline.unit && (
          <TripSegmentDetailItem
            icon={<Seat size="small" />}
            text={translate(('search.result.flight_details.seat_recline.label'))}
            value={`${seatInfo.recline.value} ${seatInfo.recline.unit.toLowerCase()}`}
          />
        )}
        {seatInfo.hasAudioVideo && AUDIO_VIDEO_TYPES[seatInfo.hasAudioVideo] && (
          <TripSegmentDetailItem
            icon={<Entertainment size="small" />}
            text={translate(('search.result.flight_details.audio_video.label'))}
            value={translate(AUDIO_VIDEO_TYPES[seatInfo.hasAudioVideo])}
          />
        )}
        {seatInfo.hasPower && (
          <TripSegmentDetailItem
            icon={<PowerPlug size="small" />}
            text={translate(('search.result.flight_details.inseat_power.label'))}
            value={getPowerInSeat(seatInfo.hasPower)}
          />
        )}
        {seatInfo.hasWifi !== null && (
          <TripSegmentDetailItem
            icon={<Wifi size="small" />}
            text={translate(('search.result.flight_details.wifi.label'))}
            value={
              seatInfo.hasWifi
                ? translate(('search.result.flight_details.yes'))
                : translate(('search.result.flight_details.no'))
            }
          />
        )}
      </ul>
    </>
  ) : null;
};

export default TripSegmentDetailsFlight;
