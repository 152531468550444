/* global __ */
import React, { useEffect, useState } from 'react';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import  useSearchParams  from '../../../../../../services/filters/Search/useSearchParams';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import TimeOfTravel from '../../../../../../common/TimeOfTravel';
import  useSearchFormState  from '../../../../../../scenes/Basic/services/SearchForm/useSearchForm';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

const TimesFilter = ({ asButton, noButtons = false, popoverActions }) => {
  const [opened, setOpened] = useState(false);
  const { times, setTimes, getDefaultValues, changeTimes, clearTimes } = useSearchParams();
  const { pushToDataLayer } = useGTM();
  const { translate } = useTranslations();
  const { mode } = useSearchFormState();

  const [time, setTime] = useState(times);

  useEffect(() => {
    setTime(times);
  }, [times]);

  const handleChange = e => {
    setTime({
      ...time,
      [e.direction]: {
        ...time[e.direction],
        [e.type]: e.value,
      },
    });
  };

  const handleClose = () => {
    setTime(times);
    setOpened(false);
  };

  const handleSubmit = () => {
    setTimes(time);
    setOpened(false);
  };

  const handleClear = () => {
    setTime(getDefaultValues('times'));
  };

  return (
    <FilterWrapper
      label={translate(('search.filter.times.title'))}
      asButton={asButton}
      open={opened}
      onOpen={() => setOpened(true)}
      onClose={handleClose}
      actions={
        JSON.stringify(time) !== JSON.stringify(getDefaultValues('times')) ? (
          <TextLink
            dataTest="ClearFilter-days"
            size="small"
            type="secondary"
            onClick={noButtons ? clearTimes : handleClear}
          >
            {translate(('search.filter.multi_used.button.clear'))}
          </TextLink>
        ) : (
          popoverActions
        )
      }
      width="260px"
    >
      {!noButtons && <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />}
      <TimeOfTravel
        value={time}
        onChange={e => {
          pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
          noButtons ? changeTimes(e) : handleChange(e);
        }}
        oneWay={mode === 'one-way'}
      />
    </FilterWrapper>
  );
};

export default TimesFilter;
