/* global __ */
import React, { useState } from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Button from '@kiwicom/orbit-components/lib/Button';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Modal, { ModalHeader, ModalSection, ModalFooter } from '@kiwicom/orbit-components/lib/Modal';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import SortIcon from '@kiwicom/orbit-components/lib/icons/Sort';
import ClockIcon from '@kiwicom/orbit-components/lib/icons/Clock';

import { usePostMessages } from '../../../../../../services/postMessages';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import Price from '../../../../../../common/Price';
import TripTime from '../../../../../../common/TripTime';
import { SORT_BY_TRANSLATIONS } from '../../../../../../consts/sortBy';

import { VerticalCenter } from './common';

const SortItem = React.memo(({ sortBy, data, draft, onClick }) => {
  const isSelected = draft === sortBy;
  const { translate } = useTranslations();

  const handleSortClick = () => {
    onClick(sortBy);
  };

  return (
    <div onClick={handleSortClick}>
      <Stack direction="row" spaceAfter="medium" align="center">
        <Stack spacing="none">
          <Text spaceAfter="none" weight={isSelected ? 'bold' : 'normal'}>
            {translate(SORT_BY_TRANSLATIONS[sortBy])}
          </Text>
          <Stack direction="row">
            <Price highlight={isSelected}>{data.price.amount}</Price>
            <VerticalCenter>
              <ClockIcon size="small" />
              <TripTime duration={data?.duration} highlight={isSelected} />
            </VerticalCenter>
          </Stack>
        </Stack>
        <VerticalCenter>
          <Radio checked={isSelected} onChange={handleSortClick} />
        </VerticalCenter>
      </Stack>
    </div>
  );
});

const SortByMobile = ({ topResults, sortBy, changeSortBy }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sortDraft, setSortDraft] = useState(sortBy);
  const { translate } = useTranslations();
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const [contentHeight, setContentHeight] = useState(false);

  const handleModalClose = () => {
    unlockResizing();
    postIframeHeight(contentHeight, true);
    setModalOpen(false);
    setSortDraft(sortBy);
  };

  const handleSelectDraft = sortParam => {
    setSortDraft(sortParam);
  };

  const handleSubmit = () => {
    postIframeHeight(contentHeight, true);
    unlockResizing();
    changeSortBy(sortDraft);
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setContentHeight(window.innerHeight);
    postIframeHeight(600);
    lockResizing();
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen && (
        <Modal size="small" onClose={handleModalClose}>
          <ModalHeader title={translate(('search.result.sorting.name'))} />
          <ModalSection>
            <SortItem
              sortBy="best"
              data={topResults.best}
              draft={sortDraft}
              onClick={handleSelectDraft}
            />
            <SortItem
              sortBy="price"
              data={topResults.cheapest}
              draft={sortDraft}
              onClick={handleSelectDraft}
            />
            <SortItem
              sortBy="duration"
              data={topResults.fastest}
              draft={sortDraft}
              onClick={handleSelectDraft}
            />
          </ModalSection>
          <ModalFooter>
            <Stack direction="row">
              <Button type="secondary" fullWidth onClick={handleModalClose}>
                {translate(('search.form.button.cancel'))}
              </Button>
              <Button fullWidth onClick={handleSubmit}>
                {translate(('search.form.distance.button.done'))}
              </Button>
            </Stack>
          </ModalFooter>
        </Modal>
      )}
      <ButtonLink size="small" type="secondary" iconLeft={<SortIcon />} onClick={handleModalOpen}>
        {translate(SORT_BY_TRANSLATIONS[sortBy])}
      </ButtonLink>
    </>
  );
};

export default SortByMobile;
