/* global __ */
import React from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Clock from '@kiwicom/orbit-components/lib/icons/Clock';
import Check from '@kiwicom/orbit-components/lib/icons/Check';
import Alert from '@kiwicom/orbit-components/lib/icons/Alert';
import BaggageCabin from '@kiwicom/orbit-components/lib/icons/BaggageCabin';

import  useTranslations  from '../../../../services/translations/useTranslations';
import TripTime from '../../../../common/TripTime';
import TranslateNode from '../../../../common/TranslateNode';
import Translate from '../../../../common/Translate';

import ItineraryTripLayoverItem from './ItineraryTripLayoverItem';

const StyledTripTime = styled(TripTime)`
  font-size: 12px;
`;

const ItineraryTripLayover = ({ stop, itinerary }) => {
  const { translate } = useTranslations();
  // const promotedAirport = React.useContext(PromotedAirportReactContext);

  // if (stop.isTechnicalStop)
  //   return (
  //     <ul>
  //       <ItineraryTripLayoverItem
  //         text={
  //           <>
  //             <h4>
  //               <TranslateNode
  //                 t="search.result.itinerary_detail.info.technical_stop.title"
  //                 values={{
  //                   time: <Duration value={stop.duration} />,
  //                 }}
  //               />
  //             </h4>
  //             <Translate t="search.result.itinerary_detail.info.technical_stop.description" />
  //           </>
  //         }
  //         icon={<Fuel size="small" />}
  //       />
  //     </ul>
  //   );

  return (
    <ul>
      <ItineraryTripLayoverItem
        icon={<Clock size="small" />}
        text={
          <TranslateNode
            tkey={('booking.global.layover')}
            values={{
              time: <StyledTripTime duration={stop.layover.duration} />,
            }}
          />
        }
      />
      {/* TODO: allowedHoldBags just because BE send also for no bags itinerary that recheck is required */}
      {stop.layover.isBaggageRecheck && Boolean(itinerary.bagsInfo.includedHandBags) && (
        <ItineraryTripLayoverItem
          text={translate(('search.result.itinerary_detail.alerts.bags_recheck'))}
          icon={<BaggageCabin size="small" />}
        />
      )}
      {/* {stop.hasGuarantee && (
        <ItineraryTripLayoverItem
          text={
            <strong>
              <Translate
                t={getSelfTransferKey(stop)}
                values={{
                  airport: stop.code,
                }}
                html
              />
            </strong>
          }
          icon={<SelfTransferIcon size="small" />}
          onClick={
            isFAQEnabled
              ? bind(openSelfTransferInfoArticle, null, openHelpCenterArticle, track)
              : undefined
          }
        />
      )} */}
      {stop.layover.guarantee && (
        <ItineraryTripLayoverItem
          text={
            <Translate
              html
              tkey={('booking.global.guarantee.company_covered')}
              values={{ companyName: 'Kiwi.com' }}
            />
          }
          icon={<Check size="small" />}
        />
      )}

      {/* {!stop.hasGuarantee && !stop.differentStation && (
        <ItineraryTripLayoverItem
          text={
            <span>
              <Translate t="booking.global.guarantee.airline_covered" html />
            </span>
          }
          icon={<Check size="small" />}
          onClick={
            isFAQEnabled
              ? bind(openAirlineGuaranteeInfoArticle, null, openHelpCenterArticle, track)
              : undefined
          }
        />
      )} */}

      {/* {promotedAirport &&
        PromotedAirportUtils.isPromotedStop(promotedAirport, stop) &&
        promotedAirport.stopoverBanner} */}
      {stop.layover.isStationChange && (
        <ItineraryTripLayoverItem
          text={
            <Text type="warning" size="small" element="span">
              {translate(
                stop.type === 'FLIGHT'
                  ? ('multi_modal.search.detail.alert.departing_next_day.flight_only')
                  : ('multi_modal.search.detail.alert.departing_next_day.bus_train'),
              )}
            </Text>
          }
          icon={<Alert color="warning" size="small" />}
        />
      )}
    </ul>
  );
};

export default ItineraryTripLayover;
