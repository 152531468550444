import React from 'react';

import  DirectionFilter from '../../../../../../common/Filters/Direction';
import  ClassFilter from '../../../../../../common/Filters/Class';
import  PassengersAndBags from '../../../../../../common/Filters/PassengersAndBags';
import  CurrenciesFilter from '../../../../../../common/Filters/Currencies';
import useViewport from '../../../../services/Viewport/useViewport';

import { FiltersContainer, FiltersWrapper } from './index.styled';
import useSearchFormState from "src/scenes/Basic/services/SearchForm/useSearchForm";

const Filters = () => {
  const { mode, setSearchMode } = useSearchFormState();
  const { isMobile } = useViewport();

  // Handles removal of "close" button from mobile popover and empty div from desktop popover:
  // On desktop mode fragment creates an additional div which adds height and we don't want that
  // but on mobile without specifying actions, we get a default close button so we put an empty fragment
  const popoverActions = isMobile ? <></> : undefined;

  return (
    <FiltersContainer>
      <FiltersWrapper>
        <DirectionFilter value={mode} onChange={setSearchMode} popoverActions={popoverActions} />
        <ClassFilter popoverActions={popoverActions} />
      </FiltersWrapper>
      <FiltersWrapper>
        <PassengersAndBags popoverActions={popoverActions} />
        <CurrenciesFilter popoverActions={popoverActions} />
      </FiltersWrapper>
    </FiltersContainer>
  );
};

export default Filters;
