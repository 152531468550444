import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';

export const isPast = (day, date) => isBefore(day, startOfDay(date));

export const isFuture = (day, date) => isAfter(day, startOfDay(date));

export const isSelected = (day, selectedDates) => {
  return !!selectedDates.find(selected => isSameDay(selected, day));
};

export const isDisabled = (day, disabledDates) =>
  !!disabledDates.find(disabled => isSameDay(disabled, day));

export const isHovered = (day, hoveredDates) =>
  !!hoveredDates.find(hovered => isSameDay(hovered, day));

export const isFirst = (day, selectedDates, hoveredDates) => {
  return hoveredDates.length ? isSameDay(day, hoveredDates[0]) : isSameDay(day, selectedDates[0]);
};

export const isLast = (day, selectedDates) => {
  return isSameDay(day, selectedDates[selectedDates.length - 1]);
};
