import styled from 'styled-components';
import { right, rtlSpacing } from '@kiwicom/orbit-components/lib/utils/rtl';

import { dots, TripCell, TripFlightCell, line } from '../index.styled';

export const TripTime = styled(TripCell)`
  padding: ${({ theme }) =>
    rtlSpacing(
      `${theme.orbit.spaceXXSmall} 0 ${theme.orbit.spaceXXSmall} ${theme.orbit.spaceXSmall}`,
    )};
  white-space: nowrap;
  text-align: center;
`;

export const TripTimeText = styled.strong`
  font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
`;

export const TripPlace = styled(TripCell)`
  padding: ${({ theme }) => theme.orbit.spaceXXSmall} 0;

  svg {
    z-index: 2;
    margin-${right}: ${({ theme }) => theme.orbit.spaceXSmall};
  }

  &:before {
    top: 0;
    height: 50%;
    ${({ isFirst }) => (isFirst ? dots : line)};
  }

  &:after {
    bottom: 0;
    height: 50%;
    ${({ isFirst }) => (isFirst ? line : dots)};
  }
`;

export const TripFlightCarrier = styled(TripCell)`
  align-items: flex-start;
  padding: ${({ theme }) => theme.orbit.spaceSmall};
  padding-${right}: ${({ theme }) => theme.orbit.spaceXXSmall};
  background: ${({ theme }) => theme.orbit.paletteCloudLight};
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const ShowMoreIcon = styled.button.attrs({
  type: 'button',
})`
  padding: ${({ theme }) => theme.orbit.spaceXXSmall};
  background: transparent;
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.orbit.backgroundButtonSecondaryHover};
  }

  &:focus {
    box-shadow: rgba(95, 115, 140, 0.5) 0 0 0 3px;
  }
`;

export const TripFlightInfo = styled(TripFlightCell)`
  background: ${({ theme }) => theme.orbit.paletteCloudLight};
  ${({ clickable }) => clickable && 'cursor: pointer;'}

  &:before {
    ${line};
  }

  /* Override Orbit’s Stack */
  /* stylelint-disable-next-line */
  div > ${ShowMoreIcon}:last-child {
    margin: -${({ theme }) => theme.orbit.spaceXXSmall} !important;
  }
`;

export const TripSegmentAlertDate = styled(TripCell)`
  padding: ${({ theme }) =>
    rtlSpacing(
      `${theme.orbit.spaceXXSmall} 0 ${theme.orbit.spaceXXSmall} ${theme.orbit.spaceXSmall}`,
    )};
  background: ${({ theme }) => theme.orbit.backgroundAlertCritical};
  ${({ isFirst, theme }) => isFirst && `border-top-left-radius: ${theme.orbit.borderRadiusNormal};`}
`;

export const TripSegmentAlertInfo = styled(TripCell)`
  padding: ${({ theme }) =>
    rtlSpacing(
      `${theme.orbit.spaceXXSmall} ${theme.orbit.spaceSmall} ${theme.orbit.spaceXXSmall} ${theme.orbit.spaceLarge}`,
    )};
  background: ${({ theme }) => theme.orbit.backgroundAlertCritical};
  ${({ isFirst, theme }) =>
    isFirst && `border-top-${right}-radius: ${theme.orbit.borderRadiusNormal};`}
  &:before {
    ${({ isFirst }) => (isFirst ? dots : line)};
    top: 0;
    height: 100%;
    ${({ isFirst, theme }) =>
      isFirst
        ? `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3E%3Ccircle cx='60' cy='60' r='30' fill='%23eeb9b9'/%3E%3C/svg%3E")`
        : `background-color: ${theme.orbit.paletteRedLightHover}`};
  }
`;

export const SegmentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
