import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

const getButtonColor = props => {
  if (props.disabled) {
    return 'gray';
  }
  if (props.isHovered || props.isSelected) {
    return 'white';
  }
  return 'inherit';
};

const getButtonBg = ({ theme, ...props }) => {
  if (props.disabled) {
    return 'white';
  }
  if (props.isHovered || props.isSelected) {
    return theme.orbit.paletteBlueNormal;
  }
  return 'white';
};

const getButtonHoverBg = ({ theme, ...props }) => {
  if (props.isHovered || props.isFocused) {
    return theme.orbit.paletteBlueNormal;
  }
  if (props.isSelected) {
    return theme.orbit.paletteBlueNormalHover;
  }
  return 'white';
};

const getButtonBorder = ({ theme, ...props }) => {
  if (props.disabled) {
    return '2px solid transparent';
  }
  if (props.isSelected || props.isHovered) {
    return `2px solid ${theme.orbit.paletteBlueNormal}`;
  }
  if (props.isHovered && props.isSelected) {
    return `2px solid white`;
  }
  return '2px solid transparent';
};

const getButtonHoverBorder = ({ theme, disabled }) => {
  if (disabled) {
    return '2px solid transparent';
  }
  return `2px solid ${theme.orbit.paletteBlueNormal}`;
};

const getButtonBorderRadius = props => {
  if (props.isFirst && props.isLast && !props.isFocused) {
    return '3px';
  }
  if (props.isFirst && props.isSelecting) {
    return '0';
  }
  if (props.isFirst && !props.isFocused) {
    return '3px 0px 0px 3px';
  }
  if (props.isLast && !props.isFocused) {
    return '0px 3px 3px 0px';
  }
  return '0';
};

const getButtonHoverBorderRadius = props => {
  if (props.disabled || props.isSelected || props.isFocused || props.isFirst) {
    return '0';
  }
  return '3px';
};

export const Button = styled.button`
  display: flex;
  height: 50px;
  width: 100%;
  padding: 2px;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  color: ${getButtonColor};
  background: ${getButtonBg};
  border: ${getButtonBorder};
  border-radius: ${getButtonBorderRadius};
  font-size: inherit;

  ${props => !props.isHidden && 'cursor: pointer;'};

  &:hover {
    background: ${getButtonHoverBg};
    border: ${getButtonHoverBorder};
    border-radius: ${getButtonHoverBorderRadius};
  }

  &:disabled {
    cursor: default;
    &:hover {
      background: inherit;
    }
  }

  &:focus {
    outline: none;
  }

  ${media.largeMobile(css`
    width: 50px;
  `)}
`;

export const Date = styled.span`
  font-size: 16px;
  margin-bottom: 0 !important;
`;

export const PriceTag = styled.span`
  font-size: 12px;
`;

export const Td = styled.div`
  display: table-cell;
  box-sizing: border-box;
`;

export const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
`;
