import React from 'react';
import styled from 'styled-components';
import City from '@kiwicom/orbit-components/lib/icons/City';
import GpsFixed from '@kiwicom/orbit-components/lib/icons/GpsFixed';
import Trip from '@kiwicom/orbit-components/lib/icons/Trip';
import AirplaneUp from '@kiwicom/orbit-components/lib/icons/AirplaneUp';
import Train from '@kiwicom/orbit-components/lib/icons/Train';
import Bus from '@kiwicom/orbit-components/lib/icons/Bus';
import Location from '@kiwicom/orbit-components/lib/icons/Location';
import CountryFlag from '@kiwicom/orbit-components/lib/CountryFlag';

import { PLACE_TYPES, PLACE_VALUE_MODES } from '../../consts/place';

const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.orbit.heightIconMedium};
`;

const PlacePickerRowIcon = React.memo(({ place }) => {
  if (place.mode === PLACE_VALUE_MODES.RADIUS) {
    return <GpsFixed />;
  }

  switch (place.type) {
    case PLACE_TYPES.CITY:
      return <City />;
    case PLACE_TYPES.AIRPORT:
      return <AirplaneUp />;
    case PLACE_TYPES.TRAIN_STATION:
      return <Train />;
    case PLACE_TYPES.BUS_STATION:
      return <Bus />;
    case PLACE_TYPES.SPECIAL:
      return <Location />;
    case PLACE_TYPES.TOURIST_REGION:
      return <Location />;
    case PLACE_TYPES.COUNTRY:
      // NOTE: Extra condition is necessary to filter out `subdivision` types which we currently also mark as COUNTRY; Can be removed once we start using separate `SUBDIVISION` type
      return !place.value.country ? (
        <FlagWrapper>
          <CountryFlag code={place.code} name={place.name} />
        </FlagWrapper>
      ) : (
        <Trip />
      );
    default:
      return <Trip />;
  }
});

export default PlacePickerRowIcon;
