import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

export const StyledPictureCardSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  padding: ${({ theme }) => theme.orbit.spaceMedium};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  box-shadow: ${({ theme }) => theme.orbit.boxShadowAction};
  background-color: ${({ theme }) => theme.orbit.backgroundButtonWhite};
`;

export const PictureCardSkeletonContent = styled.div`
  background: ${({ theme }) => theme.orbit.backgroundTableShadowRight};
`;

export const StyledPictureCardsPlaceholders = styled.div`
  width: 100%;
  position: relative;
  top: 43px;
`;

export const PictureCardPlaceholderWrapper = styled.div`
  height: 250px;
  margin-top: 10px;
`;

export const RowWrapper = styled.div`
  display: flex;
`;

export const TileWrapper = styled.div`
  position: relative;
  width: 100%;

  ${media.mediumMobile(css`
    width: 100%;

    @media (max-width: 767px) {
      margin-${right}: ${({ theme }) => theme.orbit.spaceSmall};
      &:last-of-type {
        margin-${right}: 0;
      }
    }
  `)};

  ${media.tablet(css`
    ${({ mapLayout, theme, width }) =>
      mapLayout
        ? 'width: 100%'
        : css`
      ${width && `width: ${width}%`};
      padding-bottom: ${theme.orbit.spaceMedium};
      padding-${right}: ${theme.orbit.spaceMedium};

      &:last-of-type {
        padding-${right}: 0;
      }
    `};
  `)}

  @media (min-width: 768px) and (max-width: 1023px) {
    ${({ placeholder }) =>
      placeholder === 'mapRow' &&
      css`
        width: 100%;
        &:nth-of-type(n + 3) {
          display: none;
        }
      `};
    ${({ placeholder }) =>
      placeholder === 'row' &&
      css`
        width: calc(100% / 3);
        &:nth-of-type(4n),
        &:nth-of-type(4n - 3) {
          width: calc(100% / 3 * 2);
        }
      `};
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    ${({ placeholder }) =>
      placeholder === 'mapRow' &&
      css`
        width: 50%;
        &:nth-of-type(3) {
          width: 100%;
        }
        &:nth-of-type(n + 4) {
          display: none;
        }
      `};
    ${({ placeholder }) =>
      placeholder === 'row' &&
      css`
        width: 25%;
        &:nth-of-type(4n - 2),
        &:nth-of-type(4n - 3) {
          width: 50%;
        }
      `};
  }

  @media (min-width: 1440px) {
    ${({ mapTile }) => mapTile && 'width: 40%'};
    ${({ placeholder }) =>
      placeholder === 'mapRow' &&
      css`
        width: calc(100% / 3);
        &:nth-of-type(4) {
          width: calc(100% / 3 * 2);
        }
      `};
    ${({ placeholder }) =>
      placeholder === 'row' &&
      css`
        width: 20%;
        &:nth-of-type(4n - 2),
        &:nth-of-type(4n - 3) {
          width: 40%;
        }
        &:last-of-type {
          width: 20%;
        }
      `};
  }
`;
