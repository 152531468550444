/* global __ */
import React, { useState, useContext, useEffect } from 'react';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import {SearchState}  from '../../../../../../services/filters/Search/SearchProvider';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

const MoreFilter = ({ asButton, noButtons, popoverActions }) => {
  const [open, setOpen] = useState(false);
  const {
    returnFromDifferentAirport,
    returnToDifferentAirport,
    setAllowChangeInbound,
    resetAllowChangeInbound,
    changeAllowChangeInbound,
    getDefaultValues,
  } = useContext(SearchState);
  const { translate } = useTranslations();

  const [returns, setReturns] = useState({
    returnFromDifferentAirport,
    returnToDifferentAirport,
  });

  useEffect(() => {
    setReturns({ returnFromDifferentAirport, returnToDifferentAirport });
  }, [returnFromDifferentAirport, returnToDifferentAirport]);

  const handleClose = () => {
    setReturns({
      returnFromDifferentAirport,
      returnToDifferentAirport,
    });
    setOpen(false);
  };

  const handleChange = value => {
    setReturns({
      ...returns,
      [value]: !returns[value],
    });
  };

  const handleSubmit = () => {
    setAllowChangeInbound(returns);
    setOpen(false);
  };

  const handleClear = () => {
    if (noButtons) {
      resetAllowChangeInbound();
      handleClose();
    } else {
      setReturns({
        returnFromDifferentAirport: getDefaultValues('returnFromDifferentAirport'),
        returnToDifferentAirport: getDefaultValues('returnToDifferentAirport'),
      });
    }
  };

  return (
    <FilterWrapper
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      label={translate(('search.filter.more'))}
      asButton={asButton}
      actions={
        !returns.returnFromDifferentAirport || !returns.returnToDifferentAirport ? (
          <TextLink size="small" type="secondary" onClick={handleClear} dataTest="ClearFilter-more">
            {translate(('search.filter.multi_used.button.clear'))}
          </TextLink>
        ) : (
          popoverActions
        )
      }
      width="260px"
    >
      {!noButtons && <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />}
      <Stack spacing="XSmall">
        <Checkbox
          label={translate(('result.filter.return_from_different_airport'))}
          checked={returns.returnFromDifferentAirport}
          onChange={e => {
            noButtons
              ? changeAllowChangeInbound({
                  key: 'returnFromDifferentAirport',
                  value: e.target.checked,
                })
              : handleChange('returnFromDifferentAirport');
          }}
        />
        <Checkbox
          label={translate(('result.filter.return_to_different_airport'))}
          checked={returns.returnToDifferentAirport}
          onChange={e => {
            noButtons
              ? changeAllowChangeInbound({
                  key: 'returnToDifferentAirport',
                  value: e.target.checked,
                })
              : handleChange('returnToDifferentAirport');
          }}
        />
      </Stack>
    </FilterWrapper>
  );
};

export default MoreFilter;
