import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import getDay from 'date-fns/getDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import isSameMonth from 'date-fns/isSameMonth';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import getTime from 'date-fns/getTime';
import parseISO from 'date-fns/parseISO';

const UMBRELLA_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const formatTripDuration = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  return [hours && `${hours}h`, minutes && `${minutes}m`].filter(Boolean).join(' ');
};

// return days to prepend (these days are from previous month)
export const daysFromPrevMonth = (start, lastDayOfPrevMonth, firstDay) => {
  // it's first day of the month, no need to prepend
  if (getDay(start) === firstDay) {
    return [];
  }
  // otherwise prepend days from previous month
  const prevMonthDays = eachDayOfInterval({
    start: subDays(lastDayOfPrevMonth, 5),
    end: lastDayOfPrevMonth,
  });
  let i = 0;
  let dates = [...prevMonthDays];
  while (getDay(prevMonthDays[i]) !== firstDay) {
    dates = dates.slice(1);
    i++;
  }

  return dates;
};

export const lastDayOfPrevMonth = month => lastDayOfMonth(subMonths(month, 1));

// render prepended days from previous month + days from current month
export const calendarDaysToRender = (firstDay, month) => {
  // start of month
  const start = startOfMonth(month);
  // end of month
  const end = endOfMonth(month);

  // concat days from previous month and days from current month
  return [
    ...daysFromPrevMonth(start, lastDayOfPrevMonth(month), firstDay),
    ...eachDayOfInterval({ start, end }),
  ];
};

export const calendarDayNames = months => months.slice(0, 7);

// Will return first dates of months between `firstMonth` and `lastMonth`
export const getMonths = (firstMonth, lastMonth) => {
  const num = differenceInCalendarMonths(addMonths(lastMonth, 1), firstMonth);
  const months = [];
  for (let i = 0; i < num; i++) {
    months.push(addMonths(startOfMonth(firstMonth), i));
  }
  return months;
};

export const getCurrentMonthIndex = (
  firstMonth,
  lastMonth,
  dates,
  future,
  visibleMonths,
  today = new Date(),
) => {
  const datesOrToday = !dates.length ? [today] : dates;
  const months = getMonths(firstMonth, lastMonth);
  const index = months.findIndex(month => isSameMonth(month, datesOrToday[0]));

  // without future and without preselect
  if (!future && !dates.length) {
    return index - (visibleMonths - 1);
  }
  // with preselect
  if (dates.length) {
    const toSubstract = index + visibleMonths - months.length;
    if (toSubstract >= 0) {
      return index - toSubstract;
    }
  }
  return index;
};

export const calendarMonthsToRender = (visibleMonths, currentMonth, months) => {
  if (visibleMonths >= months.length) {
    return months;
  }
  const monthsWithoutCurrent = months.slice(currentMonth);
  return monthsWithoutCurrent.slice(0, visibleMonths);
};

export const dateRange = (dates, locale) => {
  if (dates.length === 0) {
    return '';
  }
  if (dates.length === 1) {
    return format(dates[0], 'eee dd MMM', { locale });
  }
  return `${format(dates[0], 'eee dd MMM', { locale })} -  ${format(
    dates[dates.length - 1],
    'eee dd MMM',
    {
      locale,
    },
  )}`;
};

const setHours = (date, type) => {
  if (type === 'start') {
    return startOfDay(date);
  }
  return endOfDay(date);
};

export const adjustForQuery = dates => {
  if (dates === 'anytime' || isNightsOfStay(dates)) {
    return dates;
  }
  if (dates?.start?.length) {
    return {
      start: format(new Date(dates.start), UMBRELLA_DATE_FORMAT),
      end: format(new Date(dates.end), UMBRELLA_DATE_FORMAT),
    };
  }
  const dateTime = {
    start: setHours(dates[0], 'start'),
    end: setHours(dates[dates.length - 1]),
  };
  return {
    start: format(dateTime.start, UMBRELLA_DATE_FORMAT),
    end: format(dateTime.end, UMBRELLA_DATE_FORMAT),
  };
};

export const getFirstDayOffset = locale => locale?.options?.weekStartsOn || 0;

const weekdays = (locale, notOffseted) => {
  const firstDayOffset = notOffseted ? 0 : getFirstDayOffset(locale);
  const now = new Date();
  return eachDayOfInterval({
    start: addDays(startOfWeek(now), firstDayOffset),
    end: addDays(endOfWeek(now), firstDayOffset),
  });
};

export const getWeekdays = locale => weekdays(locale).map(day => format(day, 'EEEEEE', { locale }));

export const getOffsetedSingleLetterWeekdays = (locale, notOffseted = false) =>
  weekdays(locale, notOffseted).map(day => format(day, 'EEEEE', { locale }).slice(0, 1));

const fillArrayWithDayIndexesFromRange = (fromIndex, toIndex) => {
  const res = [];
  for (
    let counter = 0, currentDayIndex = fromIndex;
    currentDayIndex <= toIndex;
    counter++, currentDayIndex++
  ) {
    res[counter] = currentDayIndex;
  }
  return res;
};

export const getWeekDaysOutOfRange = searchDate => {
  if (differenceInDays(new Date(searchDate.end), new Date(searchDate.start)) >= 7) {
    return [];
  }

  const fromDay = getDay(new Date(searchDate.start));
  const toDay = getDay(new Date(searchDate.end));
  if (fromDay > toDay) {
    return fillArrayWithDayIndexesFromRange(toDay + 1, fromDay - 1);
  }

  return [
    ...(fromDay === 0 ? [] : fillArrayWithDayIndexesFromRange(0, fromDay - 1)),
    ...(toDay === 6 ? [] : fillArrayWithDayIndexesFromRange(toDay + 1, 6)),
  ];
};

export const getMidnight = date =>
  startOfDay(new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000));

export const getUmbrellaDate = (date1, date2) => {
  return [format(date1, 'yyyy-MM-dd'), date2 && format(date2, 'yyyy-MM-dd')].join('_');
};

export const getGTMDate = (date1, date2, mode) => {
  if (!date1?.length || !date2?.length) {
    if (mode === 'one-way') {
      return 'No return';
    }
    return 'Anytime';
  }
  const start = format(new Date(date1), 'dd/MM/yyyy');
  const end = format(new Date(date2), 'dd/MM/yyyy');
  if (start === end) {
    return start;
  }
  return `${start}-${end}`;
};

export const getTimestamp = dateString => {
  try {
    const date = parseISO(dateString);
    return getTime(date);
  } catch (err) {
    return null;
  }
};

export const isNightsOfStay = daysRange => {
  // Checks for range between 1 and 31, first number can be 1-9 or 10-29 or 30-31
  // https://stackoverflow.com/questions/5811528/regexp-range-of-number-1-to-36
  const daysRangeRegex = /^((?:[1-9]|[1-2][0-9]|3[0-1])-(?:[1-9]|[1-2][0-9]|3[0-1]))$/;

  if (daysRangeRegex.test(daysRange)) {
    const [first, second] = daysRange.split('-');
    if (+first > +second) {
      return false;
    }

    return true;
  } else {
    return false;
  }
};
