import Nope from 'nope-validator';
import addDays from 'date-fns/addDays';

import { supportedLocales } from '../../../consts/locales';
import { chunk } from '../../../utils/array';
import { DAY_OPTIONS } from '../../../consts/days';
import { isNightsOfStay } from '../../../utils/datetime';

const sortByOptions = ['best', 'price', 'duration'];
const transportTypesOptions = ['FLIGHT', 'BUS', 'TRAIN'];
const cabinClassOptions = ['ECONOMY', 'PREMIUM_ECONOMY', 'BUSINESS', 'FIRST_CLASS'];

const transportTypesRule = transportTypes => {
  if (!transportTypes) {
    return undefined;
  }
  const valid = transportTypes.split(',').every(t => transportTypesOptions.includes(t));

  if (valid) {
    return undefined;
  }

  return `Transport type must be one of: ${transportTypesOptions.join(', ')}`;
};

const inboundOutboundDaysRule = inboundOutboundDaysString => {
  const SHORTENED_DAY_OPTIONS = DAY_OPTIONS.map(day => day.substr(0, 3));
  if (!inboundOutboundDaysString) {
    return undefined;
  }
  const valid = inboundOutboundDaysString
    .split(',')
    .every(day => SHORTENED_DAY_OPTIONS.includes(day));

  if (valid) {
    return undefined;
  }

  return `Inbound/outbound days must be one of: ${SHORTENED_DAY_OPTIONS.join(', ')}.`;
};

export const BaseSchema = Nope.object().shape({
  iframeId: Nope.string(),
  lang: Nope.string().when(['lang'], {
    is: lang => !!lang,
    then: Nope.string().test(lang =>
      supportedLocales.includes(lang?.toLowerCase())
        ? undefined
        : `Lang must one of: ${supportedLocales.join(', ')}`,
    ),
  }),
  ...(process.env.NODE_ENV === 'development'
    ? { affilid: Nope.string().atMost(1000) }
    : {
        affilid: Nope.string().atMost(1000).required('Affiliate ID must be specified'),
      }),
  parentHref: Nope.string(),
  affiliateNetwork: Nope.string().oneOf(['cj'], 'affiliateNetwork must be CJ'),
  pid: Nope.string().when(['affiliateNetwork'], {
    is: val => !!val,
    then: Nope.string().required('Please include pid if using CJ network'),
  }),
  sid: Nope.string().when(['affiliateNetwork'], {
    is: val => !!val,
    then: Nope.string().required('Please include sid if using CJ network'),
  }),
  currency: Nope.string().exactLength(3),
  sub1: Nope.string().atMost(255),
  sub2: Nope.string().atMost(255),
  sub3: Nope.string().atMost(255),
  sub4: Nope.string().atMost(255),
  sub5: Nope.string().atMost(255),
  sortBy: Nope.string().oneOf(sortByOptions, `Sort by must be one of: ${sortByOptions.join(', ')}`),
  stopNumber: Nope.number().atLeast(-1).atMost(2),
  source: Nope.string(),
  destination: Nope.string(),
  outboundDate: Nope.string(),
  inboundDate: Nope.string(),
  outboundDays: Nope.string().test(inboundOutboundDaysRule),
  inboundDays: Nope.string().test(inboundOutboundDaysRule),
  brand: Nope.string(),
  limit: Nope.number().atLeast(1).atMost(1000),
  transportTypes: Nope.string().test(transportTypesRule),
  airlinesList: Nope.string(),
  airlinesListExclude: Nope.string(),
  passengers: Nope.string()
    .max(6)
    .test(value => {
      if (!value) {
        return undefined;
      }

      if (value.length === 1 && +value > 9) {
        if (+value > 9) {
          return 'Total passengers must be less than 9';
        }

        return undefined;
      }

      const [adults, children, infants] = value.split('-').map(Number);

      if (adults + children + infants > 9) {
        return 'Total passengers must be less than 9';
      }

      if (infants > adults) {
        return 'The number of adults must be greater than or equal to the number of infants';
      }

      if (adults === 0 && children !== 0) {
        return 'There must be at least one adult';
      }

      return undefined;
    }),
  cabinClass: Nope.string().oneOf(
    cabinClassOptions,
    `Cabin class by must be one of: ${cabinClassOptions.join(', ')}`,
  ),
  bags: Nope.string(),
  originRadius: Nope.string(),
  ui: Nope.string(),
});

export const transformPlaceFromParams = place => {
  return Array.isArray(place) ? place : place?.split(',');
};

export const transformDateFromParams = (value, outbound) => {
  if (!value) {
    return undefined;
  }

  if (value === 'anytime' || isNightsOfStay(value)) {
    return value;
  }

  if (value.length >= 1 && value.length < 8 && value !== 'anytime') {
    const [intervalStart, intervalEnd] = value.split('-').map(Number);
    const [outboundFrom, outboundTo] = outbound || [];

    if (!intervalEnd || intervalStart === intervalEnd) {
      const dateFrom = new Date(addDays(outboundFrom, intervalStart));

      return [
        dateFrom,
        outboundTo ? new Date(addDays(outboundTo, intervalEnd || intervalStart)) : dateFrom,
      ];
    }

    return [
      new Date(addDays(outboundFrom, intervalStart)),
      new Date(addDays(outboundTo, intervalEnd)),
    ];
  }

  let dates = value.split('_');

  // fix invalid usage such as 2021-03-23-2021-06-01
  if (dates.length === 1 && dates[0].length === 21) {
    dates = chunk(dates[0].split('-'), 3).map(date => date.join('-'));
  }

  return [new Date(dates[0]), dates[1] ? new Date(dates[1]) : new Date(dates[0])];
};

const getPassengers = paxParam => {
  if (!paxParam) {
    return '1-0-0';
  }

  // parse old 1 number param to adults
  if (paxParam.length === 1) {
    return `${paxParam}-0-0`;
  }

  return paxParam;
};

const transformCarriers = (airlinesList, selectedAirlinesExclude) => {
  if (!airlinesList) {
    return undefined;
  }
  const carriers = airlinesList.split(',');
  if (selectedAirlinesExclude === 'true') {
    return { excludeCarriers: carriers };
  }
  return { carriers };
};

const getOutboundDate = (outboundDate, isDeeplink) => {
  if (isDeeplink) {
    return undefined;
  }
  const fallbackDate = 'anytime';
  let date = outboundDate || fallbackDate;

  if (outboundDate === 'anytime') {
    date = fallbackDate;
  }

  return transformDateFromParams(date);
};

const transformOutboundInboundDaysFromParams = param => {
  if (param) {
    const paramArray = param.split(',');
    return DAY_OPTIONS.map(dayOption =>
      paramArray.includes(dayOption.substring(0, 3)) ? dayOption : null,
    );
  }
  return DAY_OPTIONS;
};

const defaultTimes = {
  departure: [0, 24],
  arrival: [0, 24],
};

const transformTimes = params => {
  if (!params.times) {
    return {
      outbound: defaultTimes,
      inbound: defaultTimes,
    };
  }

  const [outbound, inbound] = params.times.split('_');

  const [outboundDepartureMin, outboundDepartureMax, outboundArrivalMin, outboundArrivalMax] =
    outbound.split('-');

  const [inboundDepartureMin, inboundDepartureMax, inboundArrivalMin, inboundArrivalMax] =
    inbound?.split?.('-') || [];

  return {
    outbound: {
      departure: [+outboundDepartureMin, +outboundDepartureMax],
      arrival: [+outboundArrivalMin, +outboundArrivalMax],
    },
    inbound: inbound
      ? {
          departure: [+inboundDepartureMin, +inboundDepartureMax],
          arrival: [+inboundArrivalMin, +inboundArrivalMax],
        }
      : defaultTimes,
  };
};

export const transformCommonParams = (params, defaults = {}) => {
  return {
    transportTypes: params.transportTypes?.split(',') || transportTypesOptions,
    source: transformPlaceFromParams(params.source),
    destination: transformPlaceFromParams(params.destination),
    outboundDate: getOutboundDate(params.outboundDate, defaults.isDeeplink),
    inboundDate: transformDateFromParams(params.inboundDate, getOutboundDate(params.outboundDate)),
    outboundDays: transformOutboundInboundDaysFromParams(params.outboundDays),
    inboundDays: transformOutboundInboundDaysFromParams(params.inboundDays),
    stopNumber: Number(params.stopNumber) ?? -1,
    limit: Number(params.limit),
    originRadius: Number(params.originRadius),
    passengers: getPassengers(params.passengers),
    showTkeys: params.showTkeys === 'true',
    times: transformTimes(params),
    price: params.price || [0, 0],
    maxDuration: params.maxDuration,
    returnFromDifferentAirport: params.returnFromDifferentAirport !== 'false',
    returnToDifferentAirport: params.returnToDifferentAirport !== 'false',
    ...transformCarriers(params.airlinesList, params.selectedAirlinesExclude),
    ...(defaults.mode ? { mode: defaults.mode } : {}),
    ui: params.ui,
  };
};

export const transformScriptParams = (script, defaults = {}) => {
  return {
    source: transformPlaceFromParams(script.from),
    destination: transformPlaceFromParams(script.to),
    outboundDate: getOutboundDate(script.departure, defaults.isDeeplink),
    inboundDate: transformDateFromParams(script.return, getOutboundDate(script.departure)),
    passengers: getPassengers(script.passengers),
    bags: script.bags,
    outboundDays: transformOutboundInboundDaysFromParams(script['outbound-days']).filter(
      day => day !== null,
    ),
    inboundDays: transformOutboundInboundDaysFromParams(script['inbound-days']).filter(
      day => day !== null,
    ),
    ...transformCarriers(script.airlinesList, script.selectedAirlinesExclude),
  };
};
