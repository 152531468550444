// @flow

import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { left, right } from '@kiwicom/orbit-components/lib/utils/rtl';

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionButtonWrapper = styled.div`
  margin-${right}: ${({ theme }) => theme.orbit.spaceXXSmall};

  ${media.largeMobile(css`
    margin-${right}: ${({ theme }) => theme.orbit.spaceXXSmall};
  `)};
`;

export const FooterLoyaltyPoints = styled.div`
  margin-top: ${({ theme, hasGuarantee }) =>
    hasGuarantee ? theme.orbit.spaceXXSmall : `-${theme.orbit.spaceXXSmall}`};
`;

export const NoCheckedBagIcon = styled.span`
  margin-${right}: ${({ theme }) => theme.orbit.spaceXSmall};
  line-height: 1;
`;

export const FooterWrapper = styled.div`
  flex-grow: 1;

  ${media.largeMobile(css`
    margin: ${({ theme }) => `-${theme.orbit.spaceXSmall} -${theme.orbit.spaceMedium}`};
  `)};
`;

export const FooterShare = styled.div`
  margin-bottom: ${({ theme }) => theme.orbit.spaceXXSmall};

  ${media.largeMobile(css`
    margin-bottom: ${({ theme }) => theme.orbit.spaceSmall};
  `)};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-${left}: -${({ theme }) => theme.orbit.spaceXSmall};

  ${media.largeMobile(css`
    margin-${left}: 0;
  `)};

  /* stylelint-disable-next-line */
  ${ActionButtonsWrapper} ${ActionButtonWrapper} {
    ${media.largeMobile(css`
      display: flex;
      margin-${right}: ${({ theme }) => theme.orbit.spaceXXSmall};
    `)};
  }
`;

export const FooterBookingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterPriceWrapper = styled.div`
  padding-${right}: ${({ theme }) => theme.orbit.spaceMedium};
  display: flex;
  align-items: center;
`;

export const FooterProviderWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.orbit.spaceXXXSmall};
`;

export const NoCheckedBag = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -${({ theme }) => theme.orbit.spaceLarge} -${({ theme }) => theme.orbit.spaceMedium}
    ${({ theme }) => theme.orbit.spaceLarge};
  padding: ${({ theme }) => theme.orbit.spaceXXSmall} ${({ theme }) => theme.orbit.spaceMedium};
  color: ${({ theme }) => theme.orbit.paletteOrangeNormal};
  font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};
  background: ${({ theme }) => theme.orbit.backgroundAlertWarning};

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: ${({ theme }) => theme.orbit.spaceXXXLarge};
      z-index: 3;
    `}

  ${media.largeMobile(css`
    margin: -${({ theme }) => theme.orbit.spaceXXLarge} -${({ theme }) => theme.orbit.spaceXXLarge}
      ${({ theme }) => theme.orbit.spaceXXLarge};
    ${({ isSticky }) =>
      isSticky &&
      css`
        top: 12px;

        /* Workaround for MS Edge
         * It doesn’t take the ModalBody’s padding into account so it needs to be added manually
         */
        @supports (display: -ms-grid) {
          top: 52px;
        }
      `}
  `)};
`;

export const dots = css`
  position: absolute;
  z-index: 1;
  ${left}: 6px;
  width: 4px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3E%3Ccircle cx='60' cy='60' r='30' fill='%23bac7d5'/%3E%3C/svg%3E");
  background-size: 4px 4px;
  content: '';
`;

export const line = css`
  position: absolute;
  z-index: 1;
  ${left}: 7px;
  width: 2px;
  background: #e8edf1;
  content: '';
`;

export const TripCell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TripFlightCell = styled(TripCell)`
  flex-direction: column;
  align-items: stretch;
  padding: ${({ theme }) => theme.orbit.spaceSmall} 0;
  padding-${right}: ${({ theme }) => theme.orbit.spaceSmall};

  &:before {
    top: 0;
    height: 100%;
  }

  p {
    margin: ${({ theme }) => theme.orbit.spaceSmall} 0;
  }

  svg {
    position: relative;
    z-index: 2;
  }
`;

export const TripDetail = styled.div`
  background: ${({ theme }) => theme.orbit.paletteWhite};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  box-shadow: ${({ theme }) => theme.orbit.boxShadowAction};
`;

export const TripDateText = styled.h3`
  display: inline;
  margin: 0;
  font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};

  & + p {
    margin-top: 2px;
  }
`;

export const TripPlaceText = styled(TripDateText).attrs({ as: 'h4' })``;

export const TripDetailTitle = styled.h4`
  margin: ${({ theme }) => `${theme.orbit.spaceMedium} 0 ${theme.orbit.spaceSmall}`};
  font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};
`;

export const TripCabinClass = styled(TripCell)`
  padding: ${({ theme }) => `${theme.orbit.spaceXXSmall} ${theme.orbit.spaceLarge}`};
  background: ${({ theme }) => theme.orbit.paletteBlueLight};
`;

export const TripSelectTimesCell = styled(TripFlightCell)`
  &:before {
    ${dots};
    ${({ carousel }) => carousel && 'height: 200%'};
  }
`;

export const MoreInfo = styled.button.attrs({
  type: 'button',
})`
  display: inline-flex;
  align-items: center;
  margin-${left}: ${({ theme }) => theme.orbit.spaceXSmall};
  padding: ${({ theme }) => theme.orbit.spaceXXSmall};
  color: inherit;
  font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
  font-size: inherit;
  text-decoration: ${({ theme }) => theme.orbit.textDecorationTextLinkPrimary};
  background: transparent;
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  cursor: pointer;

  &:focus {
    box-shadow: currentColor 0 0 0 3px;
  }
`;
