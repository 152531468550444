import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import possibleTypes from '../../data/possibleTypes.json';

const cache = new InMemoryCache({
  possibleTypes,
});

const constructClient = (urlParams, uri = 'https://api.skypicker.com/umbrella/v2/graphql') => {
  return new ApolloClient({
    link: ApolloLink.from([
      new HttpLink({
        uri: `${uri}?${urlParams}`,
        credentials: 'same-origin',
      //  headers: {  'KW-Widget-Umbrella-Token': process.env.REACT_APP_UMBRELLA_TOKEN, },
      }),
    ]),
    cache,
  });
};
export default constructClient;
