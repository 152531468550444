import { gql } from 'graphql.macro';

import * as fragments from '../../../../../graphql/fragments/index';

export default gql`
  query SearchOneWayItinerariesQuery_BasicWidget(
    $search: SearchOnewayInput
    $filter: ItinerariesFilterInput
    $options: ItinerariesOptionsInput
  ) {
    onewayItineraries(search: $search, filter: $filter, options: $options) {
      __typename
      ... on AppError {
        error: message
      }
      ... on Itineraries {
        server {
          requestId
          environment
          packageVersion
          serverToken
        }
        metadata {
          itinerariesCount
          hasMorePending
          missingProviders {
            code
          }
          stopoverCountries {
            name
          }
          prices {
            items {
              frequency
              label
            }
          }
          carriers {
            id
            code
            name
          }
          stations {
            code
            id
          }
          outboundArrivalTimes {
            frequency
            label
          }
          outboundDepartureTimes {
            frequency
            label
          }
          inboundArrivalTimes {
            frequency
            label
          }
          inboundDepartureTimes {
            frequency
            label
          }
          inboundDays
          outboundDays
          durations {
            frequency
            label
          }
          locationHashtags {
            label
            id
          }
          topResults {
            best {
              ...MetadataItinerariesTopResults
            }
            cheapest {
              ...MetadataItinerariesTopResults
            }
            fastest {
              ...MetadataItinerariesTopResults
            }
          }
          isRoutePromoted
        }
        itineraries {
          __typename
          ... on ItineraryOneWay {
            id
            price {
              amount
              formattedValue
              currency {
                id
                code
                format {
                  format
                }
              }
              priceBeforeDiscount
            }
            priceEur {
              amount
              formattedValue
              priceBeforeDiscount
            }
            provider {
              name
              code
              hasHighProbabilityOfPriceChange
              contentProvider {
                code
              }
              id
            }
            cabinClasses
            bagsInfo {
              includedCheckedBags
              includedHandBags
              hasBagsSupported
              checkedBagTiers {
                tierPrice {
                  amount
                }
                bags {
                  weight {
                    value
                  }
                }
              }
              handBagTiers {
                tierPrice {
                  amount
                }
                bags {
                  weight {
                    value
                  }
                }
              }
            }
            bookingOptions {
              edges {
                node {
                  token
                  bookingUrl
                  trackingPixel
                  itineraryProvider {
                    code
                    name
                    subprovider
                    hasHighProbabilityOfPriceChange
                    contentProvider {
                      code
                    }
                    id
                  }
                  price {
                    amount
                  }
                }
              }
            }
            sector {
              id
              sectorSegments {
                guarantee
                ...SectorSectorSegments
              }
              duration
            }
          }
          id
        }
      }
    }
  }

  ${fragments.MetadataItinerariesTopResults}
  ${fragments.SectorSectorSegments}
`;
