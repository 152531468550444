import styled from 'styled-components';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

export const VerticalCenter = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-${right}: 8px;
  }
`;
