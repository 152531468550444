/* global __ */
import React from 'react';
import InformationCircle from '@kiwicom/orbit-components/lib/icons/InformationCircle';

import  useTranslations  from '../../../services/translations/useTranslations';
import * as segmentUtils from '../../../utils/segment';

import { TripDetailTitle } from '../index.styled';

import { TripSegmentDetailsWrapper } from './TripSegmentDetails.styled';
import TripSegmentDetailsItem from './TripSegmentDetailsItem';
import TripSegmentDetailsCarrierLogo from './TripSegmentDetailsCarrierLogo';
import TripSegmentDetailsFlight from './TripSegmentDetailsFlight';

const CARRIER_TYPES = {
  FLIGHT: {
    typeKey: ('search.result.airline.label'),
    numberKey: ('search.result.flight_number.label'),
    pnrKey: ('common.pnr'),
  },
  TRAIN: {
    typeKey: ('search.result.carrier_colon.label'),
    numberKey: ('search.result.train_number.label'),
    pnrKey: ('common.pnr'),
  },
  BUS: {
    typeKey: ('search.result.carrier_colon.label'),
    numberKey: ('search.result.bus_number.label'),
    pnrKey: ('common.pnr'),
  },
};

const TripSegmentDetail = ({ segment, id }) => {
  const { translate } = useTranslations();
  // const openTrainHelp = useCallback(
  //   event => {
  //     event.stopPropagation()
  //     openHelpCenterArticle(articles.TRAINS_ARTICLE)
  //   },
  //   [openHelpCenterArticle],
  // )
  // const { vehicle } = part;
  // const partType = part.type;
  // // const carrier = PartUtils.getCarrier(part);
  const segmentNumber = segmentUtils.getSegmentNumber(segment);
  // const operatingAirline = PartUtils.getOperatingAirline(part);
  // const hasDifferentOperatingCarrier = PartUtils.hasDifferentOperatingCarrier(part);
  const shouldShowSegmentNumber = segment.type === 'FLIGHT' || segment.type === 'TRAIN';
  const hasDifferentOperatingCarrier = segment.operatingCarrier?.code !== segment.carrier.code;

  return (
    <TripSegmentDetailsWrapper id={id}>
      <TripDetailTitle>{translate(('search.result.connection_info.title'))}</TripDetailTitle>
      <ul>
        <TripSegmentDetailsItem
          icon={
            <TripSegmentDetailsCarrierLogo
              carrier={segment.carrier}
              size="small"
              type={segment.type === 'flight' ? 'airline' : segment.type}
            />
          }
          text={translate(CARRIER_TYPES[segment.type].typeKey)}
          value={segment.carrier.name}
          dataTest="itineraryLogo"
        />

        {hasDifferentOperatingCarrier && segment.operatingCarrier && (
          <TripSegmentDetailsItem
            icon={
              <TripSegmentDetailsCarrierLogo
                carrier={segment.operatingCarrier}
                size="small"
                type={segment.type === 'flight' ? 'airline' : segment.type}
              />
            }
            text="Operating carrier"
            value={segment.operatingCarrier.name}
            dataTest="itineraryOperatingLogo"
          />
        )}

        {shouldShowSegmentNumber && segment.code && (
          <TripSegmentDetailsItem
            icon={<InformationCircle size="small" />}
            dataTest="itineraryFlightNumber"
            text={translate(CARRIER_TYPES[segment.type].numberKey)}
            value={segmentNumber}
          />
        )}

        {/* {vehicle && vehicle.manufacturer && (
          <TripSegmentDetailsItem
            icon={<AirplaneUp size="small" />}
            text={vehicle.manufacturer}
            value={vehicle.model}
            dataTest="itineraryManufacturer"
          />
        )} */}

        {/* {(partType === PART_TYPES.TRAIN || partType === PART_TYPES.BUS) && isFAQEnabled && (
          <TripSegmentDetailsItem
            icon={<InformationCircle size="small" />}
            text={
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div tabIndex="-1" role="button" onClick={openTrainHelp}>
                <Translate t="common.more_about_trains_and_buses" />
              </div>
            }
            train
          />
        )} */}
      </ul>
      {segment.type === 'FLIGHT' && <TripSegmentDetailsFlight segment={segment} />}
    </TripSegmentDetailsWrapper>
  );
};

export default TripSegmentDetail;
