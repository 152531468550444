/* global __ */
import React, { useState, useContext, useEffect } from 'react';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import {SearchState}  from '../../../../../../services/filters/Search/SearchProvider';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

const StopsFilter = ({ asButton, noButtons = false, popoverActions }) => {
  const [open, setOpen] = useState(false);
  const {
    stopNumber,
    allowOvernightStopovers,
    changeStopNumber,
    toggleOvernightStopovers,
    getDefaultValues,
  } = useContext(SearchState);
  const { translate } = useTranslations();
  const { pushToDataLayer } = useGTM();

  const [stop, setStop] = useState(stopNumber);
  const [overnightStopovers, setOvernightStopovers] = useState(allowOvernightStopovers);

  useEffect(() => {
    setStop(stopNumber);
    setOvernightStopovers(allowOvernightStopovers);
  }, [stopNumber, allowOvernightStopovers]);

  const handleSubmit = () => {
    changeStopNumber(stop);
    if (overnightStopovers !== allowOvernightStopovers) {
      toggleOvernightStopovers();
    }
    setOpen(false);
  };

  const handleClose = () => {
    setStop(stopNumber);
    setOvernightStopovers(allowOvernightStopovers);
    setOpen(false);
  };

  const handleClear = () => {
    if (noButtons) {
      changeStopNumber(-1);
      if (overnightStopovers !== getDefaultValues('allowOvernightStopovers')) {
        toggleOvernightStopovers();
      }
    } else {
      setStop(getDefaultValues('stopNumber'));
      setOvernightStopovers(getDefaultValues('allowOvernightStopovers'));
    }
  };

  return (
    <FilterWrapper
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      label={translate(('search.filter.stops.title'))}
      asButton={asButton}
      width="260px"
      actions={
        stop !== -1 || overnightStopovers !== true ? (
          <TextLink
            size="small"
            type="secondary"
            onClick={() => {
              pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
              handleClear();
            }}
            dataTest="ClearFilter-stops"
          >
            {translate(('search.filter.multi_used.button.clear'))}
          </TextLink>
        ) : (
          popoverActions
        )
      }
    >
      {!noButtons && <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />}
      <Stack spacing="XSmall" spaceAfter="medium">
        <Radio
          label={translate(('search.filter.stops.options.any'))}
          checked={stop === -1}
          onChange={() => {
            pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
            noButtons ? changeStopNumber(-1) : setStop(-1);
          }}
        />
        <Radio
          label={translate(('search.filter.stops.options.none'))}
          checked={stop === 0}
          onChange={() => {
            pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
            noButtons ? changeStopNumber(0) : setStop(0);
          }}
        />
        <Radio
          label={translate(('search.filter.stops.options.one'))}
          checked={stop === 1}
          onChange={() => {
            pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
            noButtons ? changeStopNumber(1) : setStop(1);
          }}
        />
        <Radio
          label={translate(('search.filter.stops.options.two'))}
          checked={stop === 2}
          onChange={() => {
            pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
            noButtons ? changeStopNumber(2) : setStop(2);
          }}
        />
      </Stack>

      <Checkbox
        label={translate(('search.filter.stops.allow_overnight_stopovers'))}
        checked={overnightStopovers}
        onChange={() =>
          noButtons ? toggleOvernightStopovers() : setOvernightStopovers(!overnightStopovers)
        }
      />
    </FilterWrapper>
  );
};

export default StopsFilter;
