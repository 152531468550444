import { mapSegment } from './segment';

export const mapOneWayItinerary = itinerary => {
  return {
    __typename: itinerary.__typename,
    id: itinerary.id,
    price: {
      amount: itinerary.price.amount,
      priceBeforeDiscount: itinerary.price.priceBeforeDiscount,
      formattedValue: itinerary.price.formattedValue,
      currency: {
        code: itinerary.price?.currency?.code,
        format: itinerary.price?.currency?.format.format,
      },
    },
    priceEur: { amount: itinerary?.priceEur?.amount },
    provider: itinerary.provider,
    cabinClasses: itinerary.cabinClasses,
    bagsInfo: itinerary.bagsInfo,
    bookingOptions: itinerary.bookingOptions?.edges.map(edge => edge.node),
    duration: itinerary.sector.duration,
    segments: itinerary.sector.sectorSegments.map(mapSegment),
    sector: {
      id: itinerary.sector.id,
    },
    trips: [
      {
        id: itinerary.sector.id,
        duration: itinerary.sector.duration,
        segments: itinerary.sector.sectorSegments.map(mapSegment),
      },
    ],
  };
};

export const mapReturnItinerary = itinerary => {
  return {
    __typename: itinerary.__typename,
    id: itinerary.id,
    bagsInfo: itinerary.bagsInfo,
    provider: itinerary.provider,
    bookingOptions: itinerary.bookingOptions?.edges.map(edge => edge.node),
    cabinClasses: itinerary.cabinClasses,
    price: {
      amount: itinerary.price.amount,
      priceBeforeDiscount: itinerary.price.priceBeforeDiscount,
      formattedValue: itinerary.price.formattedValue,
      currency: {
        code: itinerary.price?.currency?.code,
        format: itinerary.price?.currency?.format.format,
      },
    },
    priceEur: { amount: itinerary?.priceEur?.amount },
    inbound: {
      id: itinerary.inbound.id,
      segments: itinerary.inbound.sectorSegments.map(mapSegment),
    },
    outbound: {
      id: itinerary.outbound.id,
      segments: itinerary.outbound.sectorSegments.map(mapSegment),
    },
    trips: [
      {
        id: itinerary.outbound.id,
        duration: itinerary.outbound.duration,
        segments: itinerary.outbound.sectorSegments.map(mapSegment),
      },
      {
        id: itinerary.inbound.id,
        duration: itinerary.inbound.duration,
        segments: itinerary.inbound.sectorSegments.map(mapSegment),
      },
    ],
  };
};

export const mapItinerary = itinerary => {
  if (itinerary === undefined) {
    return null;
  }

  switch (itinerary.__typename) {
    case 'ItineraryOneWay':
      return mapOneWayItinerary(itinerary);
    case 'ItineraryReturn':
      return mapReturnItinerary(itinerary);
    default:
      throw new Error('Unknown itinerary type');
  }
};
