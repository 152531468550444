/* global  */

import __ from "ramda/src/__";

export const SORT_BY_VALUES = {
  best: 'QUALITY',
  price: 'PRICE',
  duration: 'DURATION',
};
/*
export const SORT_BY_TRANSLATIONS = {
  best: ('search.result.sorting.best'),
  price: ('search.result.sorting.cheapest'),
  duration: ('search.result.sorting.shortest'),

};*/

export const SORT_BY_TRANSLATIONS = {
  "best": "search.result.sorting.best",
    "price": "search.result.sorting.cheapest",
    "duration": "search.result.sorting.shortest"
}
export const SORT_BY_AGGREGATED_VALUES = {
  POPULARITY: 'POPULARITY',
  PRICE: 'PRICE',
};

export const SORT_PARAM_TO_AGGREGATED = {
  price: SORT_BY_AGGREGATED_VALUES.PRICE,
  best: SORT_BY_AGGREGATED_VALUES.POPULARITY,
  duration: SORT_BY_AGGREGATED_VALUES.POPULARITY,
};
