/* global __ */
import React from 'react';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/client';

import PLACES_QUERY from '../../../graphql/queries/places.graphql';
import { mapPlaceEdgeToPlace, injectVirtualPlaces } from '../../../utils/place';
import  useSearchFormState  from '../../../scenes/Basic/services/SearchForm/useSearchForm';
import  useTranslations  from '../../../services/translations/useTranslations';
import  useGeoData  from '../../../services/geo/useGeoData';
import PopoverActions from '../../../common/PopoverActions';

import ResultsSection from './ResultsSection';

const ge2lat=(str)=>{
  ['ა','ბ','გ','დ','ე','ვ','ზ','თ','ი','კ','ლ','მ','ნ','ო','პ','ჟ','რ','ს','ტ','უ','ფ','ქ','ღ','ყ','შ', 'ჩ', 'ც','ძ','წ','ჭ', 'ხ','ჯ','ჰ'].forEach((d,i)=>{
    str= str.replaceAll(d,['a','b','g','d','e','v','z','t','i','k','l','m','n','o','p','j','r','s','t','u','f','k','g','kv','sh','ch','c','z','w','ch','x','j','h'][i])
  });
  return str;
}
const Spacer = styled.div`
  padding-bottom: ${({ theme }) => theme.orbit.spaceSmall};
`;

const PlacesWrapper = styled.div`
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transform-origin: 32px 32px 0px;
  transition: opacity 250ms ease, transform 250ms ease;
  pointer-events: none;
  visibility: hidden;
  display: none;

  ${({ active }) =>
    active &&
    css`
      display: block;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: scale3d(1, 1, 1);
    `}
`;

const Query = ({
  active,
  searchTerm,
  direction,
  values,
  onClose,
  handleAddPlace,
  handleRemovePlace,
  handleSubmit,
}) => {
  const { isPlaceSelected } = useSearchFormState();
  const { closestCity } = useGeoData();
  const { translate, lang } = useTranslations();
  searchTerm=ge2lat(searchTerm);
  const res = useQuery(PLACES_QUERY, {
    variables: {
      search: {
        term: searchTerm,
        ...(!searchTerm && {
          alternatives: {
            id: values[0]?.id,
          },
        }),
      },
      options: {
        locale: lang,
      },
      first: 20,
      // filter: {
      //   // onlyTypes: [
      //   //   'AIRPORT',
      //   //   'COUNTRY',
      //   //   'CITY',
      //   //   'TRAIN_STATION',
      //   //   'AUTONOMOUS_TERRITORY',
      //   //   'REGION',
      //   //   'CONTINENT',
      //   //   'BUS_STATION',
      //   //   'TOURIST_REGION',
      //   // ],
      // },
    },
  });

  const selectedPlacesMap = values.reduce((xs, x) => {
    xs[x.id] = true;

    return xs;
  }, {});

  const mappedPlaces = res.data?.places?.edges
    .map(mapPlaceEdgeToPlace)
    .filter(e => !selectedPlacesMap[e.code]);

  const places = injectVirtualPlaces(mappedPlaces || [], searchTerm, direction, translate).filter(
    p => !isPlaceSelected(p, direction),
  );

  return (
    <>
      {active && <Spacer />}
      <PlacesWrapper active={active}>
        {active && (
          <>
            <PopoverActions onClose={onClose} onSubmit={handleSubmit} />
            {values.length !== 0 && searchTerm.trim().length === 0 && (
              <ResultsSection
                title={translate(('search.form.already_included'))}
                places={values}
                direction={direction}
                onRemove={handleRemovePlace}
                selected
              />
            )}

            <ResultsSection
              title={translate(('form_search.places_nearby_one'), {
                first: values?.[0]?.name || closestCity.data.name,
              })}
              places={
                values.filter(p => p.name === '').length === 1
                  ? places.filter(p => p.name !== '')
                  : places
              }
              direction={direction}
              onAdd={handleAddPlace}
            />
          </>
        )}
      </PlacesWrapper>
    </>
  );
};

export default React.memo(Query);
