import { createGlobalStyle } from 'styled-components';
//import theme  from '../styles/theme';

const theme = require('../styles/theme').default;
export default createGlobalStyle
    `
  * {
    box-sizing: border-box;
  }

  html {
    min-height: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    margin: 0;
    font-family: ${theme.orbit.fontFamily};

    color: ${theme.orbit.colorTextPrimary};
    background-color: ${theme.orbit.backgroundBody};
    letter-spacing: 0.02em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button {
    display: block;
    padding: 0;
    border: 0;

    &:focus {
      outline: 0;
    }
  }

  p {
    margin: 0;
    line-height: 20px;
  }

  ul {
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
    box-shadow: none;

    & li {
      display: block;
      margin: 0;
      padding: 0;
      width: auto;
      background: none;
      border: 0;
    }
  }

  input {
    line-height: normal;
  }

  html,
  body,
  input,
  textarea,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-size: 14px;
    font-weight: normal;
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  label {
    display: block;
    line-height: 20px;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }

  .longPrice {
    font-size: 12px;
  }

  .font-bold {
    font-weight: 700;
  }
`;
