import { DEVICE_NAME } from '../consts/device';

export const getViewportName = device => {
  if (device.isLargeDesktop) {
    return DEVICE_NAME.LARGE_DESKTOP;
  } else if (device.isDesktop) {
    return DEVICE_NAME.DESKTOP;
  } else if (device.isTablet) {
    return DEVICE_NAME.TABLET;
  } else if (device.isLargeMobile) {
    return DEVICE_NAME.LARGE_MOBILE;
  }

  return DEVICE_NAME.MOBILE;
};
