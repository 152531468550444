import styled, { css } from 'styled-components';
import { left } from '@kiwicom/orbit-components/lib/utils/rtl';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const TripPlace = styled.span`
  margin-${left}: ${({ theme }) => theme.orbit.spaceSmall};

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      margin-${left}: ${({ theme }) => theme.orbit.spaceXSmall};
    `)};
`;

export const TripPlaceDetails = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.orbit.spaceXXXSmall};
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};
  line-height: ${({ theme }) => theme.orbit.lineHeightText};

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      display: inline;
      margin-top: 0;
      margin-${left}: ${({ theme }) => theme.orbit.spaceXXSmall};
      font-size: ${({ theme }) => theme.orbit.fontSizeTextNormal};
    `)};
`;

export const SemiBold = styled.strong`
  font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
`;
