import React from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';

import { CABIN_CLASS_TYPE_TRANSLATIONS } from '../../../consts/cabinClass';

import { TripCabinClass } from '../index.styled';

import { TripCabinClassInfo } from './CabinClassInfo.styled';
import useTranslations from "src/services/translations/useTranslations";

const CabinClassInfo = ({ segment }) => {
  const { translate } = useTranslations();

  if (!segment.cabinClass) {
    return null;
  }

  return (
    <>
      <TripCabinClass />
      <TripCabinClassInfo>
        <Text type="info" size="small">
          {translate(CABIN_CLASS_TYPE_TRANSLATIONS[segment.cabinClass])}
        </Text>
      </TripCabinClassInfo>
    </>
  );
};

export default CabinClassInfo;
