import React from 'react';
import Translate from '@kiwicom/nitro/lib/components/Translate/index';

import { convertPrice } from '../../utils/price';
import  useCurrency  from '../../services/currencies/useCurrency';
import useCurrencyData from '../../services/currencies/useCurrencyData';


const Price = props => {
  const {
    formatThousandsAndDecimals = true,
    children,
    prefix = '',
    convert = false,
    alreadyRounded = false,
    maxSize = 0,
    noBreak = false,
    tKey,
    highlight,
  } = props;
  const { formatPrice, roundAmount } = useCurrency();
  const { currencies, activeCurrencyCode } = useCurrencyData();
  let { className = '' } = props;

  // eslint-disable-next-line react/destructuring-assignment
  const currency = props.currency || activeCurrencyCode;
  let amountInEur;
  let amountInCurrency;

  const amount = parseFloat(children);

  if (isNaN(amount)) {
    return null;
  }

  if (convert) {
    amountInEur = amount;
    amountInCurrency = convertPrice(
      amountInEur,
      currencies.find(c => c.code === currency?.toLowerCase?.()),
    );
  } else if (alreadyRounded) {
    amountInCurrency = amount;
  } else {
    amountInCurrency = parseFloat(roundAmount(amount, currency));
  }

  let output = formatPrice(currency, amountInCurrency, formatThousandsAndDecimals);

  if (maxSize > 0) {
    const roundedPrice = amountInCurrency.toFixed(0);
    output = formatPrice(currency, roundedPrice);

    if (!props.showCurrencySymbol) {
      // If still too long, remove currency symbol
      if (`${output}`.length > maxSize || props.hideCurrencySymbol) {
        output = roundedPrice;

        if (`${output}`.length > maxSize) {
          // If still too long, round to thousands
          output = parseFloat(roundedPrice) / 1000;

          if (Math.round(output) !== output) {
            output = output.toFixed(1);
          }

          output = `${output}k`;
        }

        if (`${output}`.length >= maxSize) {
          // If too big, (just as maxSize or bigger), add class to notify about that
          className += ' longPrice';
        }
      }
    }
  }

  output = prefix + output;
  className += ` length-${output.length}`;

  if (highlight) {
    className += ' font-bold';
  }

  if (tKey) {
    return (
      <span className={className}>
        <Translate
          t={tKey}
          values={{
            price: output,
          }}
        />
      </span>
    );
  }

  output = noBreak ? output.replace(/ /g, '\u00a0') : output;
  return <span className={className}>{output}</span>;
};

export default Price;
