/* global __ */
import React, { useContext } from 'react';
import styled from 'styled-components';
import Mobile from '@kiwicom/orbit-components/lib/Mobile';
import Desktop from '@kiwicom/orbit-components/lib/Desktop';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

import {SearchState}  from '../../../../../../services/filters/Search/SearchProvider';

import SortByDesktop from './SortByDesktop';
import SortByMobile from './SortByMobile';

export const VerticalCenter = styled.span`
  display: flex;
  align-items: center;

  svg {
    margin-${right}: 8px;
  }
`;

const OPTIONS = {
  POPULARITY: ('search.result.sorting.popularity'),
  PRICE: ('search.result.sorting.cheapest'),
};

const SortBy = () => {
  const { aggregatedSortBy, changeAggregatedSortBy } = useContext(SearchState);

  return (
    <>
      <Desktop>
        <SortByDesktop
          sortBy={aggregatedSortBy}
          changeSortBy={changeAggregatedSortBy}
          options={OPTIONS}
        />
      </Desktop>
      <Mobile>
        <SortByMobile
          sortBy={aggregatedSortBy}
          changeSortBy={changeAggregatedSortBy}
          options={OPTIONS}
        />
      </Mobile>
    </>
  );
};

export default SortBy;
