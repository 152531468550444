import React from 'react';
import styled from 'styled-components';

import { formatTripDuration } from '../../utils/datetime';

const TimeWrapper = styled.time`
  font-size: 14px;
  font-weight: ${({ highlight }) => (highlight ? 700 : 400)};
`;

const TripTime = ({ duration, highlight, className }) => {
  return (
    <TimeWrapper highlight={highlight} className={className}>
      {formatTripDuration(duration)}
    </TimeWrapper>
  );
};

export default TripTime;
