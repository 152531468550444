export const rejectNil = object => {
  const res = {};

  // eslint-disable-next-line guard-for-in
  for (const key in object) {
    const el = object[key];

    if (el !== null && el !== undefined) {
      res[key] = el;
    }
  }

  return res;
};
