import React from 'react';
import styled from 'styled-components';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import { rtlSpacing } from '@kiwicom/orbit-components/lib/utils/rtl';

import TripTime from '../../common/TripTime';

const StyledTripTime = styled(TripTime)`
  font-size: 12px;
  font-weight: 500;
`;

const BadgeWrapper = styled.div`
  margin: ${({ theme }) =>
    rtlSpacing(`0 ${theme.orbit.spaceXXSmall} ${theme.orbit.spaceXXSmall} 0`)};
`;

const ItineraryTimeBadge = ({ duration }) => {
  return (
    <BadgeWrapper>
      <Badge type="info">
        <StyledTripTime duration={duration} />
      </Badge>
    </BadgeWrapper>
  );
};

export default ItineraryTimeBadge;
