import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { left } from '@kiwicom/orbit-components/lib/utils/rtl';

export const FormContainer = styled.div`
  display: block;
  max-width: 1400px;
  width: 100%;
  min-width: 250px;
  margin: 0 auto;
  padding: 16px;

  & > button {
    width: 100%;
  }

  ${media.desktop(css`
    padding: 0 40px 10px;
  `)}

  ${media.largeMobile(css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  `)}

  ${media.largeDesktop(css`
    flex-wrap: nowrap;
    flex: 1 1 0%;
    padding-bottom: 0px;

    & > button {
      margin-${left}: 8px;
      width: auto;
    }
  `)}
`;

export const FormWrapper = styled.div`
  display: block;
  width: 100%;

  ${media.largeMobile(css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  `)}
`;
