const MAX_CABIN_BAGS_PER_PERSON = 1;
const MAX_CHECKED_BAGS_PER_PERSON = 2;

export const getHandBags = (adults, children, cabinBags) => {
  const childrenHandBags = Array(children).fill(0);
  const adultsHandBags = Array(adults).fill(0);
  let remainingBags = cabinBags;
  let childrenWithBags = 0;
  let adultsWithBags = 0;

  // 1 cabin bag per passenger
  while (remainingBags > 0) {
    if (adultsWithBags < adults) {
      adultsHandBags[adultsWithBags] += 1;
      adultsWithBags++;
    } else {
      childrenHandBags[childrenWithBags] += 1;
      childrenWithBags++;
    }
    remainingBags--;
  }

  return { adultsHandBags, childrenHandBags };
};

export const getHoldBags = (adults, children, checkedBags) => {
  const adultsHoldBags = Array(adults).fill(0);
  const childrenHoldBags = Array(children).fill(0);
  let remainingBags = checkedBags;

  const passengers = adults + children;
  let childrenWithBags = 0;
  let adultsWithBags = 0;

  // First add one bag to each passenger then increase starting from adults
  while (remainingBags > 0) {
    if (adultsWithBags + childrenWithBags === passengers) {
      adultsWithBags = 0;
      childrenWithBags = 0;
    }
    if (adultsWithBags < adults) {
      adultsHoldBags[adultsWithBags] += 1;
      adultsWithBags++;
    } else {
      childrenHoldBags[childrenWithBags] += 1;
      childrenWithBags++;
    }
    remainingBags--;
  }

  return { adultsHoldBags, childrenHoldBags };
};

// distribute bags evenly across number of pax
const distributeBags = (bagsArray, bags, paxCount, checkedBags) => {
  let bagCount = bags;
  while (bagCount > 0) {
    for (let paxNum = 0; paxNum < paxCount; paxNum++) {
      if (bagCount === 0) {
        break;
      }
      if (!bagsArray[paxNum]) {
        bagsArray[paxNum] = [0, 0];
      }

      if (checkedBags) {
        if (bagsArray[paxNum][1] === MAX_CHECKED_BAGS_PER_PERSON) {
          continue;
        }
        bagsArray[paxNum][1]++;
      } else {
        if (bagsArray[paxNum][0] === MAX_CABIN_BAGS_PER_PERSON) {
          continue;
        }
        bagsArray[paxNum][0]++;
      }
      bagCount--;
    }
  }
};

export const getQueryBags = (checkedBags, cabinBags, paxCount, adultsCount) => {
  if (checkedBags === 0 && cabinBags === 0) {
    return undefined;
  }
  const bagsArray = [];
  distributeBags(bagsArray, checkedBags, paxCount, true);
  distributeBags(bagsArray, cabinBags, paxCount, false);
  const formattedBagsArray = bagsArray.map(bags => bags.join('.'));
  const adults = formattedBagsArray.slice(0, adultsCount);
  const children = formattedBagsArray.slice(adultsCount, bagsArray.length);

  return children.length ? `${adults.join('_')}-${children.join('_')}` : adults.join('_');
};

export const getArrayBags = queryBags => {
  if (!queryBags) {
    return [0, 0];
  }
  const [adults, children] = queryBags.split('-');
  const adultBags = adults ? adults.split('_') : [];
  const childBags = children ? children.split('_') : [];
  let handBags = 0;
  let holdBags = 0;
  for (const human of adultBags.concat(childBags)) {
    const [hand, hold] = human.split('.');
    handBags += Number(hand);
    holdBags += Number(hold);
  }

  return [handBags, holdBags];
};
