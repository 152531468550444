import styled from 'styled-components';

import { line, TripCabinClass } from '../index.styled';

export const TripCabinClassInfo = styled(TripCabinClass)`
  &:before {
    ${line};
    top: 0;
    height: 100%;
    background: ${({ theme }) => theme.orbit.paletteBlueLightHover};
  }
`;
