/* global __ */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';

import  useSearchParams  from '../../services/filters/Search/useSearchParams';
import { useGTM } from '../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../consts/tracking';
import  useTranslations  from '../../services/translations/useTranslations';
import useViewport from '../../scenes/Basic/services/Viewport/useViewport';
import { usePostMessages } from '../../services/postMessages';
import { useDeeplinkSyncProvider } from '../../services/deeplinkSync';
import PopoverActions from '../../common/PopoverActions';

const CheckboxWrapper = styled.div`
  margin-top: 8px;
`;

const RadioWrapper = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const MixedClassesWrapper = styled.div`
  margin: 16px 16px 0;
`;

const ClassWrapper = styled.div`
  padding: 16px;
`;

const options = [
  {
    label: ('search.form.cabin_class.economy'),
    value: 'ECONOMY',
  },
  {
    label: ('search.form.cabin_class.premium'),
    value: 'PREMIUM_ECONOMY',
  },
  {
    label: ('search.form.cabin_class.business'),
    value: 'BUSINESS',
  },
  {
    label: ('search.form.cabin_class.first'),
    value: 'FIRST_CLASS',
  },
];

const ClassFilter = ({ popoverActions }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { cabinClass, changeCabinClass, applyMixedClasses } = useSearchParams();
  const [cabinDraft, setCabinDraft] = useState(cabinClass);
  const [mixedClassesDraft, setMixedClassesDraft] = useState(applyMixedClasses);
  const { translate } = useTranslations();
  const { isMobile } = useViewport();
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const { pushToDataLayer } = useGTM();
  const [contentHeight, setContentHeight] = useState(false);
  const [, deeplinkDispatch] = useDeeplinkSyncProvider();

  useEffect(() => {
    setCabinDraft(cabinClass);
  }, [cabinClass]);

  const handleOpenPopover = () => {
    if (isMobile) {
      setContentHeight(window.innerHeight);
      postIframeHeight(600);
      lockResizing();
    }
    setPopoverOpen(true);
  };
  const handleClosePopover = () => {
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeight, true);
    }
    setPopoverOpen(false);
    setCabinDraft(cabinClass);
    setMixedClassesDraft(applyMixedClasses);
  };

  const handleSubmit = () => {
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeight, true);
    }
    changeCabinClass(cabinDraft, mixedClassesDraft);
    deeplinkDispatch({ type: 'SET_PARAM', key: 'cabinClass', value: cabinDraft });
    pushToDataLayer(GTM_EVENTS.SEARCH_FILTER, {
      cabinClass: cabinDraft,
      applyMixedClasses: mixedClassesDraft,
    });
    setPopoverOpen(false);
  };

  return (
    <Popover
      dataTest="CabinClassPopover"
      opened={popoverOpen}
      onOpen={handleOpenPopover}
      onClose={handleClosePopover}
      overlapped
      noPadding
      fixed
      actions={popoverActions}
      content={
        <ClassWrapper>
          <PopoverActions onClose={handleClosePopover} onSubmit={handleSubmit} />
          {options.map(o => (
            <RadioWrapper
              key={o.value}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setCabinDraft(o.value);
              }}
            >
              <Radio
                checked={cabinDraft === o.value}
                onChange={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                label={translate(o.label)}
                value={o.value}
              />
            </RadioWrapper>
          ))}
          <MixedClassesWrapper>
            <Text weight="bold">{translate(('search.form.cabin_class.save_money'))}</Text>
            <CheckboxWrapper>
              <Checkbox
                label={translate(('search.form.cabin_class.apply_mixed'))}
                checked={mixedClassesDraft}
                onChange={() => setMixedClassesDraft(m => !m)}
              />
            </CheckboxWrapper>
          </MixedClassesWrapper>
        </ClassWrapper>
      }
    >
      <ButtonLink
        dataTest="CabinClassField"
        size="small"
        type="secondary"
        iconRight={<ChevronDownIcon />}
      >
        {translate(options.find(o => o.value === cabinClass).label)}
      </ButtonLink>
    </Popover>
  );
};

export default ClassFilter;
