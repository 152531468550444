import { DEVICE_NAME } from '../consts/device';

import { randomFactory } from './rng';

const LAYOUT_INFO = {
  [DEVICE_NAME.LARGE_DESKTOP]: {
    long: 40,
    short: 20,
    maxCols: 5,
  },
  [DEVICE_NAME.DESKTOP]: {
    long: 50,
    short: 25,
    maxCols: 4,
  },
  [DEVICE_NAME.TABLET]: {
    long: 66.666,
    short: 33.333,
    maxCols: 3,
  },
  [DEVICE_NAME.LARGE_MOBILE]: {
    long: 50,
    short: 50,
    maxCols: 2,
  },
  [DEVICE_NAME.MOBILE]: {
    long: 100,
    short: 100,
    maxCols: 1,
  },
};

/**
 * Calculate masonry layout for aggregated results
 * @param {object} viewportName useMediaQuery result from utils/device.js > getViewportName
 * @param {*} resultCount Number of results
 */
export const calculateLayout = (viewportName, resultCount) => {
  const layoutInfo = LAYOUT_INFO[viewportName];
  const randomSeeds = [1, 2, 3, 5, 7, 11];
  const result = [];

  let currWidth = 0;
  let rowI = 0;

  const seed = randomSeeds[Math.floor(Math.random() * randomSeeds.length)];
  const customRandom = randomFactory(seed);

  for (let i = 0; i < resultCount; i++) {
    if (!result[rowI]) {
      result.push([]);
    }

    const currentSize = customRandom() < 0.5 ? 'long' : 'short';
    let size = layoutInfo[currentSize];

    if (currWidth + size >= 99.999) {
      size = layoutInfo.short;
    }

    currWidth += size;
    result[rowI].push({ width: size, itineraryIndex: i });
    if (currWidth >= 99.999) {
      currWidth = 0;
      rowI++;
    }
  }

  return result;
};
