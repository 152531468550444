import styled, { css } from 'styled-components';
import { left } from '@kiwicom/orbit-components/lib/utils/rtl';

export const ItemWrapper = styled.li`
  margin: ${({ theme }) => theme.orbit.spaceXSmall} 0;
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};
  color: ${({ theme }) => theme.orbit.colorTextSecondary};

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;

          strong {
            text-decoration: underline;
            font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
            color: ${({ theme }) => theme.orbit.colorTextPrimary};
          }

          &:hover strong {
            color: ${({ theme }) => theme.orbit.colorTextLinkPrimaryHover};
          }
        `
      : css`
          strong {
            font-weight: ${({ theme }) => theme.orbit.fontWeightNormal};
          }
        `}
`;

export const ItemIcon = styled.span`
  float: ${left};
`;

export const ItemText = styled.div`
  margin-${left}: ${({ theme }) => theme.orbit.spaceMedium};
  padding-${left}: ${({ theme }) => theme.orbit.spaceXSmall};

  h4 {
    margin: 0;
    font-size: inherit;
    font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
    color: inherit;
  }
`;
