import React, { useState, useEffect } from 'react';

import languages from '../../data/languages.json';
import useEntryParameters from '../entryParameters/useEntryParameters';

import { locales } from '../../consts/locales';
import  useGeoData  from '../geo/useGeoData';
import { getLocale } from '../../utils/locale';
import { useDeeplinkSyncProvider } from '../../services/deeplinkSync';

export const TranslationState = React.createContext({});

const DEFAULT_LANGUAGE = 'ka';

const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState(null);
  const [locale, setLocale] = useState(null);
  const entryParams = useEntryParameters();
  const [lang, setLang] = useState(entryParams.lang);
  const { geoData } = useGeoData();
  const [, deeplinkDispatch] = useDeeplinkSyncProvider();

  useEffect(() => {
    if (geoData.loading || translations) {
      return;
    }
    const langCode = getLocale(entryParams.lang || geoData.data?.isoCountryCode?.toLowerCase());
   ;
    const language = languages[langCode] || languages[DEFAULT_LANGUAGE];

    Promise.all([
      fetch(`/static/translations/${language.phraseApp}.json`).then(r => r.json()),
    //  fetch(`/static/translations/ka-GE.json`).then(r => r.json()),
      locales[langCode](),
    ]).then(([fetchedTranslations, fetchedLocale]) => {
      setTranslations(fetchedTranslations);
      setLocale(fetchedLocale.default);
      setLang(langCode);
    });
    // update deeplinkSyncState with correct language
    deeplinkDispatch({ type: 'SET_PARAM', key: 'lang', value: lang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, geoData.data]);

  const localeData = languages[lang?.split('-')?.[0]];

  const translate = (tkey, values = {}) => {
    if (entryParams.showTkeys) {
      return tkey;
    }

    return (
      Object.keys(values).reduce(
        (xs, x) => xs.replace(`__${x}__`, values[x]),
        translations[tkey || ''],
      ) || tkey
    );
  };

  if (!lang) {
    return null;
  }

  const value = {
    translations,
    lang,
    locale,
    localeData,
    translate,
  };

  return <TranslationState.Provider value={value}>{children}</TranslationState.Provider>;
};

export default TranslationProvider;
