import styled, { css } from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  position: relative;

  ${({ hasActions }) =>
    hasActions &&
    css`
      justify-content: space-between;
    `}

  & > div:first-of-type {
    display: flex;
  }
`;

const getLineSize = ({ lineSize }) => {
  switch (lineSize) {
    case 'small':
      return 2;
    case 'medium':
    default:
      return 4;
  }
};

export const TabContainer = styled.div`
  transition: border-bottom 100ms ease-in-out;
  border-bottom: ${getLineSize}px solid
    ${({ theme, active }) =>
      active ? theme.orbit.backgroundButtonPrimary : theme.orbit.paletteCloudDark};
`;

export const TabButton = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: ${({ theme }) => theme.orbit.paddingButtonNormal};
  color: ${({ theme, transparent }) =>
    transparent ? theme.orbit.colorTextSecondary : theme.orbit.colorTextButtonLinkSecondary};
  font-size: ${({ theme }) => theme.orbit.fontSizeButtonSmall};
  cursor: pointer;
  background-color: transparent;
  transition: background-color 200ms ease-in-out;

  ${({ active }) =>
    active &&
    css`
      font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
      background-color: ${({ theme, transparent }) =>
        transparent ? 'transparent' : theme.orbit.backgroundButtonSecondary};
      letter-spacing: -0.15px;
    `}
`;

export const ActionsWrapper = styled.div`
  display: flex;

  & > button {
    margin-left: 8px;
  }
`;
