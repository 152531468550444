/* global __ */
import React, { useMemo, useContext, useCallback, useLayoutEffect } from 'react';
import PictureCard from '@kiwicom/orbit-components/lib/PictureCard';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Text from '@kiwicom/orbit-components/lib/Text';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import HorizontalScroll from '@kiwicom/orbit-components/lib/HorizontalScroll';
import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';
import FlightDirectIcon from '@kiwicom/orbit-components/lib/icons/FlightDirect';

import {SearchState}  from '../../../../../../services/filters/Search/SearchProvider';
import  useSearchFormState  from '../../../../../../scenes/Basic/services/SearchForm/useSearchForm';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import useEntryParameters from '../../../../../../services/entryParameters/useEntryParameters';
import { calculateLayout } from '../../../../../../utils/layout';
import { getViewportName } from '../../../../../../utils/device';
import { mapPlaceEdgeToPlace } from '../../../../../../utils/place';
import { PLACE_TYPES } from '../../../../../../consts/place';
import { getHashtagTranslation } from '../../../../../../utils/hashtags';
import TranslateNode from '../../../../../../common/TranslateNode';
import Timestamp from '../../../../../../common/Timestamp';
import TransportFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/TransportFilter';
import StopsFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/StopsFilter';
import PriceFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/PriceFilter';
import DaysFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/DaysFilter';
import MoreFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/MoreFilter';
import SortByAggregated from '../../../../../../scenes/Basic/scenes/SearchResults/components/SortByAggregated';
import LocationHashtagsFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/LocationHashtagsFilter';
import  useAggregatedSearch  from '../../../../../../scenes/Basic/services/SearchActions/useAggregatedSearch';
import NoResults from '../../../../../../common/NoResults';
import PictureCardPlaceholder from '../../../../../../common/PictureCardPlaceholder';
import useViewport from '../../../../../../scenes/Basic/services/Viewport/useViewport';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import  useCurrency  from '../../../../../../services/currencies/useCurrency';
import useCurrencyData from '../../../../../../services/currencies/useCurrencyData';

import {
  Container,
  FiltersWrapper,
  FiltersButtonWrapper,
  RowWrapper,
  TileWrapper,
  ScrollWrapper,
  PriceValue,
  PriceBeforeDiscountValue,
  PriceDiscountedValue,
} from './index.styled';

const MobileFilterWrapper = props => {
  return (
    <ScrollWrapper>
      <HorizontalScroll showArrows>{props.children}</HorizontalScroll>
    </ScrollWrapper>
  );
};

const AggregatedSearchResults = () => {
  const { results, loadResults } = useAggregatedSearch();
  const { aggregatedSortBy } = useContext(SearchState);
  const { addSearchPlace } = useSearchFormState();
  const searchForm = useSearchFormState();
  const { translate } = useTranslations();
  const entryParameters = useEntryParameters();
  const { isMobile, isNotDesktop } = useViewport();
  const { pushToDataLayer } = useGTM();
  const viewportInfo = useMediaQuery();
  const { formatPrice } = useCurrency();
  const { activeCurrencyCode } = useCurrencyData();

  const viewportName = getViewportName(viewportInfo);
  const hasAffilidError = results.data?.error?.includes('Unknown partner provided.');

  useLayoutEffect(() => {
    loadResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layout = useMemo(() => {
    if (!results.data) {
      return calculateLayout(viewportName, 16);
    }
    return calculateLayout(viewportName, results.data?.itineraries?.length);
  }, [results.data, viewportName]);

  const showFilters = searchForm.destination.length !== 0 || !entryParameters.resultsOnly;

  const FilterWrapperComponent = isNotDesktop ? MobileFilterWrapper : FiltersButtonWrapper;
  const popoverActions = isMobile ? <></> : undefined;
  const Filters = useCallback(() => {
    return (
      <FiltersWrapper>
        <FilterWrapperComponent>
          <LocationHashtagsFilter
            asButton
            hashtags={results?.data?.metadata?.locationHashtags || []}
            popoverActions={popoverActions}
          />
          <StopsFilter asButton popoverActions={popoverActions} />
          <TransportFilter asButton popoverActions={popoverActions} />
          <DaysFilter asButton popoverActions={popoverActions} />
          <PriceFilter
            asButton
            prices={results?.data?.metadata?.prices?.items || []}
            popoverActions={popoverActions}
          />
          <MoreFilter asButton popoverActions={popoverActions} />
        </FilterWrapperComponent>
        <SortByAggregated />
      </FiltersWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const Results = useCallback(() => {
    return (
      <>
        <Heading type="title2" spaceAfter="small">
          {translate(
            aggregatedSortBy === 'POPULARITY'
              ? ('search.route_cards.trending_destinations.title')
              : ('search.route_cards.travel_bargains'),
          )}
        </Heading>
        {aggregatedSortBy === 'PRICE' && (
          <Text spaceAfter="large">{translate(('search.route_cards.travel_bargains_desc'))}</Text>
        )}
        {layout.map((row, rowI) => {
          return (
            <RowWrapper key={rowI}>
              {row.map((tile, i) => {
                const itinerary = results.data.itineraries[tile.itineraryIndex];

                return (
                  <TileWrapper
                    key={i}
                    width={tile.width}
                    data-test={`TrendingDestTile-${tile.itineraryIndex}`}
                  >
                    <PictureCard
                      label={translate(
                        getHashtagTranslation(itinerary?.locationHashtags?.[0]?.label),
                      )}
                      image={{
                        original: '385x320',
                        code: itinerary.destination.station.city.legacyId,
                        name: itinerary.destination.station.city.name,
                        placeholder: '385x320',
                      }}
                      title={itinerary.destination.station.city.name}
                      onClick={() => {
                        const { station } = itinerary.destination;
                        const mappedPlace = {
                          node: {
                            __typename: 'City',
                            legacyId: station.city.legacyId,
                            name: station.city.name,
                            id: station.city.legacyId,
                            slug: station.city.legacyId,
                            country: { ...station.country, id: station.country.legacyId },
                            type: PLACE_TYPES.CITY,
                            city: { id: station.city.legacyId, name: station.city.name },
                            parentIds: [
                              station.city.legacyId,
                              station.country.legacyId,
                              station.country.region.legacyId,
                              station.country.region.continent.legacyId,
                            ],
                          },
                        };

                        pushToDataLayer(GTM_EVENTS.SEARCH_TILE, {
                          ...mappedPlace,
                          position: i,
                          total: results.data.itineraries.length,
                        });
                        addSearchPlace(mapPlaceEdgeToPlace(mappedPlace), 'destination');
                      }}
                    >
                      {searchForm.mode === 'return' ? (

                        <TranslateNode
                          tkey={('search.tiles.nights_from_price')}
                          values={{
                            count: itinerary.nightsCount,
                            price:
                              itinerary.price.amount !== itinerary.price.priceBeforeDiscount ? (
                                <>
                                  <PriceBeforeDiscountValue>
                                    {formatPrice(
                                      activeCurrencyCode,
                                      parseFloat(itinerary.price.priceBeforeDiscount).toFixed(0),
                                    )}
                                  </PriceBeforeDiscountValue>
                                  <FlightDirectIcon medium="large" color="secondary" />
                                  <PriceDiscountedValue>
                                    {itinerary.price.formattedValue}
                                  </PriceDiscountedValue>
                                </>
                              ) : (
                                <PriceValue>{itinerary.price.formattedValue}</PriceValue>
                              ),
                          }}
                        />
                      ) : (
                        <TranslateNode
                          tkey={('search.tiles.date_from_price')}
                          values={{
                            date: (
                              <Timestamp
                                formatKeys="dateFormat"
                                value={new Date(itinerary.destination.localTime)}
                                isLocal
                              />
                            ),
                            price:
                              itinerary.price.amount !== itinerary.price.priceBeforeDiscount ? (
                                <>
                                  <PriceBeforeDiscountValue>
                                    {formatPrice(
                                      activeCurrencyCode,
                                      parseFloat(itinerary.price.priceBeforeDiscount).toFixed(0),
                                    )}
                                  </PriceBeforeDiscountValue>
                                  <FlightDirectIcon medium="large" />
                                  <PriceDiscountedValue>
                                    {itinerary.price.formattedValue}
                                  </PriceDiscountedValue>
                                </>
                              ) : (
                                <PriceValue>{itinerary.price.formattedValue}</PriceValue>
                              ),
                          }}
                        />
                      )}
                    </PictureCard>
                  </TileWrapper>
                );
              })}
            </RowWrapper>
          );
        })}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  return (
    <Container>
      {showFilters && <Filters />}
      {results.loading ? (
        <PictureCardPlaceholder layout={layout} />
      ) : (
        <>
          {results.data?.itineraries?.length ? (
            <Results />
          ) : (
            <NoResults invalidAffilid={hasAffilidError} />
          )}
        </>
      )}
    </Container>
  );
};

export default AggregatedSearchResults;
