import styled from 'styled-components';
import { left } from '@kiwicom/orbit-components/lib/utils/rtl';

export const TripSegmentDetailsItemWrapper = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.orbit.spaceXSmall};
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};

  ${({ train, theme }) =>
    train &&
    `
      color: ${theme.orbit.colorTextLinkPrimary};
      text-decoration: underline;
    `};

  &:hover {
    ${({ train, theme }) => train && `color: ${theme.orbit.colorTextLinkPrimaryHover}`};
  }
`;

export const TripSegmentDetailsItemText = styled.div`
  margin-${left}: ${({ theme }) => theme.orbit.spaceXSmall};
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
`;

export const TripSegmentDetailsItemValue = styled.div`
  margin-${left}: auto;
`;
