export const PLACE_MODES = {
  ANYWHERE: 'anywhere',
  RADIUS: 'radius',
  PLACE: 'place',
  AS_PICK_UP: 'as_pick_up',
};

export const PLACE_VALUE_MODES = {
  TEXT: 'text',
  PLACE: 'place',
  RADIUS: 'radius',
  ANYWHERE: PLACE_MODES.ANYWHERE,
  ID: 'id',
  UNSELECTED: 'unselected',
  MULTI_PLACE: 'multiPlace',
  AS_PICK_UP: 'as_pick_up',
};

export const PLACE_TYPES = {
  AIRPORT: 0,
  COUNTRY: 1,
  CITY: 2,
  TRAIN_STATION: 4,
  AUTONOMOUS_TERRITORY: 3,
  REGION: 5,
  CONTINENT: 6,
  BUS_STATION: 7,
  SPECIAL: 8,
  TOURIST_REGION: 9,
  VIRTUAL: 999,
  RADIUS: 'radius', // TODO: unify
};

export const AMBIGUOUS_PLACE_TYPES = {
  [PLACE_TYPES.REGION]: true,
  [PLACE_TYPES.CONTINENT]: true,
  [PLACE_TYPES.VIRTUAL]: true,
};

export const PLACES_TO_OMIT = {
  origin: ['anywhere'],
  destination: [],
};

export const PLACE_MODE_KEYS = {
  [PLACE_MODES.ANYWHERE]: 'form_search.anywhere',
  [PLACE_MODES.RADIUS]: 'form_search.radius_search',
  [PLACE_MODES.AS_PICK_UP]: 'form_search.as_pick_up',
};
