import React, { useReducer, useCallback, useEffect } from 'react';

import { adjustForQuery } from '../../../../utils/datetime';
import { AMBIGUOUS_PLACE_TYPES } from '../../../../consts/place';
import  useGeoData  from '../../../../services/geo/useGeoData';
import useEntryParameters from '../../../../services/entryParameters/useEntryParameters';
import { useDeeplinkSyncProvider } from '../../../../services/deeplinkSync/';

export const SearchFormState = React.createContext({});
export const SearchFormDispatch = React.createContext({});

const addPlaceToState = (state, place) => {
  if (AMBIGUOUS_PLACE_TYPES[place.type]) {
    return state.concat(place);
  }

  return state.filter(p => !AMBIGUOUS_PLACE_TYPES[p.type]).concat(place);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INITIAL_PLACES':
      return {
        ...state,
        source: action.source,
        ...(action.destination ? { destination: action.destination } : {}),
      };
    case 'ADD_SEARCH_PLACE':
      return {
        ...state,
        [action.direction]: addPlaceToState(state[action.direction], action.place),
      };
    case 'REMOVE_SEARCH_PLACE':
      return {
        ...state,
        [action.direction]: state[action.direction].filter(p => p.slug !== action.place.slug),
      };
    case 'SET_LATEST_SEARCH':
      return {
        ...state,
        latestSearchSnapshot: {
          source: state.source,
          destination: state.destination,
          outboundDate: state.outboundDate,
          inboundDate: state.inboundDate,
        },
      };
    case 'SET_MODE':
      return {
        ...state,
        mode: action.mode,
      };
    case 'ON_SOURCE_CHANGE':
      return {
        ...state,
        source: action.places,
      };
    case 'ON_DESTINATION_CHANGE':
      return {
        ...state,
        destination: action.places,
      };
    case 'ON_OUTBOUND_DATE_CHANGE':
      return {
        ...state,
        outboundDate: action.dateTime,
      };
    case 'ON_INBOUND_DATE_CHANGE':
      return {
        ...state,
        inboundDate: action.dateTime,
      };
    case 'SET_INITIAL_DATES':
      return {
        ...state,
        outboundDate: action.outboundDate,
        inboundDate: action.inboundDate,
        mode: action.mode,
      };
    case 'RESET_DATE':
      return {
        ...state,
        inboundDate: { start: [], end: [] },
      };
    default:
      return state;
  }
};

const initialState = {
  mode: 'one-way',
  source: [],
  destination: [],
  outboundDate: 'anytime',
  inboundDate: 'anytime',

  latestSearchSnapshot: {
    source: [],
    destination: [],
    outboundDate: { start: [], end: [] },
    inboundDate: { start: [], end: [] },
  },
};

const SearchFormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const entryParams = useEntryParameters();
  const [, deeplinkDispatch] = useDeeplinkSyncProvider();
  const { closestCity } = useGeoData();

  useEffect(() => {
    if (!entryParams.source.length) {
      dispatch({
        type: 'SET_INITIAL_PLACES',
        source: [].concat(closestCity.data || []),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closestCity]);
  useEffect(() => {
    dispatch({
      type: 'SET_INITIAL_PLACES',
      source: entryParams.source.length ? entryParams.source : [].concat(closestCity.data || []),
      destination: entryParams.destination,
    });
    dispatch({
      type: 'SET_INITIAL_DATES',
      outboundDate:
        entryParams.outboundDate || entryParams.outboundDate?.start
          ? adjustForQuery(entryParams.outboundDate)
          : initialState.outboundDate,
      inboundDate:
        entryParams.inboundDate || entryParams.inboundDate?.start
          ? adjustForQuery(entryParams.inboundDate)
          : initialState.inboundDate,
      mode: entryParams.mode || (entryParams.inboundDate ? 'return' : 'one-way'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryParams]);

  useEffect(() => {
    deeplinkDispatch({
      type: 'ASSIGN',
      value: {
        source: state.source,
        destination: state.destination,
        mode: state.mode,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.source, state.destination, state.mode]);

  const addSearchPlace = useCallback((place, direction) => {
    dispatch({ type: 'ADD_SEARCH_PLACE', direction, place });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeSearchPlace = useCallback((place, direction) => {
    dispatch({ type: 'REMOVE_SEARCH_PLACE', direction, place });
  }, []);

  const onSourceChange = useCallback(places => {
    dispatch({ type: 'ON_SOURCE_CHANGE', places });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDestinationChange = useCallback(places => {
    dispatch({ type: 'ON_DESTINATION_CHANGE', places });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOutboundDateChange = useCallback(dateTime => {
    dispatch({ type: 'ON_OUTBOUND_DATE_CHANGE', dateTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInboundDateChange = useCallback(dateTime => {
    dispatch({ type: 'SET_MODE', mode: 'return' });
    dispatch({ type: 'ON_INBOUND_DATE_CHANGE', dateTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPlaceSelected = useCallback(
    (place, direction) => {
      return state[direction].find(p => p.slug === place.slug);
    },
    [state],
  );

  const setLatestSearch = useCallback(() => {
    dispatch({ type: 'SET_LATEST_SEARCH' });
  }, []);

  const setSearchMode = useCallback(mode => {
    dispatch({ type: 'SET_MODE', mode });
    if (mode === 'one-way') {
      dispatch({ type: 'RESET_DATE' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetLocations = useCallback(() => {
    dispatch({
      type: 'SET_INITIAL_PLACES',
      source: entryParams.source.length ? entryParams.source : [].concat(closestCity.data || []),
      destination: entryParams.destination,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closestCity.data]);

  const value = {
    ...state,
    addSearchPlace,
    removeSearchPlace,
    isPlaceSelected,
    setSearchMode,
    onSourceChange,
    onDestinationChange,
    onOutboundDateChange,
    onInboundDateChange,
    resetLocations,
  };

  return (
    <SearchFormDispatch.Provider value={{ dispatch, setLatestSearch }}>
      <SearchFormState.Provider value={value}>{children}</SearchFormState.Provider>
    </SearchFormDispatch.Provider>
  );
};

export default SearchFormProvider;
