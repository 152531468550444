import styled from 'styled-components';
import { right, rtlSpacing } from '@kiwicom/orbit-components/lib/utils/rtl';

import { dots, TripCell } from '../index.styled';

export const TripWrapper = styled.div`
  h2 {
    margin: ${({ theme, isFirst }) => (isFirst ? '0' : theme.orbit.spaceXXLarge)} -${({ theme }) =>
        theme.orbit.spaceXXLarge} ${({ theme }) => theme.orbit.spaceMedium};
    padding: 0 ${({ theme }) => theme.orbit.spaceXXLarge} ${({ theme }) => theme.orbit.spaceMedium}
      ${({ theme }) => theme.orbit.spaceXXLarge};
    border-bottom: 1px solid ${({ theme }) => theme.orbit.borderColorModal};
  }
`;

export const TripDate = styled(TripCell)`
  padding: ${({ theme }) => rtlSpacing(`0 ${theme.orbit.spaceSmall} ${theme.orbit.spaceLarge} 0`)};

  &:last-of-type:before {
    ${dots};
    top: 50%;
    height: 50%;
  }

  svg {
    margin-${/* sc-custom "right" */ right}: ${({ theme }) => theme.orbit.spaceXSmall};
  }
`;

export const TripArrival = styled(TripCell)`
  padding: ${({ theme }) => theme.orbit.spaceLarge} 0 ${({ theme }) => theme.orbit.spaceSmall};

  &:nth-child(2):before {
    ${dots};
    top: 0;
    height: 50%;
  }
`;

export const TripAlert = styled(TripCell)`
  padding: ${({ theme }) => theme.orbit.spaceSmall} 0;

  svg {
    position: relative;
    z-index: 2;
    margin-${right}: ${({ theme }) => theme.orbit.spaceXSmall};
    background-color: ${({ theme }) => theme.orbit.paletteCloudLight};
  }

  &:nth-child(2):before {
    ${dots};
    top: 0;
    height: 100%;
  }
`;
