import React from 'react';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Separator from '@kiwicom/orbit-components/lib/Separator';

import  useTranslations  from '../../services/translations/useTranslations';
import {__} from "ramda";

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const PopoverActions = ({ onClose, onSubmit, spaceAfter }) => {
  const { translate } = useTranslations();

  return (
    <>
      <ActionsWrapper>
        <Button size="small" type="secondary" onClick={onClose}>
          {translate(('search.form.multi_used.button.cancel'))}
        </Button>
        <Button size="small" onClick={onSubmit}>
          {translate(('search.form.multi_used.button.done'))}
        </Button>
      </ActionsWrapper>
      <Separator spaceAfter={spaceAfter || 'normal'} />
    </>
  );
};

export default PopoverActions;
