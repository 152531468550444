import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
//import getCurrencies from '@kiwicom/nitro/lib/services/server/getCurrencies';
import getCurrencies from '@kiwicom/nitro/lib/services/currency/getAll';


import COUNTRIES from '../../data/countries.json';
import  useTranslations  from '../../services/translations/useTranslations';
import useEntryParameters from '../../services/entryParameters/useEntryParameters';
import { useDeeplinkSyncProvider } from '../../services/deeplinkSync';
import { number } from '../../utils/formatters';
import  useGeoData  from '../../services/geo/useGeoData';

export const CurrenciesState = React.createContext({});
export const CurrenciesDataState = React.createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_CURRENCIES':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_CURRENCIES_SUCCESS':
      return {
        ...state,
        loading: false,
        currencies: action.currencies,
        activeCurrency: action.currencies.find(
          curr => curr.code.toLowerCase() === state.activeCurrencyCode.toLowerCase(),
        ),
      };
    case 'FETCH_CURRENCIES_FAIL':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case 'CHANGE_CURRENCY':
      return {
        ...state,
        activeCurrencyCode: action.code,
        activeCurrency: state.currencies
          ? state.currencies.find(curr => curr.code.toLowerCase() === action.code.toLowerCase())
          : {
              format: '__price__ €',
            },
      };
    default:
      return state;
  }
};

const initialState = {
  loading: true,
  currencies: null,
  error: null,
  activeCurrencyCode: 'eur',
};

const CurrenciesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { localeData } = useTranslations();
  const entryParams = useEntryParameters();
  const [, deeplinkDispatch] = useDeeplinkSyncProvider();
  const { geoData } = useGeoData();

  useEffect(() => {
    const currency =
      entryParams.currency || COUNTRIES[geoData.data?.isoCountryCode?.toLowerCase()]?.currency;
    if (currency && state.activeCurrencyCode !== currency) {
      dispatch({ type: 'CHANGE_CURRENCY', code: currency });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoData, entryParams.currency]);

  useEffect(() => {
    if (!state.currencies?.length) {
      return;
    }

    const validCurrency = state.currencies.some(
      curr => curr.code.toLowerCase() === state.activeCurrencyCode?.toLowerCase(),
    );

    if (!validCurrency) {
      dispatch({ type: 'CHANGE_CURRENCY', code: 'EUR' });
    }
  }, [state.currencies, state.activeCurrencyCode]);

  useEffect(() => {
    dispatch({ type: 'FETCH_CURRENCIES' });

    getCurrencies()
      .then(response => {
        const data = Object.values(response).map(curr => ({
          code: curr.id,
          format: curr.format,
          name: curr.name,
          symbol: curr.format.replace('__price__', '').trim(),
          rate: curr.rate,
          precision: curr.precision,
        }));

        dispatch({
          type: 'FETCH_CURRENCIES_SUCCESS',
          currencies: data,
        });
      })
      .catch(error => {
        dispatch({ type: 'FETCH_CURRENCIES_FAIL', error });
      });
  }, []);

  // this is used to set deeplinkSyncState to correctly sync deeplink form with preview both ways
  useEffect(() => {
    deeplinkDispatch({
      type: 'SET_PARAM',
      key: 'currency',
      value: state.activeCurrencyCode.toUpperCase(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deeplinkDispatch, state.activeCurrencyCode]);

  const changeCurrency = useCallback(code => {
    dispatch({ type: 'CHANGE_CURRENCY', code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const priceFormat = state.activeCurrency?.format;

  const getPriceFromValue = useCallback(
    value => {
      if (priceFormat) {
        return priceFormat.replace('__price__', value);
      }
      return () => {};
    },
    [priceFormat],
  );

  const roundAmount = useCallback(
    (amount, currencyCode) => {
      const currencyData = state.currencies.find(
        curr => curr.code.toLowerCase() === currencyCode.toLowerCase(),
      );
      const precision = currencyData ? Number(currencyData.precision) : 2;
      return amount.toFixed(precision);
    },
    [state.currencies],
  );

  const formatPrice = useCallback(
    (currencyCode, price, formatThousandsAndDecimals) => {
      const floatPrice = parseFloat(price);

      const currencyData = state.currencies.find(
        curr => curr.code.toLowerCase() === currencyCode.toLowerCase(),
      );

      const finalPrice =
        Math.round(floatPrice) !== floatPrice
          ? roundAmount(floatPrice, currencyCode)
          : String(floatPrice);

      if (formatThousandsAndDecimals) {
        price = number(localeData, price);
      }

      return currencyData.format.replace('__price__', finalPrice);
    },
    [localeData, roundAmount, state.currencies],
  );

  const value = useMemo(
    () => ({
      ...state,
      changeCurrency,
      getPriceFromValue,
      formatPrice,
      roundAmount,
    }),
    [changeCurrency, getPriceFromValue, formatPrice, roundAmount, state],
  );

  const currencies = state.currencies?.join('-');

  const currencyDataValue = useMemo(
    () => state,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.loading, currencies, state.error, state.activeCurrencyCode],
  );

  return (
    <CurrenciesDataState.Provider value={currencyDataValue}>
      <CurrenciesState.Provider value={value}>{children}</CurrenciesState.Provider>
    </CurrenciesDataState.Provider>
  );
};

export default React.memo(CurrenciesProvider);
