import React, { useContext, useReducer } from 'react';

import useEntryParameters from '../../services/entryParameters/useEntryParameters';

const DeeplinkSyncState = React.createContext({});

const getTouchedFields = array => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item]: true,
    };
  }, initialValue);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PARAM':
      return {
        ...state,
        [action.key]: action.value,
        touched: { ...state.touched, [action.key]: true },
      };
    case 'ASSIGN':
      const keys = Object.keys(action.value);
      return {
        ...state,
        ...action.value,
        ...(keys.includes('source') ? { touched: getTouchedFields(keys) } : {}), // stupid way to check if locations are appended
      };
    default:
      return state;
  }
};

const DeeplinkSyncProvider = ({ children }) => {
  const entryParams = useEntryParameters();
  const stateDispatch = useReducer(reducer, { ...entryParams, touched: {} });

  return <DeeplinkSyncState.Provider value={stateDispatch}>{children}</DeeplinkSyncState.Provider>;
};

export const useDeeplinkSyncProvider = () => {
  return useContext(DeeplinkSyncState);
};

export default DeeplinkSyncProvider;
