import React from 'react';
import styled, { css } from 'styled-components';
import ChevronLeftIcon from '@kiwicom/orbit-components/lib/icons/ChevronBackward';
import ChevronRightIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

const getDisabledProps = () => {
  return css`
    background: ${({ theme }) => theme.orbit.borderColorCard};
    cursor: not-allowed;
    opacity: 0.3;
  `;
};

const Base = styled.button`
  position: absolute;

  ${({ direction }) => (direction === 'back' ? 'left: 1.5em' : 'right: 1.5em')};

  background: ${({ theme }) => theme.orbit.borderColorCard};
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  align-items: center;
  height: 32px;
  width: 32px;
  max-width: 100%;
  border-radius: 3px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
  cursor: pointer;
  transition: all 0.15s ease-in-out !important;
  outline: 0;

  &:hover {
    background: ${({ theme }) => theme.orbit.backgroundButtonLinkPrimaryHover};
  }

  &:active {
    background: ${({ theme }) => theme.orbit.backgroundButtonLinkPrimaryActive};
  }

  ${({ disabled }) => disabled && getDisabledProps()}
`;

const Icon = ({ direction }) => {
  if (direction === 'back') {
    return <ChevronLeftIcon size="small" />;
  }

  return <ChevronRightIcon size="small" />;
};

const NavButton = ({ direction, disabled, onClick }) => {
  return (
    <Base direction={direction} onClick={onClick} disabled={disabled}>
      <Icon direction={direction} />
    </Base>
  );
};

NavButton.defaultProps = {
  direction: 'back',
};

export default NavButton;
