import { useMemo } from 'react';
import qs from 'query-string';

import { getQueryBags } from '../../utils/bags';
import usePaxAndBags from '../../services/filters/PassengersAndBags/usePaxAndBags';
import useEntryParameters from '../../services/entryParameters/useEntryParameters';
import { AFFILIATE_NETWORK_URLS } from '../../consts/affiliateNetworks';
import  useTranslations  from '../../services/translations/useTranslations';

export const useBookingDeeplink = itinerary => {
  const paxAndBags = usePaxAndBags();
  const {
    affilid,
    affiliateNetwork,
    pid,
    sid,
    brand,
    sub1,
    sub2,
    sub3,
    sub4,
    sub5,
    isWordpress,
    UTMWidgetType,
  } = useEntryParameters();
  const { lang } = useTranslations();

  const paxWithBags = paxAndBags.adults + paxAndBags.children;
  const queryBags = useMemo(() => {
    return getQueryBags(
      paxAndBags.checkedBags,
      paxAndBags.cabinBags,
      paxWithBags,
      paxAndBags.adults,
    );
  }, [paxAndBags.checkedBags, paxAndBags.cabinBags, paxWithBags, paxAndBags.adults]);

  const bookingOptions = {};
  for (const option of itinerary.bookingOptions) {
    const bookingDeeplink = {
      passengers: `${paxAndBags.adults}-${paxAndBags.children}-${paxAndBags.infants}`,
      price: itinerary.price.amount,
      currency: itinerary.price.currency.code,
      lang,
      booking_token: option.token,
      affilid: sub2 ? `${affilid}_${sub2}` : affilid,
      brand,
      sub1,
      sub2,
      sub3,
      sub4,
      sub5,
      searchBags: queryBags,
      utm_source: 'tequila',
      utm_medium: 'widget',
      utm_campaign: UTMWidgetType,
      ...(isWordpress && {
        utm_source: 'wpplugin',
        utm_medium: 'widget',
        utm_campaign: 'widgets-wp-plugin',
      }),
    };
    bookingOptions[option.itineraryProvider.code] = `https://www.kiwi.com/deep?${qs.stringify(
      bookingDeeplink,
    )}`;
  }

  if (!affiliateNetwork) {
    return bookingOptions;
  }

  // TODO: Check if other providers will be supported on widget
  return {
    KIWI: AFFILIATE_NETWORK_URLS[affiliateNetwork](bookingOptions.KIWI, { pid, sid }),
  };
};
