import React from 'react';

import { Button, Date, PriceTag, Td, DayWrapper } from './CalendarDay.styled';

const CalendarDay = ({
  isHidden,
  number,
  value,
  isPast,
  isFuture,
  isSelected,
  isDisabled,
  isHovered,
  isFirst,
  isLast,
  price,
  isFocused,
  selectDate,
  onHover,
  isSelecting,
}) => {
  const handleClick = () => {
    if (!isHidden) {
      selectDate(value);
    }
  };

  const handleHover = () => {
    if (!isHidden) {
      onHover(value);
    }
  };

  return (
    <Td>
      <Button
        data-test={`calendar-day-${number}`}
        onClick={handleClick}
        onMouseOver={handleHover}
        onFocus={handleHover}
        disabled={isPast || isFuture || isDisabled}
        isSelected={isSelected}
        isFirst={isFirst}
        isLast={isLast}
        isHidden={isHidden}
        isPast={isPast}
        isFuture={isFuture}
        isHovered={isHovered}
        isFocused={isFocused}
        number={number}
        isSelecting={isSelecting}
      >
        <DayWrapper>
          <Date>{number}</Date>
          {price && <PriceTag>{price}</PriceTag>}
        </DayWrapper>
      </Button>
    </Td>
  );
};

const dayPropsAreEqual = (prevDay, nextDay) =>
  prevDay.isHovered === nextDay.isHovered &&
  prevDay.isSelected === nextDay.isSelected &&
  prevDay.isFocused === nextDay.isFocused &&
  prevDay.isLast === nextDay.isLast &&
  prevDay.isFirst === nextDay.isFirst;

export default React.memo(CalendarDay, dayPropsAreEqual);
