import styled, { css, keyframes } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { left, right, rtlSpacing } from '@kiwicom/orbit-components/lib/utils/rtl';

const closeDetailAnimation = keyframes`
  40% {
    transform: scale(0.99);
  }

  100% {
    transform: scale(1);
  }
`;

const DetailsButton = styled.div``;

const ResultCardWrapperCommon = styled.div`
  margin-bottom: ${({ theme }) => theme.orbit.spaceSmall};
  background: ${({ theme }) => theme.orbit.backgroundCard};
  border-radius: ${({ theme }) => theme.orbit.borderRadiusNormal};
  outline: 0;
  box-shadow: ${({ theme }) => theme.orbit.boxShadowAction};
  transition: box-shadow ${({ theme }) => theme.orbit.durationFast} ease-in-out;

  ${props => props.clickable && !props['aria-expanded'] && 'cursor: pointer'};

  ${media.tablet(css`
    margin-bottom: ${({ theme }) => theme.orbit.spaceMedium};
  `)};

  &:hover,
  &.with-shadow {
    box-shadow: ${({ theme }) => theme.orbit.boxShadowActionActive};
  }

  ${DetailsButton} {
    pointer-events: none;
  }

  ${({ closed }) =>
    closed &&
    css`
      animation: ${closeDetailAnimation} 0.45s ease-out;
    `}
`;

// const Discount = styled.span`
//   color: ${({ theme }) => theme.orbit.colorTextSecondary};
//   font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
//   font-size: ${({ theme }) => theme.orbit.fontSizeTextNormal};
//   text-decoration: line-through;
// `;

// const DiscountedPrice = styled.span`
//   color: ${({ theme }) => theme.orbit.paletteProductNormal};
//   font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
//   ${({ itinerary, theme }) => itinerary && `font-size: ${theme.orbit.fontSizeTextLarge}`}
// `;

export const DetailsButtonMobile = styled(DetailsButton)`
  margin-right: 8px;

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      display: none;
    `)};
`;

export const DetailsButtonLargeMobile = styled(DetailsButton)`
  display: none;

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      display: block;
      margin-${left}: auto;
      padding-${left}: ${({ theme }) => theme.orbit.spaceXXSmall};
      white-space: nowrap;
    `)};
`;

export const ResultCardWrapper = styled(ResultCardWrapperCommon)`
  position: relative;
  font-size: ${({ theme }) => theme.orbit.fontSizeTextNormal};

  &:hover {
    /* stylelint-disable-next-line */
    ${DetailsButtonMobile} > div {
      background-color: ${({ theme }) => theme.orbit.backgroundButtonSecondaryHover};
    }

    /* stylelint-disable-next-line */
    ${DetailsButtonLargeMobile} > div {
      background-color: ${({ theme }) => theme.orbit.backgroundButtonLinkSecondaryHover};
    }
  }

  &:focus {
    /* stylelint-disable */
    ${DetailsButtonMobile} > div,
    ${DetailsButtonLargeMobile} > div {
      box-shadow: rgba(95, 115, 140, 0.5) 0 0 0 3px;
    }
    /* stylelint-enable */
  }

  ${({ unseen }) =>
    unseen &&
    css`
      outline: rgba(12, 179, 199, 0.5) solid 1px;
    `}

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      display: flex;
      flex-direction: column;
    `)};

  /* Override Orbit */
  /* stylelint-disable-next-line */
  ${DetailsButtonLargeMobile} > div:last-child {
    margin-bottom: 0 !important;
  }
`;

export const ResultCardPriceSectionActionButtons = styled.div`
  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      position: absolute;
      top: ${({ theme }) => theme.orbit.spaceXXSmall};
      ${right}: 0;
    `)};
`;

export const ResultCardMain = styled.div`
  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      border-${right}: ${({ theme }) =>
      `${theme.orbit.borderWidthCard} ${theme.orbit.borderStyleCard} ${theme.orbit.borderColorCard}`};
      width: 65%;
    `)};
`;

export const ResultCardSection = styled.div`
  padding: ${({ theme }) => theme.orbit.spaceSmall};

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      padding: ${({ theme }) => theme.orbit.spaceMedium};
    `)};
`;

export const ResultCardActions = styled(ResultCardSection)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      flex: 1;
    `)};
`;

export const ResultCardActionsPriceMeta = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.orbit.spaceSmall};

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    `)};
`;

export const ResultCardActionsPrice = styled.div`
  display: flex;
  align-items: center;
`;

export const PriceText = styled.strong`
  display: flex;
  align-items: baseline;
  margin-${left}: ${({ theme }) => theme.orbit.spaceXXSmall};
  font-weight: ${({ theme }) => theme.orbit.fontWeightBold};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextLarge};
  text-align: center;

  ${media.largeMobile(css`
    display: inline;
  `)};

  & > p {
    margin-${right}: ${({ theme }) => theme.orbit.spaceXXSmall};

    ${media.largeMobile(css`
      margin-bottom: ${({ theme }) => theme.orbit.spaceXSmall};
      margin-${right}: 0;
    `)};
  }

  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      margin-bottom: ${({ theme }) => theme.orbit.spaceXXSmall};
      margin-${left}: 0;
      font-size: ${({ theme }) => theme.orbit.fontSizeHeadingTitle2};
    `)};
`;

export const PriceBeforeDiscountValue = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
  white-space: nowrap;
  text-decoration: line-through;
  color: #697D95;

  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 700;
  }
`;

export const PriceDiscountedValue = styled.div`
  font-size: 22px;
  font-weight: 500;
  white-space: nowrap;
  color: #00A991;
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

// export const ResultCardLoyaltyPoints = styled.div`
//   align-self: center;
// `;

export const ResultCardInner = styled.div`
  ${({ narrowView }) =>
    !narrowView &&
    media.largeMobile(css`
      display: flex;
      width: 100%;
    `)}
`;

// export const Discount = styled(StyledDiscount)``;

// export const DiscountedPrice = styled(StyledDiscountedPrice)``;

export const BadgeWrapper = styled.div`
  margin: ${({ theme }) =>
    rtlSpacing(`0 ${theme.orbit.spaceXXSmall} ${theme.orbit.spaceXXSmall} 0`)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
