import React from 'react';

import { ItemWrapper, ItemIcon, ItemText } from './ItineraryTripLayoverItem.styled';

const ItineraryTripLayoverItem = ({ text, icon, onClick }) => (
  <ItemWrapper onClick={onClick}>
    {icon && <ItemIcon>{icon}</ItemIcon>}
    <ItemText>{text}</ItemText>
  </ItemWrapper>
);

export default ItineraryTripLayoverItem;
