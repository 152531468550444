import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

export const Container = styled.div`
  margin-top: 12px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 24px;

  ${media.desktop(css`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

export const FiltersButtonWrapper = styled.div`
  display: flex;

  & > div {
    margin-${right}: 4px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
`;

export const TileWrapper = styled.div`
  ${({ height }) => height && `height: ${height}px`};
  box-sizing: border-box;
  ${({ mapTile }) => mapTile && 'height: 508px'};
  ${({ placeholder }) => placeholder && 'height: 250px'};
  width: 100%;
  ${({ mapLayout, theme }) => !mapLayout && `padding-bottom: ${theme.orbit.spaceSmall}`};

  ${({ mapTile }) =>
    mapTile &&
    css`
      display: none;

      ${media.tablet(css`
        display: block;
      `)};
    `};

  ${media.mediumMobile(css`
    width: 100%;

    @media (max-width: 767px) {
      margin-${right}: ${({ theme }) => theme.orbit.spaceSmall};
      &:last-of-type {
        margin-${right}: 0;
      }
    }
  `)};

  ${media.tablet(css`
    ${({ mapLayout, theme, width }) =>
      mapLayout
        ? 'width: 100%'
        : css`
      ${width && `width: ${width}%`};
      padding-bottom: ${theme.orbit.spaceMedium};
      padding-${right}: ${theme.orbit.spaceMedium};

      &:last-of-type {
        padding-${right}: 0;
      }
    `};
  `)};

  @media (min-width: 768px) and (max-width: 1023px) {
    ${({ mapTile }) => mapTile && 'width: calc(100% / 3 * 2)'};
    ${({ placeholder }) =>
      placeholder === 'mapRow' &&
      css`
        width: 100%;
        &:nth-of-type(n + 3) {
          display: none;
        }
      `};
    ${({ placeholder }) =>
      placeholder === 'row' &&
      css`
        width: calc(100% / 3);
        &:nth-of-type(4n),
        &:nth-of-type(4n - 3) {
          width: calc(100% / 3 * 2);
        }
      `};
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    ${({ mapTile }) => mapTile && 'width: 50%'};
    ${({ placeholder }) =>
      placeholder === 'mapRow' &&
      css`
        width: 50%;
        &:nth-of-type(3) {
          width: 100%;
        }
        &:nth-of-type(n + 4) {
          display: none;
        }
      `};
    ${({ placeholder }) =>
      placeholder === 'row' &&
      css`
        width: 25%;
        &:nth-of-type(4n - 2),
        &:nth-of-type(4n - 3) {
          width: 50%;
        }
      `};
  }

  @media (min-width: 1440px) {
    ${({ mapTile }) => mapTile && 'width: 40%'};
    ${({ placeholder }) =>
      placeholder === 'mapRow' &&
      css`
        width: calc(100% / 3);
        &:nth-of-type(4) {
          width: calc(100% / 3 * 2);
        }
      `};
    ${({ placeholder }) =>
      placeholder === 'row' &&
      css`
        width: 20%;
        &:nth-of-type(4n - 2),
        &:nth-of-type(4n - 3) {
          width: 40%;
        }
        &:last-of-type {
          width: 20%;
        }
      `};
  }
`;

export const ScrollWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 32px;
  width: 100%;
  overflow: hidden;
  box-sizing: content-box;
`;

export const PriceValue = styled.span`
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
`;

export const PriceBeforeDiscountValue = styled.span`
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
  white-space: nowrap;
  text-decoration: line-through;
  color: #ffffff80;
`;

export const PriceDiscountedValue = styled.span`
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  white-space: nowrap;
  color: #fff;
`;
