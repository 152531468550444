import { gql } from 'graphql.macro';

import * as fragments from '../../../../../graphql/fragments/index';

export default gql`
  query AggregateResultsReturnItinerariesQuery_BasicWidget(
    $search: SearchReturnInput
    $filter: ItinerariesFilterInput
    $options: ItinerariesOptionsInput
  ) {
    returnOnePerCityItineraries(search: $search, filter: $filter, options: $options) {
      __typename
      ... on AppError {
        error: message
      }
      ... on OnePerCityItineraries {
        server {
          serverToken
        }
        metadata {
          ...AggregatedItinerariesMetadata
        }
        itineraries {
          __typename
          ... on ReturnOnePerCityItinerary {
            price {
              amount
              formattedValue
              priceBeforeDiscount
            }
            locationHashtags {
              id
              slug
              label
            }
            source {
              ...AggregatedSearchStop
            }
            destination {
              ...AggregatedSearchStop
            }
            nightsCount
          }
        }
      }
    }
  }

  ${fragments.AggregatedItinerariesMetadata}
  ${fragments.AggregatedSearchStop}
`;
