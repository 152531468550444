import styled, { css } from 'styled-components';
import { left, right } from '@kiwicom/orbit-components/lib/utils/rtl';

import {
  ResultCardSection as ResultCardSectionStyled,
  BadgeWrapper as BadgeWrapperStyled,
} from './index.styled';

export const ResultCardSection = styled(ResultCardSectionStyled)``;

export const DepartureDate = styled.p`
  margin-bottom: ${({ theme }) => theme.orbit.spaceXXSmall};
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
`;

export const ArrivalDate = styled.p`
  margin-top: ${({ theme }) => theme.orbit.spaceXXSmall};
  color: ${({ theme }) => theme.orbit.colorTextSecondary};
`;

export const TripRow = styled.div`
  display: flex;
`;

export const TripArrowWrapper = styled.div.attrs({
  ariaHidden: 'true',
})`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-${right}: ${({ theme }) => theme.orbit.spaceXSmall};
`;

export const TripArrowIcon = styled.div`
  position: relative;
  z-index: 1;
  ${({ background, theme }) =>
    background &&
    css`
      background: ${theme.orbit.paletteWhite};
      border-bottom: 1px solid ${theme.orbit.paletteWhite};
    `}
`;

export const TripArrowLine = styled.div`
  position: absolute;
  ${left}: calc(50% - 1px);
  width: 2px;
  height: 50%;
  background: ${({ theme }) => theme.orbit.paletteCloudDark};

  ${({ bottom }) =>
    bottom
      ? css`
          top: 50%;
        `
      : css`
          bottom: 50%;
        `}
`;

export const TripLayover = styled.div`
  position: relative;
  margin-top: 7px;
`;

export const TripLayoverText = styled.div`
  position: absolute;
  top: -${({ theme }) => theme.orbit.spaceXSmall};
  left: 50%;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: ${({ theme }) => theme.orbit.fontWeightMedium};
  font-size: ${({ theme }) => theme.orbit.fontSizeTextSmall};
  transform: translateX(-50%);
`;

export const TripLayoverTextBackground = styled.div`
  padding: 0 ${({ theme }) => theme.orbit.spaceSmall};
  background: ${({ theme }) => theme.orbit.paletteWhite};
`;

export const BadgeWrapper = styled(BadgeWrapperStyled)``;

export const TripBadgesAndLock = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: ${({ theme }) => theme.orbit.spaceXSmall} 0 ${({ theme }) => theme.orbit.spaceXXSmall};
`;

export const TripBadges = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
`;

export const TripShowIcon = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  ${right}: -12px;
  display: flex;
  padding: ${({ theme }) => theme.orbit.spaceXXSmall};
  color: ${({ theme }) => theme.orbit.colorIconTertiary};
  background-color: ${({ theme }) => theme.orbit.paletteWhite};

  &:hover {
    color: ${({ theme }) => theme.orbit.colorIconPrimary};
  }
`;

export const TripBorderWrapper = styled.div`
  position: relative;
  ${({ border, theme }) =>
    border &&
    `border: 1px solid ${theme.orbit.paletteCloudDark};
    border-radius: ${theme.orbit.borderRadiusNormal}
  `}
  ${({ hovering, theme }) =>
    hovering &&
    css &&
    `
    cursor: pointer;
    &:hover {
      box-shadow: ${theme.orbit.boxShadowActionable};
      ${TripShowIcon} {
        color: ${theme.orbit.colorIconPrimary};
      }
    }
  `};
`;

export const TripWrapper = styled.div`
  position: relative;
  ${({ padding, theme }) => padding && `padding: ${theme.orbit.spaceSmall}`};
`;
