import React from 'react';
import styled from 'styled-components';
import Text from '@kiwicom/orbit-components/lib/Text';
import {InputStepperStateless} from '@kiwicom/orbit-components/lib/deprecated/InputStepper';
import { right } from '@kiwicom/orbit-components/lib/utils/rtl';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-${right}: 8px;
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepperWrapper = styled.div`
  width: 130px;
  min-width: 130px;

  div {
    box-shadow: none !important;
    background: transparent;
  }
`;

const Item = ({
  title,
  description,
  value,
  disabledIncrement,
  disabledDecrement,
  IconComponent,
  onIncrement,
  onDecrement,
  dataTest,
}) => {
  return (
    <ItemContainer data-test={dataTest}>

      <ItemWrapper>
        <IconComponent  />
        <ItemInfo>
          <Text>{title}</Text>
          <Text type="secondary" size="small">
            {description}
          </Text>
        </ItemInfo>
      </ItemWrapper>

      <StepperWrapper>

        <InputStepperStateless
          value={value}
          onIncrement={disabledIncrement ? () => {} : onIncrement}
          onDecrement={disabledDecrement ? () => {} : onDecrement}
          onChange={() => {}}
          disabledIncrement={disabledIncrement}
          disabledDecrement={disabledDecrement}
          titleIncrement="Increment"
          titleDecrement="Decrement"
          dataTest="numberValue"
        />

      </StepperWrapper>

    </ItemContainer>
  );
};

export default Item;
