import { gql } from 'graphql.macro';

export const SegmentCarrier = gql`
  fragment SegmentCarrier on Carrier {
    name
    code
    id
  }
`;

export const SegmentStop = gql`
  fragment SegmentStop on Stop {
    localTime
    utcTime
    station {
      name
      code
      gps {
        lat
        lng
      }
      city {
        name
        mapId
        id
      }
      country {
        code
        id
      }
      id
    }
  }
`;

export const MetadataItinerariesTopResults = gql`
  fragment MetadataItinerariesTopResults on Itinerary {
    __typename
    duration
    price {
      amount
      formattedValue
      currency {
        code
      }
    }
    id
  }
`;

export const SectorSegmentLayover = gql`
  fragment SectorSegmentLayover on Layover {
    duration
    guarantee
    isBaggageRecheck
    isStationChange
    isWalkingDistance
  }
`;

export const ServerInfo = gql`
  fragment ServerInfo on Server {
    requestId
    environment
    packageVersion
    serverToken
  }
`;

export const AggregatedItinerariesMetadata = gql`
  fragment AggregatedItinerariesMetadata on ItinerariesMetadata {
    itinerariesCount
    hasMorePending
    prices {
      items {
        frequency
        label
      }
    }
    locationHashtags {
      label
      id
    }
    inboundDays
    outboundDays
  }
`;

export const AggregatedSearchStation = gql`
  fragment AggregatedSearchStation on Station {
    id
    legacyId
    name
    city {
      legacyId
      name
      id
    }
    country {
      code
      name
      id
      legacyId
      region {
        legacyId
        continent {
          legacyId
          id
        }
        id
      }
    }
  }
`;

export const AggregatedSearchStop = gql`
  fragment AggregatedSearchStop on Stop {
    station {
      ...AggregatedSearchStation
    }
    localTime
    utcTime
  }

  ${AggregatedSearchStation}
`;

export const SectorSegmentSegment = gql`
  fragment SectorSegmentSegment on Segment {
    cabinClass
    code
    source {
      ...SegmentStop
    }
    destination {
      ...SegmentStop
    }
    duration
    type
    code
    carrier {
      ...SegmentCarrier
    }
    operatingCarrier {
      ...SegmentCarrier
    }
    cabinClass
    seatInfo {
      hasAudioVideo
      hasPower
      hasWifi
      pitch {
        unit
        value
      }
      recline {
        unit
        value
      }
      width {
        unit
        value
      }
    }
  }

  ${SegmentStop}
  ${SegmentCarrier}
`;

export const SectorSectorSegments = gql`
  fragment SectorSectorSegments on SectorSegment {
    layover {
      ...SectorSegmentLayover
    }
    segment {
      ...SectorSegmentSegment
    }
  }

  ${SectorSegmentLayover}
  ${SectorSegmentSegment}
`;
