import React from 'react';
import Stack from '@kiwicom/orbit-components/lib/Stack';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import Text from '@kiwicom/orbit-components/lib/Text';
import BaggageCabin from '@kiwicom/orbit-components/lib/icons/BaggageCabin';
import BaggageChecked20Icon from '@kiwicom/orbit-components/lib/icons/BaggageChecked20';
import BaggageCheckedNone from '@kiwicom/orbit-components/lib/icons/BaggageCheckedNone';

import  useTranslations  from '../../services/translations/useTranslations';
import Weight from '../../common/Weight';
import { CABIN_CLASS_TYPE_TRANSLATIONS } from '../../consts/cabinClass';

import { ResultCardBadgesRow, BadgeInnerWrapper } from './CardBadges.styled';

const ResultCardBadges = ({ itinerary, narrowView, noButton, children }) => {
  const { translate } = useTranslations();

  const { cabinClasses } = itinerary;
  const showNoBags =
    (itinerary.bagsInfo.includedCheckedBags || itinerary.bagsInfo.checkedBagTiers.length) === 0;

  const hasBadges = cabinClasses.length > 0 || showNoBags || !narrowView;

  return (
    hasBadges && (
      <ResultCardBadgesRow data-test="ResultCardBadges" narrowView={narrowView} noButton={noButton}>
        <Stack flex row inline wrap grow={!noButton} shrink align="center" spacing="XSmall">
          {cabinClasses.length > 0 && (
            <Badge dataTest="ResultCardBadge-CabinClass">
              <BadgeInnerWrapper>
                {cabinClasses.map((item, index) => (
                  <React.Fragment key={item}>
                    {index > 0 && (
                      <Text size="small" weight="bold" element="span">
                        {' + '}
                      </Text>
                    )}
                    <Text
                      type={item !== 'ECONOMY' ? 'info' : 'primary'}
                      size="small"
                      weight="bold"
                      element="span"
                    >
                      {translate(CABIN_CLASS_TYPE_TRANSLATIONS[item])}
                    </Text>
                  </React.Fragment>
                ))}
              </BadgeInnerWrapper>
            </Badge>
          )}

          {itinerary.bagsInfo.handBagTiers.length > 0 && itinerary.bagsInfo.includedHandBags ? (
            <Badge icon={<BaggageCabin size="small" />} dataTest="ResultCardBadge-CabinBags">
              <span>
                {itinerary.bagsInfo.includedHandBags > 1 &&
                  `${itinerary.bagsInfo.includedHandBags}x `}
              </span>
              <Weight value={itinerary.bagsInfo.handBagTiers[0].bags[0].weight.value} />
            </Badge>
          ) : null}

          {itinerary.bagsInfo.checkedBagTiers.length > 0 &&
          itinerary.bagsInfo.includedCheckedBags ? (
            <Badge icon={<BaggageChecked20Icon size="small" />} dataTest="ResultCardBadge-CheckedBags">
              <span>
                {itinerary.bagsInfo.includedCheckedBags > 1 &&
                  `${itinerary.bagsInfo.includedCheckedBags}x `}
              </span>
              <Weight value={itinerary.bagsInfo.checkedBagTiers[0].bags[0].weight.value} />
            </Badge>
          ) : null}

          {showNoBags && (
            <Badge icon={<BaggageCheckedNone size="small" />} type="warning">
              No checked bags
            </Badge>
          )}
        </Stack>
        {children}
      </ResultCardBadgesRow>
    )
  );
};

export default ResultCardBadges;
