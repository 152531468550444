/* global __ */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import ChildIcon from '@kiwicom/orbit-components/lib/icons/Child';
import InfantIcon from '@kiwicom/orbit-components/lib/icons/Infant';
import PassengerIcon from '@kiwicom/orbit-components/lib/icons/Passenger';
import PassengersIcon from '@kiwicom/orbit-components/lib/icons/Passengers';
import BaggageCabinIcon from '@kiwicom/orbit-components/lib/icons/BaggageCabin';
import BaggageChecked20Icon from '@kiwicom/orbit-components/lib/icons/BaggageChecked20';
import BaggageSetIcon from '@kiwicom/orbit-components/lib/icons/BaggageSet';
import { left, right } from '@kiwicom/orbit-components/lib/utils/rtl';

import usePaxAndBags from '../../services/filters/PassengersAndBags/usePaxAndBags';
import  useTranslations  from '../../services/translations/useTranslations';
import { usePostMessages } from '../../services/postMessages';
import useViewport from '../../scenes/Basic/services/Viewport/useViewport';
import PopoverActions from '../../common/PopoverActions';

import Item from './GenericStepper';

const SectionWrapper = styled.div`
  margin-bottom: 24px;

  & + & {
    margin-bottom: 12px;
  }
`;

const BaggageWrapper = styled.span`
  margin-${left}: 12px;
  margin-${right}: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const PassengersAndBags = ({ popoverActions }) => {
  const {
    adults,
    children,
    infants,
    totalPax,
    totalBags,
    cabinBags,
    checkedBags,
    canIncrement,
    canDecrement,
    restoreStateFromSnapshot,
  } = usePaxAndBags();
  const { translate } = useTranslations();
  const { isMobile } = useViewport();
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const [contentHeight, setContentHeight] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [paxAndBags, setPaxAndBags] = useState({
    adults,
    children,
    infants,
    cabinBags,
    checkedBags,
  });

  useEffect(() => {
    setPaxAndBags({
      adults,
      children,
      infants,
      cabinBags,
      checkedBags,
    });
  }, [adults, children, infants, cabinBags, checkedBags]);

  const handleOpenPopover = () => {
    if (isMobile) {
      setContentHeight(window.innerHeight);
      postIframeHeight(600);
      lockResizing();
    }
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPaxAndBags({
      adults,
      children,
      infants,
      cabinBags,
      checkedBags,
    });
    if (isMobile) {
      unlockResizing();
      postIframeHeight(contentHeight, true);
    }
    setPopoverOpen(false);
  };

  const handleSubmit = () => {
    restoreStateFromSnapshot(paxAndBags);
    handleClosePopover();
  };

  const handleChange = (type, target) => {
    if (type === 'increment' && canIncrement(paxAndBags)[target]) {
      setPaxAndBags({
        ...paxAndBags,
        [target]: paxAndBags[target] + 1,
      });
    }

    if (type === 'decrement' && canDecrement(paxAndBags)[target]) {
      if (target === 'adults') {
        setPaxAndBags({
          ...paxAndBags,
          adults: paxAndBags.adults - 1,
          infants: Math.min(paxAndBags.infants, paxAndBags.adults - 1),
          cabinBags: Math.min(paxAndBags.cabinBags, paxAndBags.adults + paxAndBags.children - 1),
          checkedBags: Math.min(
            paxAndBags.checkedBags,
            (paxAndBags.adults + paxAndBags.children - 1) * 2,
          ),
        });
      } else if (target === 'children') {
        setPaxAndBags({
          ...paxAndBags,
          children: paxAndBags.children - 1,
          cabinBags: Math.min(paxAndBags.cabinBags, paxAndBags.adults + paxAndBags.children - 1),
          checkedBags: Math.min(
            paxAndBags.checkedBags,
            (paxAndBags.adults + paxAndBags.children - 1) * 2,
          ),
        });
      } else {
        setPaxAndBags({
          ...paxAndBags,
          [target]: paxAndBags[target] - 1,
        });
      }
    }
  };

  return (
    <Popover
      dataTest="PassengersPopover"
      opened={popoverOpen}
      onOpen={handleOpenPopover}
      onClose={handleClosePopover}
      overlapped
      fixed
      actions={popoverActions}
      content={
        <>

          <PopoverActions onClose={handleClosePopover} onSubmit={handleSubmit} />

          <SectionWrapper>

            <Heading type="title4" spaceAfter="normal">
              {translate(('search.form.passengers.title'))}
            </Heading>

            <Item
              dataTest="PassengersRow-adults"
              title={translate(('search.form.passengers.adults.title'))}
              description={translate(('search.form.filter.passengers.adults.subtext.short'))}
              value={paxAndBags.adults}
              disabledIncrement={!canIncrement(paxAndBags).adults}
              disabledDecrement={!canDecrement(paxAndBags).adults}
              onIncrement={() => handleChange('increment', 'adults')}
              onDecrement={() => handleChange('decrement', 'adults')}
              IconComponent={PassengerIcon}
            />

            <Item
              dataTest="PassengersRow-children"
              title={translate(('search.form.passengers.children.title'))}
              description={translate(('search.form.passengers.children.subtext'))}
              value={paxAndBags.children}
              disabledIncrement={!canIncrement(paxAndBags).children}
              disabledDecrement={!canDecrement(paxAndBags).children}
              onIncrement={() => handleChange('increment', 'children')}
              onDecrement={() => handleChange('decrement', 'children')}
              IconComponent={ChildIcon}
            />

            <Item
              dataTest="PassengersRow-infants"
              title={translate(('search.form.passengers.infants.title'))}
              description={translate(('search.form.passengers.infants.subtext'))}
              value={paxAndBags.infants}
              disabledIncrement={!canIncrement(paxAndBags).infants}
              disabledDecrement={!canDecrement(paxAndBags).infants}
              onIncrement={() => handleChange('increment', 'infants')}
              onDecrement={() => handleChange('decrement', 'infants')}
              IconComponent={InfantIcon}
            />

          </SectionWrapper>
          <SectionWrapper>
            <Heading type="title4" spaceAfter="normal">
              {translate(('search.form.filter.bags.title'))}
            </Heading>

            <Item
              dataTest="BagsPopup-cabin"
              title={translate(('search.form.bags.tiers.cabin_bags'))}
              value={paxAndBags.cabinBags}
              disabledIncrement={!canIncrement(paxAndBags).cabinBags}
              disabledDecrement={!canDecrement(paxAndBags).cabinBags}
              onIncrement={() => handleChange('increment', 'cabinBags')}
              onDecrement={() => handleChange('decrement', 'cabinBags')}
              IconComponent={BaggageCabinIcon}
            />
            <Item
              dataTest="BagsPopup-checked"
              title={translate(('search.form.bags.tiers.checked_bags'))}
              value={paxAndBags.checkedBags}
              disabledIncrement={!canIncrement(paxAndBags).checkedBags}
              disabledDecrement={!canDecrement(paxAndBags).checkedBags}
              onIncrement={() => handleChange('increment', 'checkedBags')}
              onDecrement={() => handleChange('decrement', 'checkedBags')}
              IconComponent={BaggageChecked20Icon}
            />
          </SectionWrapper>

        </>
      }
    >
      <ButtonLink
        dataTest="PassengersField"
        size="small"
        type="secondary"
        iconLeft={<PassengersIcon />}
        iconRight={<ChevronDownIcon />}
      >
        <span data-test="TotalPassNum">{totalPax}</span>
        <BaggageWrapper>
          <BaggageSetIcon />
        </BaggageWrapper>
        <span data-test="TotalBagsNum">{totalBags}</span>
      </ButtonLink>
    </Popover>
  );
};

export default PassengersAndBags;
