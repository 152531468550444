import { useCallback, useContext } from 'react';

import useEntryParameters from '../entryParameters/useEntryParameters';

import { PostMessagesState } from './PostMessagesProvider';

const usePostMessages = () => {
  const { iframeId } = useEntryParameters();
  const { resizingLocked, lockResizing, unlockResizing } = useContext(PostMessagesState);

  const postIframeLoaded = useCallback(() => {
    if (window !== window.top) {
      window.parent.postMessage({ iframeId, loaded: true }, '*');
    }
  }, [iframeId]);

  const postIframeHeight = useCallback(
    (height, forceResize = false) => {
      if (!resizingLocked || forceResize) {
        window.parent.postMessage({ iframeId, height }, '*');
      }
    },
    [iframeId, resizingLocked],
  );

  const postScrollToTop = useCallback(() => {
    window.parent.postMessage({ iframeId, scrollToTop: true }, '*');
  }, [iframeId]);

  const postCreateSolution = useCallback(() => {
    window.parent.postMessage({ iframeId, createSolution: true }, '*');
  }, [iframeId]);

  const postTrackCopy = useCallback(
    code => {
      window.parent.postMessage({ iframeId, code }, '*');
    },
    [iframeId],
  );

  const postDeeplinkScript = useCallback(
    script => {
      window.parent.postMessage({ id: 'builderScript', iframeId, script }, '*');
    },
    [iframeId],
  );

  return {
    postIframeLoaded,
    postIframeHeight,
    postScrollToTop,
    postCreateSolution,
    postTrackCopy,
    postDeeplinkScript,
    lockResizing,
    unlockResizing,
  };
};

export default usePostMessages;
