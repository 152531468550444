/* global __ */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';

import { useBookingDeeplink } from '../../services/deeplinks/useBookingDeeplink';
import Translate from '../../common/Translate';
import { useGTM } from '../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../consts/tracking';

const BookingButtonWrapper = styled.div`
  ${({ fullWidth }) => fullWidth && 'flex: 1'};
`;

const BookingButton = ({
  itinerary,
  fullWidth,
  size = 'small',
  shouldStopPropagation,
  type,
  dataTest,
  resultType,
}) => {
  const bookingUrl = useBookingDeeplink(itinerary);
  const { pushToDataLayer } = useGTM();

  const handleWrapperClick = useCallback(
    e => (shouldStopPropagation ? e.stopPropagation() : () => {}),
    [shouldStopPropagation],
  );

  return (
    <BookingButtonWrapper
      fullWidth={Boolean(fullWidth)}
      data-test={dataTest}
      onClick={handleWrapperClick}
    >
      <Button
        href={bookingUrl.KIWI}
        onClick={() => {
          if (resultType === 'detail') {
            pushToDataLayer(GTM_EVENTS.BOOK_BUTTON_DETAILS, { itinerary });
          } else {
            pushToDataLayer(GTM_EVENTS.BOOK_BUTTON_ITINERARY, { itinerary });
          }
        }}
        external
        type={type}
        fullWidth={fullWidth}
        size={size || (fullWidth ? 'small' : 'normal')}
      >
        <Translate tkey={('search.result.itinerary_detail.book.button')} />
      </Button>
    </BookingButtonWrapper>
  );
};

export default BookingButton;
