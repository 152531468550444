import React from 'react';
import Mobile from '@kiwicom/orbit-components/lib/Mobile';
import Desktop from '@kiwicom/orbit-components/lib/Desktop';

import SortByDesktop from './SortByDesktop';
import SortByMobile from './SortByMobile';

const SortBy = ({ topResults, sortBy, changeSortBy }) => {
  return (
    <>
      <Desktop>
        <SortByDesktop topResults={topResults} sortBy={sortBy} changeSortBy={changeSortBy} />
      </Desktop>
      <Mobile>
        <SortByMobile topResults={topResults} sortBy={sortBy} changeSortBy={changeSortBy} />
      </Mobile>
    </>
  );
};

export default SortBy;
