/* global __ */
import React from 'react';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Button from '@kiwicom/orbit-components/lib/Button';
import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';

import { getOffsetedSingleLetterWeekdays } from '../../../../../../utils/datetime';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import Translate from '../../../../../../common/Translate';

import { Wrapper, Container, ToggleCircleButton, FirstLetterOfDay } from './index.styled';

const removeOffset = (i, offset) => (i + offset) % 7;

const Filter = ({ direction, days: selectedDaysArray, asButton, onToggle }) => {
  const { locale } = useTranslations();
  const { isLargeMobile } = useMediaQuery();
  const { pushToDataLayer } = useGTM();

  const offset = locale?.options?.weekStartsOn;
  const notOffseted = !offset;

  return (
    <>
      {!isLargeMobile && (
        <Wrapper asButton={asButton}>
          <Heading as="h3">
            <Translate tkey={('result.filter.days')} />
          </Heading>
        </Wrapper>
      )}
      <Container asButton={asButton}>
        {getOffsetedSingleLetterWeekdays(locale, notOffseted).map(
          (firstLetterOfDay, offsetedIndex) => {
            const dayIndex = removeOffset(offsetedIndex, offset);
            const isDaySelected = Boolean(selectedDaysArray[dayIndex]);

            return (
              <Button
                dataTest={`DaysFilter-${selectedDaysArray[dayIndex]}`}
                key={offsetedIndex}
                asComponent={props => (
                  <ToggleCircleButton {...props} type={isDaySelected ? 'info' : 'white'} />
                )}
                size="small"
                type={isDaySelected ? 'primary' : 'white'}
                circled
                disabled={selectedDaysArray.includes(dayIndex)}
                onClick={() => {
                  pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
                  onToggle(dayIndex, direction);
                }}
              >
                <FirstLetterOfDay>{firstLetterOfDay}</FirstLetterOfDay>
              </Button>
            );
          },
        )}
      </Container>
    </>
  );
};

export default React.memo(Filter);
