import styled, { css } from 'styled-components';
import { translate3d } from '@kiwicom/orbit-components/lib/utils/rtl';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

export const FormAndFiltersContainer = styled.div`
  ${({ theme }) =>
    theme.deprecatedColors.searchFormBackgroundColor
      ? css`
          background-color: ${({ theme }) => theme.deprecatedColors.searchFormBackgroundColor};
        `
      : css`
          background-color: ${({ theme }) => theme.orbit.backgroundModal};
        `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    theme.deprecatedColors.resultsBackgroundColor &&
    css`
      background-color: ${({ theme }) => theme.deprecatedColors.resultsBackgroundColor};
    `}
`;

export const ResultsContainer = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  width: 100%;
  min-width: 250px;
  padding: 0 16px 16px;

  ${media.desktop(css`
    padding: 0 40px;
  `)}
`;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 3px;
  width: 100%;
  overflow: hidden;
`;

export const LoaderBar = styled.div`
  background-color: ${({ theme }) => theme.orbit.backgroundButtonPrimary};
  height: 3px;
  opacity: 0.4;
  animation: LoadingLineMove 1500ms cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  @keyframes LoadingLineMove {
    0% {
      transform: ${translate3d('-100%, 0, 0')};
    }
    100% {
      transform: ${translate3d('100%, 0, 0')};
    }
  }
`;
