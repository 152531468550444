import React, { useState } from 'react';
import isSameDay from 'date-fns/isSameDay';
import isPast from 'date-fns/isPast';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import { useGTM } from '../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../consts/tracking';
import { adjustForQuery } from '../../../utils/datetime';
import { usePostMessages } from '../../../services/postMessages';
import useViewport from '../../../scenes/Basic/services/Viewport/useViewport';
import { isNightsOfStay } from '../../../utils/datetime';

export const DateState = React.createContext({});

const DateProvider = ({ onChange, direction, children }) => {
  const [date, setDate] = useState([]);
  const [nightsOfStay, setNightsOfStay] = useState([2, 10]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [prevDateClicked, setPrevDateClicked] = useState(null);
  const [contentHeight, setContentHeight] = useState(false);
  const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
  const { isMobile } = useViewport();
  const { pushToDataLayer } = useGTM();

  const handleSetPopoverOpen =
    value =>
    (dates = null) => {
      // opening
      if (isMobile && value) {
        setContentHeight(window.innerHeight);
        postIframeHeight(600);
        lockResizing();
      }
      // closing
      if (isMobile && !value) {
        unlockResizing();
        postIframeHeight(contentHeight, true);
      }

      // handle setting dates on close
      if (!value) {
        /* if modal is being closed functionally (from handleDayChange)
       date from state is not set yet, thats why we need to use dates
       provided by function call instead from state
    */
        if (isNightsOfStay(dates)) {
          onChange(dates);
        } else if (dates?.length) {
          const dateTime = adjustForQuery(dates || date);
          pushToDataLayer(GTM_EVENTS.SEARCH_BUTTON, { [`${direction}Date`]: dateTime });
          onChange(dateTime);
        } else {
          const dateTime = 'anytime';
          pushToDataLayer(GTM_EVENTS.SEARCH_BUTTON, { [`${direction}Date`]: dateTime });
          onChange(dateTime);
        }
      }

      setPopoverOpen(value);
    };

  const handleDayChange = dates => {
    setDate(dates);
    handleSetPopoverOpen(false)(dates);
  };

  const handleNightsOfStayChange = nights => {
    setNightsOfStay(nights);
    handleSetPopoverOpen(false)(nights);
  };

  const handleMonthClick = callbackSet => date => {
    const isDisabled = isPast(date);
    const startingDate = isDisabled ? new Date() : date;

    const endOfMonth = lastDayOfMonth(startingDate);
    if (isSameDay(startingDate, endOfMonth)) {
      setDate([startingDate]);
      return;
    }

    const selectedDates = eachDayOfInterval({ start: startingDate, end: endOfMonth });
    callbackSet(selectedDates);
  };

  const clearDates = () => {
    setDate(undefined);

    onChange?.(undefined);
  };

  const value = {
    date,
    setDate,
    nightsOfStay,
    setNightsOfStay,
    clearDates,
    popoverOpen,
    handleSetPopoverOpen,
    prevDateClicked,
    setPrevDateClicked,
    handleDayChange,
    handleNightsOfStayChange,
    handleMonthClick,
  };

  return <DateState.Provider value={value}>{children}</DateState.Provider>;
};

export default DateProvider;
