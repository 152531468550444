import React from 'react';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';

import { getDisplayName } from '../../../utils/place';
import PlaceIcon from '../../../common/PlaceIcon';

import  usePlacePickerState  from '../services/usePlacePickerState';

const Item = ({ place, onAdd, onRemove, selected }) => {
  const {
    setInput,
    placePickerRef,
    isPlaceSelected,
  } = usePlacePickerState();

  const isSelected = selected ? selected : isPlaceSelected(place);

  const handlePlaceClick = () => {
     if (isSelected) {
      onRemove(place);
      return;
    }
    setInput('');
    onAdd(place);

    placePickerRef.current.value = '';
    placePickerRef.current.focus();
  };

  return (
    <div>
      <ListChoice
        dataTest={`PlacePickerRow-${place?.type}`}
        icon={<PlaceIcon place={place} />}
        title={getDisplayName(place)}
        onClick={handlePlaceClick}
        selected={isSelected}
        selectable
      />
    </div>
  );
};

export default Item;
