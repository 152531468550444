import useMediaQuery from '@kiwicom/orbit-components/lib/hooks/useMediaQuery';

import { DEVICE_NAME } from '../../../../consts/device';
import { getViewportName } from '../../../../utils/device';

const useViewport = () => {
  const viewportInfo = useMediaQuery();
  const viewportName = getViewportName(viewportInfo);

  const isNotDesktop =
    viewportName !== DEVICE_NAME.LARGE_DESKTOP && viewportName !== DEVICE_NAME.DESKTOP;

  const isMobileOrLargeMobile =
    viewportName === DEVICE_NAME.MOBILE || viewportName === DEVICE_NAME.LARGE_MOBILE;

  const isMobile = viewportName === DEVICE_NAME.MOBILE;

  return {
    isNotDesktop,
    isMobileOrLargeMobile,
    isMobile,
  };
};

export default useViewport;
