/* global __ */
import React from 'react';
import Separator from '@kiwicom/orbit-components/lib/Separator';
import Badge from '@kiwicom/orbit-components/lib/Badge';
import NotificationBadge from '@kiwicom/orbit-components/lib/NotificationBadge';
import AirplaneUpIcon from '@kiwicom/orbit-components/lib/icons/AirplaneUp';
import ArrowDownIcon from '@kiwicom/orbit-components/lib/icons/ArrowDown';
import BusIcon from '@kiwicom/orbit-components/lib/icons/Bus';
import CircleSmallIcon from '@kiwicom/orbit-components/lib/icons/CircleSmall';
import TrainIcon from '@kiwicom/orbit-components/lib/icons/Train';
import isSameDay from 'date-fns/isSameDay';

import ItineraryTimeBadge from '../../common/ItineraryTimeBadge';
import Timestamp from '../../common/Timestamp';
import Translate from '../../common/Translate';
import * as itineraryUtils from '../../utils/itinerary';

import ItineraryPlace from './ItineraryPlace';
import ItineraryCarriers from './ItineraryCarriers';
import {
  ResultCardSection,
  ArrivalDate,
  DepartureDate,
  TripBadgesAndLock,
  TripBorderWrapper,
  TripWrapper,
  TripRow,
  TripArrowWrapper,
  TripArrowIcon,
  TripArrowLine,
  TripBadges,
  BadgeWrapper,
  TripLayover,
  TripLayoverText,
  TripLayoverTextBackground,
} from './Itinerary.styled';

const partTypeIcons = {
  FLIGHT: <AirplaneUpIcon size="small" />,
  TRAIN: <TrainIcon size="small" />,
  BUS: <BusIcon size="small" />,
};

const ResultCardItinerary = ({ itinerary, narrowView = false }) => {
  return itinerary.trips.map((trip, i) => {
    const layover = itineraryUtils.getLayovers(itinerary)[i];

    return (
      <div key={i}>
        <ResultCardSection narrowView={narrowView} data-test="FlightSection">
          <DepartureDate data-test="FlightSectionDate">
            <Timestamp
              formatKeys="dateFormat"
              value={new Date(trip.segments[0].source.localTime)}
              isLocal
            />
          </DepartureDate>
          <TripBorderWrapper>
            <TripWrapper>
              <TripRow data-test="FlightSectionFrom">
                <TripArrowWrapper>
                  <TripArrowLine bottom />
                  <TripArrowIcon>
                    <CircleSmallIcon size="small" color="secondary" />
                  </TripArrowIcon>
                </TripArrowWrapper>
                <ItineraryPlace destination={trip.segments[0].source} narrowView={narrowView} />
              </TripRow>
              <TripRow data-test="FlightSectionIcons">
                <TripArrowWrapper>
                  <TripArrowLine />
                  <TripArrowIcon data-test="TripArrowIcon" background>
                    <ArrowDownIcon size="small" />
                  </TripArrowIcon>
                  <TripArrowLine bottom />
                </TripArrowWrapper>
                <TripBadgesAndLock>
                  <TripBadges data-test="TripBadges">
                    <ItineraryTimeBadge duration={trip.duration} />
                    <ItineraryCarriers itinerary={trip} />
                    {itineraryUtils.getTypes(trip).map((type, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <BadgeWrapper key={i} data-test={`TripTypeBadge-${type}`}>
                        <NotificationBadge icon={partTypeIcons[type]}>
                          {partTypeIcons[type]}
                        </NotificationBadge>
                      </BadgeWrapper>
                    ))}
                    <BadgeWrapper data-test="StopCountBadge">
                      {/* TODO: Wrong */}
                      <Badge>
                        <Translate
                          tkey={itineraryUtils.getStopsKey(trip.segments.length - 1)}
                          values={{ count: trip.segments.length - 1 }}
                        />
                      </Badge>
                    </BadgeWrapper>
                  </TripBadges>
                </TripBadgesAndLock>
              </TripRow>
              <TripRow data-test="FlightSectionTo">
                <TripArrowWrapper>
                  <TripArrowLine />
                  <TripArrowIcon>
                    <CircleSmallIcon size="small" color="secondary" />
                  </TripArrowIcon>
                </TripArrowWrapper>
                <ItineraryPlace
                  destination={trip.segments[trip.segments.length - 1].destination}
                  narrowView={narrowView}
                />
              </TripRow>
            </TripWrapper>
          </TripBorderWrapper>
          {!isSameDay(
            new Date(trip.segments[0].destination.localTime),
            new Date(trip.segments[trip.segments.length - 1].destination.localTime),
          ) && (
            <ArrivalDate>
              <Timestamp
                formatKeys="dateFormat"
                value={new Date(trip.segments[trip.segments.length - 1].destination.localTime)}
                isLocal
              />
            </ArrivalDate>
          )}
        </ResultCardSection>
        {layover && (
          <TripLayover>
            <Separator />
            <TripLayoverText>
              <TripLayoverTextBackground>
                <Translate
                  tkey={
                    layover.nightsInDestination === 1
                      ? ('result.nights_in_destination')
                      : ('result.nights_in_destination')
                  }
                  values={{
                    nights: layover.nightsInDestination,
                    place: layover.station.name,
                  }}
                />
              </TripLayoverTextBackground>
            </TripLayoverText>
          </TripLayover>
        )}
      </div>
    );
  });
};

export default ResultCardItinerary;
