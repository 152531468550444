/* global __ */
import React, { useState, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Slider from '@kiwicom/orbit-components/lib/Slider';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import debounce from '../../../../../../utils/debounce';
import  useSearchParams  from '../../../../../../services/filters/Search/useSearchParams';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import useCurrencyData from '../../../../../../services/currencies/useCurrencyData';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import {
  MIN_POWER,
  MAX_POWER,
  parsePricesForHistogram,
  powerToEuros,
  eurosToPower,
  getValueDescription,
} from '../../../../../../common/PriceRange/utils';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

const SliderWrapper = styled.div`
  ${({ asButton }) =>
    asButton &&
    css`
      min-width: 200px;
      padding: 0;
    `}
`;

const toHistogramCols = (length, arr = []) =>
  new Array(length).fill(0).map((val, index) => arr[index] || val);

const PriceFilter = ({ prices, asButton, noButtons, popoverActions }) => {
  const [opened, setOpened] = useState(false);
  const { price, changePrice } = useSearchParams();
  const { activeCurrencyCode, currencies } = useCurrencyData();
  const [internalPrice, setInternalPrice] = useState([price[0], price[1]]);
  const { translate } = useTranslations();
  const { pushToDataLayer } = useGTM();

  const histogramData = useMemo(() => {
    const currencyData = currencies?.find?.(currency => currency.code === activeCurrencyCode);

    const parsed = parsePricesForHistogram(prices || [], currencyData?.rate);
    const histogram = toHistogramCols(parsed.length, parsed);

    return histogram;
  }, [activeCurrencyCode, currencies, prices]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedPriceChange = useCallback(debounce(changePrice, 500), [changePrice]);

  const handleRelease = useCallback(
    ([min, max]) => {
      handleDebouncedPriceChange([powerToEuros(min, 'min'), powerToEuros(max, 'max')]);
      pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changePrice],
  );

  const handlePricesChange = useCallback(
    ([min, max]) => {
      setInternalPrice([powerToEuros(min, 'min'), powerToEuros(max, 'max')]);
      pushToDataLayer(GTM_EVENTS.SEARCH_FILTER);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setInternalPrice],
  );

  const shouldSetDefaultValue = () => {
    if (internalPrice[0] !== 0 || internalPrice[1] !== 0) {
      return (
        <TextLink
          dataTest="ClearFilter-price"
          size="small"
          type="secondary"
          onClick={() => {
            if (noButtons) {
              handleDebouncedPriceChange([0, 0]);
              handleClose();
            }
            handleClear();
          }}
        >
          {translate(('search.filter.multi_used.button.clear'))}
        </TextLink>
      );
    }
    return popoverActions;
  };

  const handleClose = () => {
    setInternalPrice([price[0], price[1]]);
    setOpened(false);
  };

  const handleSubmit = () => {
    changePrice(internalPrice);
    setOpened(false);
  };

  const handleClear = () => {
    setInternalPrice([0, 0]);
  };

  return (
    <FilterWrapper
      label={translate(('search.filter.price.title'))}
      asButton={asButton}
      open={opened}
      onOpen={() => setOpened(true)}
      onClose={handleClose}
      actions={shouldSetDefaultValue()}
      width="260px"
    >
      {!noButtons && <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />}
      <SliderWrapper data-test="HashtagSlider" asButton={asButton}>
        <Slider
          minValue={MIN_POWER}
          maxValue={MAX_POWER}
          defaultValue={[
            eurosToPower(internalPrice[0], 'min'),
            eurosToPower(internalPrice[1], 'max'),
          ]}
          onChange={handlePricesChange}
          onChangeAfter={noButtons ? handleRelease : null}
          // eslint-disable-next-line react/jsx-no-useless-fragment
          label={<></>} // orbit won't display description without a label
          valueDescription={getValueDescription(internalPrice)}
          histogramData={asButton ? null : histogramData}
        />
      </SliderWrapper>
    </FilterWrapper>
  );
};

export default PriceFilter;
