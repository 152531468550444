/* global __ */
import React, { useCallback, useEffect, useState } from 'react';
import addMonths from 'date-fns/addMonths';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

import { dateRange } from '../../utils/datetime';
import { useToggle } from '../../utils/hooks';
import  useTranslations  from '../../services/translations/useTranslations';
import PopoverActions from '../../common/PopoverActions';
import { isNightsOfStay } from '../../utils/datetime';

import DropdownInputButton from './components/DropdownInputButton';
import Calendar from './components/Calendar';
import DatePickerProvider from './services/DateProvider';
import useDatePickerState from './services/useDatePickerState';

const DatePickerComponent = ({
  prefix,
  suffix,
  firstMonth,
  direction,
  values,
  disabled,
  mode,
  noReturn,
  enableClear,
}) => {
  const { locale, translate } = useTranslations();

  const {
    date,
    setDate,
    nightsOfStay,
    handleNightsOfStayChange,
    popoverOpen,
    handleSetPopoverOpen,
    handleDayChange,
    handleMonthClick,
  } = useDatePickerState();

  const [selectedDates, setSelectedDates] = useState(date);
  const isTimeOfStay = useToggle(false);
  const [selectedNightsOfStay, setSelectedNightsOfStay] = useState(nightsOfStay);

  useEffect(() => {
    if (isNightsOfStay(values)) {
      setDate(values);
      setSelectedNightsOfStay([values.split('-')[0], values.split('-')[1]]);
    } else if (values.start?.length) {
      const dateArray = eachDayOfInterval({
        start: new Date(values.start),
        end: new Date(values.end),
      });

      setDate(dateArray);
      setSelectedDates(dateArray);
    }
  }, [values, setDate]);

  const getDateTextValue = internalDates => {
    if (mode === 'one-way' && direction !== 'outbound') {
      return translate(('form_search.no_return_label'));
    }

    if (!internalDates.length) {
      return translate(('form_search.anytime'));
    }

    if (isNightsOfStay(internalDates)) {
      const nightsOfStay = internalDates.split('-');

      const displayedNightsOfStay =
        nightsOfStay[0] === nightsOfStay[1]
          ? nightsOfStay[1]
          : `${nightsOfStay[0]}-${nightsOfStay[1]}`;

      return `${displayedNightsOfStay} ${translate(('form_search.of_nights'))}`;
    }

    return dateRange(internalDates, locale);
  };

  const handleClose = () => {
    if (isNightsOfStay(date)) {
      let nightsOfStay = date.split('-');
      nightsOfStay = nightsOfStay[0] === nightsOfStay[1] ? nightsOfStay[0] : nightsOfStay;
      setSelectedNightsOfStay(nightsOfStay);
    } else {
      setSelectedDates(date);
    }

    handleSetPopoverOpen(false)(null);
  };

  const handleSubmit = () => {
    isTimeOfStay.isOn
      ? handleNightsOfStayChange(`${selectedNightsOfStay[0]}-${selectedNightsOfStay[1]}`)
      : handleDayChange(selectedDates);
  };

  const handleClearDates = () => {
    setSelectedDates([]);
    handleDayChange([]);
  };

  const handleNightsChange = useCallback(
    ([min, max]) => {
      setSelectedNightsOfStay([min, max]);
    },
    [setSelectedNightsOfStay],
  );

  return (
    <DropdownInputButton
      prefix={prefix}
      suffix={suffix}
      disabled={disabled}
      value={getDateTextValue(date)}
      noReturn={noReturn}
      opened={popoverOpen}
      onOpen={handleSetPopoverOpen(true)}
      onSubmit={handleSubmit}
      enableClear={enableClear}
      clearDates={handleClearDates}
      content={
        <>
          <PopoverActions onClose={handleClose} onSubmit={handleSubmit} />
          <Calendar
            firstMonth={firstMonth}
            lastMonth={addMonths(new Date(), 12)}
            rangeSelect
            firstWeekDay={1}
            past={false}
            visibleMonths={2}
            selectedDates={selectedDates}
            selectMonth={handleMonthClick(setSelectedDates)}
            selectDates={setSelectedDates}
            isTimeOfStay={isTimeOfStay}
            nightsOfStay={selectedNightsOfStay}
            handleNightsChange={handleNightsChange}
            direction={direction}
          />
        </>
      }
    />
  );
};

DatePickerComponent.defaultProps = {
  firstMonth: new Date(),
};

const DatePicker = ({ onChange, direction, ...props }) => (
  <DatePickerProvider onChange={onChange} direction={direction}>
    <DatePickerComponent direction={direction} {...props} />
  </DatePickerProvider>
);

export default DatePicker;
