import React from 'react';
import { useLazyQuery } from '@apollo/client';

import  useSearchFormState  from '../SearchForm/useSearchForm';

import ONEWAY_QUERY from './graphql/oneWay.graphql';
import RETURN_QUERY from './graphql/return.graphql';

export const SearchQueryState = React.createContext();

const SearchQueryProvider = props => {
  const { mode } = useSearchFormState();
  const onewayQuery = useLazyQuery(ONEWAY_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const returnQuery = useLazyQuery(RETURN_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <SearchQueryState.Provider value={mode === 'return' ? returnQuery : onewayQuery}>
      {props.children}
    </SearchQueryState.Provider>
  );
};

export default SearchQueryProvider;
