import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import InputField from '@kiwicom/orbit-components/lib/InputField';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import CloseCircleIcon from '@kiwicom/orbit-components/lib/icons/CloseCircle';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';
import Tooltip from '@kiwicom/orbit-components/lib/Tooltip';

import  useTranslations  from '../../../services/translations/useTranslations';
import {} from "ramda";

const Container = styled.div`
  margin-bottom: 10px;
  ${({ disabled }) => disabled && 'pointer-events: none'};

  input {
    font-weight: 500;
  }

  ${media.largeMobile(
    css`
      width: calc(50% - 4px);
    `,
  )}

  ${media.largeDesktop(css`
    width: calc(25% - 5px);
    margin-bottom: 5px;
  `)}
`;

const ClearWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 45px;
  height: 24px;
  cursor: pointer;
`;

const DropdownInputButton = ({
  content,
  value,
  prefix,
  suffix,
  noReturn,
  onOpen,
  onSubmit,
  enableClear,
  clearDates,
  opened,
  disabled,
}) => {
  const ref = useRef(null);
  const { translate } = useTranslations();

  const handleClearClick = e => {
    e.stopPropagation();
    clearDates();
  };

  const RenderSuffix = () => {
    if (suffix || (!noReturn && enableClear && value !== 'Anytime')) {
      return (
        <Tooltip content={translate(('search.form.date_picker.reset_hint'))} placement="top">
          <ClearWrapper onClick={handleClearClick}>
            <CloseCircleIcon />
          </ClearWrapper>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Container data-test="SearchDateInput" disabled={disabled}>
      <Popover opened={opened} onOpen={onOpen} onClose={onSubmit} content={content} actions={<></>}>
        <InputField
          dataTest={`SearchFieldDateInput-${prefix}`}
          disabled={noReturn || disabled}
          prefix={prefix}
          suffix={<RenderSuffix />}
          error={null}
          ref={ref}
          onBlur={() => (opened ? ref.current.focus() : ref.current.blur())}
          value={noReturn ? 'No return' : value}
          readOnly
        />
      </Popover>
    </Container>
  );
};

export default React.memo(DropdownInputButton);
