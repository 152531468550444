/* global __ */
import React from 'react';
import styled, { css } from 'styled-components';
import Checkbox from '@kiwicom/orbit-components/lib/Checkbox';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';

import  useTranslations  from '../../services/translations/useTranslations';
import useViewport from '../../scenes/Basic/services/Viewport/useViewport';

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  button {
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    background-color: rgb(236, 248, 247);

    button {
      opacity: 1;
      visibility: visible;
    }
  }
  ${({ disabled }) => disabled && 'pointer-events:none'};
  ${({ isMobile }) =>
    isMobile &&
    css`
      button {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

const CheckboxFilterItem = ({ label, value, checked, onToggle, onOnlyClick, disabled }) => {
  const { translate } = useTranslations();
  const { isMobile } = useViewport();

  const handleItemClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onToggle(value);
  };

  return (
    <Wrapper onClick={handleItemClick} disabled={disabled} isMobile={isMobile}>
      <Checkbox
        label={label}
        value={value}
        checked={checked}
        onChange={handleItemClick}
        disabled={disabled}
      />
      <ButtonLink
        type="secondary"
        size="small"
        onClick={e => {
          e.stopPropagation();
          onOnlyClick(value);
        }}
      >
        {translate(('search.filter.multi_used.button.only'))}
      </ButtonLink>
    </Wrapper>
  );
};

export default CheckboxFilterItem;
