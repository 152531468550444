/* global __ */
import React, { useState, useMemo, useEffect } from 'react';
import Text from '@kiwicom/orbit-components/lib/Text';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';

import useSearchParams from '../../../../../../services/filters/Search/useSearchParams';
import useSearchFormState  from '../../../../../../scenes/Basic/services/SearchForm/useSearchForm';
import useTranslations  from '../../../../../../services/translations/useTranslations';
import Translate from '../../../../../../common/Translate';
import PopoverActions from '../../../../../../common/PopoverActions';

import FilterWrapper from '../FilterWrapper';

import {
  MainPanel,
  Navigation,
  ActiveLine,
  Item,
  Modes,
  PopoverActionsWrapper,
} from './index.styled';
import Filter from './Filter';

const DaysFilter = ({ asButton, noButtons, popoverActions }) => {
  const [opened, setOpened] = useState(false);
  const [direction, setDirection] = useState(0); // 0 for outbound , 1 for inbound
  const { outboundDays, inboundDays, filterDays, clearDays, toggleDay, setDays, getDefaultValues } =
    useSearchParams();
  const { outboundDate, inboundDate, mode } = useSearchFormState();
  const { translate } = useTranslations();

  const [outbound, setOutbound] = useState(outboundDays);
  const [inbound, setInbound] = useState(inboundDays);

  const didDaysChange = useMemo(() => {
    if (outbound.some(d => d === null)) {
      return true;
    }
    if (inbound.some(d => d === null)) {
      return true;
    }
    return false;
  }, [outbound, inbound]);

  useEffect(() => {
    setOutbound(outboundDays);
    setInbound(inboundDays);
  }, [inboundDays, outboundDays]);

  const handleClose = () => {
    setOutbound(outboundDays);
    setInbound(inboundDays);
    setOpened(false);
  };

  const handleToggle = (dayIndex, type) => {
    if (type === 'outbound') {
      setOutbound(filterDays(outbound, dayIndex));
    } else {
      setInbound(filterDays(inbound, dayIndex));
    }
  };

  const handleSubmit = () => {
    setDays('outbound', outbound);
    setDays('inbound', inbound);
    handleClose();
  };

  const handleClear = () => {
    if (noButtons) {
      clearDays();
      handleClose();
    }
    setOutbound(getDefaultValues('outboundDays'));
    setInbound(getDefaultValues('inboundDays'));
  };

  return (
    <FilterWrapper
      label={translate(('search.filter.days.title'))}
      asButton={asButton}
      open={opened}
      noPadding
      onOpen={() => setOpened(true)}
      onClose={handleClose}
      actions={
        didDaysChange ? (
          <TextLink size="small" type="secondary" dataTest="ClearFilter-days" onClick={handleClear}>
            {translate(('search.filter.multi_used.button.clear'))}
          </TextLink>
        ) : (
          popoverActions
        )
      }
      width="300px"
    >
      {!noButtons && (
        <PopoverActionsWrapper>
          <PopoverActions onClose={handleClose} onSubmit={handleSubmit} spaceAfter="none" />
        </PopoverActionsWrapper>
      )}
      <MainPanel>
        {mode !== 'one-way' && (
          <Navigation asButton={asButton}>
            <Modes>
              <Item active={direction === 0} onClick={() => setDirection(0)} asButton={asButton}>
                <Text
                  dataTest="FilterDays-Departure"
                  weight="bold"
                  type={direction === 0 ? 'primary' : 'secondary'}
                >
                  <Translate tkey={('result.outbound')} />
                </Text>
                <ActiveLine />
              </Item>
              <Item active={direction === 1} onClick={() => setDirection(1)} asButton={asButton}>
                <Text
                  dataTest="FilterDays-Return"
                  weight="bold"
                  type={direction === 1 ? 'primary' : 'secondary'}
                >
                  <Translate tkey={('result.inbound')} />
                </Text>
                <ActiveLine />
              </Item>
            </Modes>
          </Navigation>
        )}
        {direction === 0 ? (
          <Filter
            direction="outbound"
            days={outbound}
            dates={outboundDate}
            asButton={asButton}
            onToggle={noButtons ? toggleDay : handleToggle}
          />
        ) : (
          <Filter
            direction="inbound"
            days={inbound}
            dates={inboundDate}
            asButton={asButton}
            onToggle={noButtons ? toggleDay : handleToggle}
          />
        )}
      </MainPanel>
    </FilterWrapper>
  );
};

export default DaysFilter;
