const makeCJUrl = (deeplink, params) => {
  let affUrl = `http://www.jdoqocy.com/click-${params.pid}-12729203?url=${deeplink}`;
  if (params.sid) {
    affUrl += `&sid=${params.sid}`;
  }
  return affUrl;
};

export const AFFILIATE_NETWORK_URLS = {
  cj: makeCJUrl,
};
